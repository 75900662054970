import { CSSObject, FlexProps } from '@chakra-ui/react';
import { TTableText } from '@/views/account/Transactions/components/TransactionTable/types';

export const TableText = ({ textVariation }: TTableText) => ({
  color: textVariation === 'positive' ? 'gamma.500' : 'white',
  fontWeight: 'semibold',
  fontSize: 'xs',
});

export const transactionInfoFlexProps: FlexProps = {
  color: 'gamma.200',
  p: {
    fontWeight: 'normal',
    fontSize: 'xs',
  },
};

export const TransactionWrapper: CSSObject = {
  h: 'auto',
  borderTop: '1px dashed',
  borderTopColor: 'whiteAlpha.200',
  borderBottom: '1px dashed',
  borderBottomColor: 'blackAlpha.400',
  py: '2',
  px: '0',
};

export const PageHeadingWrapper: CSSObject = {
  borderBottom: 'none',
};

export const TableWrapper: CSSObject = {
  pt: '0',
  color: 'white',
};
