import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1))',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'md',
  fontSize: 'xs',
  fontWeight: 'bold',
  border: 'none',
  px: '3',
  py: '4',
  mb: '2',
  color: 'white',
  _hover: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
