import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const RaceAndTimerContainer: CSSObject = {
  p: '0',
  px: '2',
  py: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
};

export const TextRaceConditions: CSSObject = {
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: 'gamma.500',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
  pt: '3px',
  '&&': {
    '.quaddie': {
      boxShadow:
        '0px 0px 0px 2px rgba(255, 255, 255, 0.65) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      bgGradient: 'linear(to-b, #A750ED, #8C3DCB)',
      color: 'blackAlpha.800',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      '&[data-active="false"]': {
        bgGradient: 'linear(to-b, #A750ED, #8C3DCB)',
      },
      '&.active': {
        border: 'none',
        boxShadow:
          '0px 0px 0px 2px rgba(255, 255, 255, 0.65) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: 'white',
        bg: 'gamma.800',
      },
      '&:hover': {
        bg: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #8C3DCB',
        boxShadow:
          '0px 0px 0px 2px rgba(255, 255, 255, 0.65) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: 'blackAlpha.800',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
      },
    },
  },
};

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '8',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'gamma.800',
          border: 'none',
          boxShadow:
            '0px 0px 0px 2px rgba(255, 255, 255, 0.65) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          color: 'white',
          textShadow: '0px 1px 3px rgba(0, 0, 0, 0.30)',
        },
      },

      '&[data-closed="true"]': {
        bg: 'gray.300',
        color: 'blackAlpha.400',
        textShadow: 'unset',
        px: '3',
      },
    },
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'alpha.800',
  bg: 'alpha.500',
};

export const RaceStatusText: CSSObject = {
  color: 'white',
  // @TODO colour not in palette
  bg: '#DD6B20',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'white',
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  mx: ['2', null, '4'],
};

export const RaceRunnerList: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, beta.600, beta.700)',
  p: '2',
  pt: '0',
};

export const TextTitle: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const RaceName: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  mb: '2',
  pt: '1',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  alignItems: 'center',
  mt: ['1.5', null, 'unset'],
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'alpha.400',
    },
  },
  columnGap: '0',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '42px',
  mx: '2',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  width: '16',
  px: '1',
  svg: {
    color: 'white',
  },
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'white',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};
