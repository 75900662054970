import { Hide, Image, Show } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import logoDesktop from '@/assets/hyperbet/images/logoHyper.png';
import logoMb from '@/assets/hyperbet/images/logoHyperMb.png';
import { THEME_NAME } from '@/constants/themeConfig';

export default function index() {
  return (
    <Link to="/">
      <Show above="lg">
        <Image src={logoDesktop} alt={THEME_NAME.hyperbet} mx="auto" pb={2} />
      </Show>
      <Hide above="lg">
        <Image src={logoMb} alt={THEME_NAME.hyperbet} h="16" mt={1} />
      </Hide>
    </Link>
  );
}
