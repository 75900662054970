import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema, TButton } from './Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    fontSize: 'sm',
    fontWeight: 'bold',
    minW: '30px',
    boxSize: '30px',
    lineHeight: 'normal',
    sx: {
      '&&': {
        h: '8',
        w: 'auto',
      },
    },
  },
};

export const Button = ({ isSelected }: TButton): CSSObject => ({
  bgGradient: isSelected
    ? 'linear(to-b, gamma.600, gamma.600)'
    : 'linear(to-b, alpha.500, alpha.600)',
  color: 'white',
  border: '2px solid',
  borderColor: isSelected ? 'transparent' : 'alpha.700',
  '&&': {
    boxShadow: isSelected
      ? 'inset 0px 2px 0px 1px rgba(0, 0, 0, 0.3)'
      : 'inset 0px 0px 0px 1px #426DD6',
  },
  '&:hover': {
    bgGradient: isSelected ? 'beta.300' : 'linear(to-b, alpha.700, alpha.700)',
    borderColor: isSelected ? 'transparent' : 'alpha.500',
    '&&': {
      boxShadow: 'inherit',
    },
  },
  py: '1',
  boxSize: '30px',
  borderRadius: 'lg',
  fontSize: 'sm',
  fontWeight: 'semibold',
  minWidth: 'unset',
  '&:disabled': {
    boxShadow: 'none',
    cursor: 'default',
    '&&': {
      color: 'gray.500',
      bg: 'blackAlpha.200',
    },
  },
  '&:disabled:hover': {
    bg: 'none',
  },
});

export const CheckboxRunnerBoxed: CSSObject = {
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'gray.700',
    borderColor: 'gray.300',
    _hover: {
      bg: 'white',
    },
  },
  _checked: {
    bg: 'beta.100',

    '.chakra-checkbox__control': {
      border: '1px',
      borderColor: 'beta.200',
    },
  },

  '.chakra-checkbox__label': {
    marginInlineStart: '0',
  },
  flexDir: 'column',
  gap: '2',
  boxSize: '30px',
  borderRadius: 'base',
  bg: 'blackAlpha.200',
  alignItems: 'center',
  justifyContent: 'center',
};

export const FlexOdds: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  bg: 'blackAlpha.200',
  alignItems: 'center',
  w: '12',
  justifyContent: 'center',
  px: '1',
  py: ['1', null, '0.5'],
  minW: '10',
  textAlign: 'center',
  borderRadius: 'base',
  whiteSpace: 'nowrap',
  mr: '0',
  color: 'gray.600',
  h: '30px',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: ['1', null, '2'],
};

export const HStackActions: CSSObject = {
  pr: ['null', '1'],
  alignItems: ['flex-end', 'center'],
  flexDir: 'row',
};