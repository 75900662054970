import { CompetitionBySportV2Schema } from '@/views/sports/CompetitionsBySport/styles/CompetitionBySportV2.styles';

export const competitionBySportV2Styles: CompetitionBySportV2Schema = {
  containerNav: { mb: '3' },
  scrollButtonGroup: {
    mt: '3',
    variant: 'filter',
  },
  scrollButtonGroupDivider: {
    mb: '3',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
  competitionListBoxWrapper: {
    w: 'full',
  },
  scrollButtonGroupButtonCompetition: {
    sx: {
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
  },
  scrollButtonGroupButtonAllLeagues: {
    sx: {
      '&[data-active]': {
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
  },
  hasNextPageButtonOverride: {
    h: '28px',
    w: 'full',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'normal',
    bgGradient: 'linear(to-b, beta.200, beta.400)',
    textTransform: 'uppercase',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
    fontFamily: 'body',
    borderRadius: 'base',
    sx: {
      '&&': {
        button: {
          color: 'blackAlpha.800',
          textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
        },
        fontWeight: 'bold',
        fontSize: '11px',
        color: 'blackAlpha.800',
        svg: {
          bg: 'blackAlpha.700',
          p: '0.5',
          color: 'beta.200',
          borderRadius: 'sm',
        },
      },
    },
  },
};
