import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '1',
};
export const Subheading: CSSObject = {
  color: 'white',
  span: {
    color: 'alpha.400',
  },
};
export const DividerWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'secondary',
  fontWeight: 'bold',
  mb: '3',
};
export const ButtonWithdrawal: CSSObject = {
  variant: 'secondary',
  fontWeight: 'normal',
};
export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
};
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'blackAlpha.700',
    fontWeight: 'medium',
    fontSize: '2xs',
    bg: 'gamma.500',
    p: '1',
    borderRadius: 'base',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const AmountInputWrapperWithdrawal: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
    bg: 'beta.500',
    border: 'none',
    _hover: {
      bg: 'beta.300',
      boxShadow:
        '0px 0px 8px 0px rgba(173, 241, 6, 0.5), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
    },
    _focus: {
      bg: 'alpha.500',
    },
    '#amount-input': {
      h: '42px',
      bg: 'alpha.700',
      border: '3px solid',
      borderColor: 'beta.400',
      borderLeft: 'none',
      borderLeftRadius: 'none',
      _hover: {
        bg: 'alpha.700',
        border: '3px solid',
        borderColor: 'beta.400',
        borderLeftRadius: 'none',
        borderLeft: 'none',
      },
      _focus: {
        bg: 'alpha.900',
        border: '3px solid',
        borderColor: 'beta.400',
        borderLeftRadius: 'none',
        borderLeft: 'none',
      },
    },
  },
  '.chakra-input__left-addon': {
    fontWeight: 'bold',
    fontSize: 'md',
    fontFamily: 'accent',
    color: 'blackAlpha.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    border: 'none',
    borderLeftRadius: 'base',
    h: '42px',
    pt: '1',
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
};
/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '5',
  px: '2',
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const BankRadio: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'blue.600',
    border: '3px solid',
    borderColor: 'white',
    _hover: {
      bg: 'blue.600',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};
export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};
export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};
/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'transparent',
  boxShadow: 'none',
  mt: '0',
  p: '0',
  w: '100%',
};
export const ListContainerHeading: CSSObject = {
  color: 'white',
  fontFamily: 'heading',
  fontSize: 'lg',
  mt: '3',
  '&&': {
    fontWeight: 'normal',
  },
};
/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};
export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px dashed',
  py: '1.5',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};
export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  py: '0.5',
  borderRadius: 'base',
  bg: status === EWithdrawalStatus.Pending && 'gamma.100',
  color: status === EWithdrawalStatus.Pending && 'blackAlpha.700',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  textTransform: 'uppercase',
  fontSize: '2xs',
});
export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const IncreaseAmountWrapper: CSSObject = {
  mb: '3',
};
export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '2',
  variant: 'secondary',
  fontWeight: 'bold',
  h: '26px',
};
export const ButtonWithdrawalCardCancel: CSSObject = {
  bg: 'transparent',
  borderRadius: 'md',
  border: '2px',
  borderColor: 'beta.300',
  boxShadow: '0px 0px 10px 0px rgba(173, 241, 6, 0.7)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    bg: 'transparent',
    borderColor: 'white',
    boxShadow: 'none',
    color: 'beta.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};
export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};
export const WithdrawalWrapper: CSSObject = {
  border: 'none',
  p: '2',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
};
export const WithdrawalCardContent: CSSObject = {
  color: 'white',
  fontFamily: 'body',
  fontWeight: 'semibold',
};
export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
};
export const WithdrawalCardDetailRequested: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'blackAlpha.500',
};
export const InputWithdrawal: CSSObject = {
  '&&': {
    bg: 'blackAlpha.500',
    border: 'none',
    fontSize: 'sm',
    fontWeight: 'bold',
    borderLeftRadius: 'md',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
      color: 'white',
    },
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
    '&&:hover, &&:focus': {
      border: 'none',
      bg: 'blackAlpha.800',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
    },
  },
};
