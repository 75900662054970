import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};
export const BoxTakeActionTable: CSSObject = {
  bg: 'rgba(34, 92, 161, 0.5)',
  borderRadius: 'base',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
  p: '3',
};
export const TextTakeAction: CSSObject = {
  color: 'white',
};
