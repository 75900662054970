import { CSSObject } from '@chakra-ui/react';

export const StartTime: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  color: 'blackAlpha.700',
  borderRadius: '3px',
  bg: 'beta.200',
  px: '1',
  pt: '0.5',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
};
