import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  height: '38px',
};

export const FlexWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  borderBottomRadius: 'lg',
  px: ['3', null, '2.5'],
  bgGradient: 'linear(to-b, alpha.500, alpha.600)',
  pos: 'relative',
  _before: {
    bgGradient: 'linear(to-b, rgba(0, 0, 0, 0), rgba(0, 0, 0, .2))',
    bottom: 'calc(100% + 1px)',
    content: '""',
    h: '17px',
    left: '0',
    pointerEvents: 'none',
    pos: 'absolute',
    right: '0',
    transitionDuration: 'normal',
    transitionProperty: 'common',
  },
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
  },

  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, #FFF 0%, #B2B2B2 100%)',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.30)',
    border: 'none',
    _hover: {
      boxShadow: '0px 0px 8px 0px rgba(67, 187, 255, 0.50)',
    },
  },
  '.chakra-input__left-addon': {
    bg: ' transparent',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    bg: 'alpha.700',
    border: 'none',
    m: '2px',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'alpha.600',
      border: 'none',
    },
    _focus: {
      bg: 'beta.800',
      border: '2px',
      borderColor: 'beta.500',
      borderLeft: 'none',
      m: '0',
      h: '42px',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  bg: 'alpha.500',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  borderBottomRadius: 'lg',
  zIndex: '10',
};

export const InfoHeader: CSSObject = {
  borderTop: '3px',
  borderColor: 'beta.500',
  bg: 'alpha.500',
  h: '9',
  px: '4',
  py: '3',
  alignItems: 'center',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontFamily: 'Staatliches',
  fontWeight: 'normal',
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'sm',
  fontWeight: 'bold',
  boxShadow:
    '0 1px rgba(255, 255, 255, .15), 0 1px rgba(255, 255, 255, .15) inset',
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    },
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-1',
  boxSize: '6',
  color: 'white',
};
