import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  bg: 'alpha.700',
  pr: ['2', '0'],
  mt: '0',
  pb: '3',
  mb: '3',
  gap: '1',
  borderRadius: 'md',
  h: 'fit-content',

  '&&': {
    ml: '2',
  },

  '&:has(+ .subTabBar)': {
    borderBottomRadius: 'none',
  },

  '&.tabBar': {
    bg: 'blackAlpha.400',
    boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.20) inset',
    borderRadius: ['none', 'lg'],
    h: 'fit-content',
    mx: '0',
    pl: '2',
    pb: '0',
    mb: '3',

    '&:has(+ .subTabBar)': {
      borderBottomRadius: 'none',
      mb: '0',
    },
  },
  '&.subTabBar': {
    bg: 'beta.500',
    borderBottomRadius: ['none', 'lg'],
    borderTopRadius: 'none',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
    mt: 0,
    pl: '2',
    mx: '0',
    pb: '0',
    mb: '2',
    h: 'fit-content',
  },
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'quaddiesTab',

  sx: {
    '&&': {
      marginLeft: '0',
      marginRight: '0',
      border: 'none',
    },
    py: '2',
    '&&[data-active="true"]': {
      bg: 'beta.500',
      borderBottom: 'none',
      h: ['100%', '40px'],
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        bg: 'alpha.900',
        color: 'beta.500',
        fontWeight: 'bold',
        fontSize: 'xs',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
    },
    '&.subTab': {
      color: 'gray.700',
      _hover: {
        '&&': {
          color: 'alpha.900',
          borderColor: 'alpha.600',
        },
      },
    },
  },
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: 'alpha.300',
  top: '-5px',
  right: '-5px',
  borderRadius: '3px',
  _before: {
    content: "' '",
    height: '5px',
    width: '2px',
    backgroundColor: 'alpha.900',
    position: 'absolute',
    top: '2px',
    right: '3px',
    transform: 'rotate(45deg)',
  },
  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'alpha.900',
    position: 'absolute',
    top: '6px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};
