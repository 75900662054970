import { CSSObject } from '@chakra-ui/react';
import { EWithdrawalStatus } from '@/lib/DBModels';
import { TWithdrawalStatus } from '../RequestedWithdrawalCard/styles/RequestedWithdrawalCard.styles';
import { CustomButtonProps } from '@/components/Button/Button';

export const Heading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '1',
};
export const Subheading: CSSObject = {
  color: 'white',
  span: {
    color: 'white',
    textDecoration: 'underline',
  },
};
export const DividerWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const TextDescription: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
export const buttonAddAccountProps: CustomButtonProps = {
  variant: 'solid',
  textTransform: 'uppercase',
  mb: '4',
  borderRadius: 'base',
};
export const ButtonWithdrawal: CSSObject = {
  variant: 'solid',
  textTransform: 'uppercase',
  borderRadius: 'base',
};
export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
};
export const InputWrapperWithdrawal: CSSObject = {
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-form__helper-text': {
    color: 'alpha.600',
    fontWeight: 'medium',
    fontSize: '2xs',
    bg: 'beta.400',
    px: '2',
    py: '0.5',
    borderRadius: 'sm',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
  },
  '.chakra-input': {
    borderRadius: 'base',
  },
  '.chakra-form__error-message': {
    bg: 'red.600',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    minH: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: 'sm',
    px: '2',
    py: '0.5',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const AmountInputWrapperWithdrawal: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bg: 'alpha.100',
    border: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '#amount-input': {
      bg: 'alpha.800',
      border: '2px solid',
      borderColor: 'alpha.100',
      fontSize: 'sm',
      fontWeight: 'bold',
      color: 'white',
      borderLeft: 'none',
      borderLeftRadius: 'none',
      fontFamily: 'accent',
      px: '2',
      _placeholder: {
        fontWeight: 'bold',
      },
      _hover: {
        bg: 'alpha.500',
        boxShadow: '0px 0px 12px 0px rgba(28, 213, 255, 0.5)',
      },
      _focus: {
        border: '2px solid',
        borderColor: 'beta.200',
        borderLeft: 'none',
        borderLeftRadius: 'none',
        m: '0',
      },
    },
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, alpha.100, alpha.200)',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-form__error-message': {
    bg: 'beta.400',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'alpha.600',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    svg: {
      fontSize: 'sm',
    },
  },
};
/**
 * Bank Account Radios
 */
export const BankRadioWrapper: CSSObject = {
  w: 'full',
  alignItems: 'center',
  justifyContent: 'space-between',
  py: '3',
  px: '2',
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
};
export const BankRadio: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'alpha.400',
    border: '3px solid',
    borderColor: 'white',
    _hover: {
      bg: 'alpha.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const BankCardContainer: CSSObject = {
  mt: '-3',
  bg: 'transparent',
  color: 'white',
};
export const FlexBankDetailsContainer: CSSObject = {
  fontSize: 'xs',
};
export const TextBankAccountDetail: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    bg: 'white',
  },
  '.chakra-checkbox__control[data-checked]': {
    bg: 'black',
  },
};
/**
 * Withdrawal Requests Aside
 */
export const ListContainer: CSSObject = {
  bg: 'alpha.600',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  mt: '3',
  px: '2',
  py: '0',
  w: '100%',
  borderRadius: 'md',
};
export const ListContainerHeading: CSSObject = {
  color: 'white',
  fontFamily: 'body',
  fontSize: 'md',
  mt: '2',
  textTransform: 'uppercase',
  '&&': {
    ml: '0',
    fontWeight: 'bold',
  },
};
/**
 * Withdrawal Request Card
 */
export const TextBankAccountName: CSSObject = {
  fontSize: 'sm',
};
export const WithdrawalCardDetail: CSSObject = {
  borderBottom: '1px solid',
  py: '1.5',
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
};
export const WithdrawalStatus = ({ status }: TWithdrawalStatus): CSSObject => ({
  px: '1',
  pb: '0',
  pt: 'px',
  lineHeight: 'normal',
  borderRadius: '3px',
  bg: status === EWithdrawalStatus.Pending && 'beta.200',
  color: status === EWithdrawalStatus.Pending && 'blackAlpha.700',
  textTransform: 'uppercase',
  fontSize: '2xs',
  fontWeight: 'bold',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
});
export const CardTitleWithdrawal: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  fontSize: 'sm',
  fontWeight: 'semibold',
};
export const IncreaseAmountWrapper: CSSObject = {
  mb: '3',
};
export const buttonWithdrawalProps: CustomButtonProps = {
  mt: '2',
  variant: 'solid',
  textTransform: 'uppercase',
  borderRadius: 'base',
};
export const ButtonWithdrawalCardCancel: CSSObject = {
  borderRadius: 'md',
  bg: 'alpha.500',
  border: '2px',
  borderColor: 'alpha.100',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'black',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  boxShadow: '0px 0px 10px 0px rgba(28, 213, 255, 0.5)',
  _hover: {
    color: 'beta.300',
    border: '2px',
    borderColor: 'beta.300',
    bg: 'alpha.500',
    boxShadow: '0px 0px 10px 0px rgba(255, 110, 32, 0.5)',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.100',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};
export const FlexWithdrawalWrapper: CSSObject = {
  flexDir: 'column',
};
export const WithdrawalWrapper: CSSObject = {
  border: 'none',
  p: '2',
  bg: 'rgba(34, 92, 161, 0.5)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
  backdropFilter: 'blur(8px)',
};
export const WithdrawalCardContent: CSSObject = {
  color: 'white',
};
export const WithdrawalCardDetailNumber: CSSObject = {
  borderBottom: '1px dashed',
};
export const WithdrawalCardDetailRequested: CSSObject = {
  borderBottom: '1px solid',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  borderBottomColor: 'blackAlpha.400',
};
export const InputWithdrawal: CSSObject = {
  '&&&': {
    border: 'none',
    bg: 'blackAlpha.500',
    fontSize: 'xs',
    fontWeight: 'medium',
    '&&:hover, &&:focus, &&:active': {
      bg: 'blackAlpha.600',
    },
  },
};
