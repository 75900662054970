import { CSSObject } from '@chakra-ui/react';
import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { CustomButtonProps } from '@/components/Button/Button';
import { TCloseIcon } from '@/components/Betslip/components/Modal/Header/styles/Header.styles';

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  py: '2',
  w: 'fill-available',
  px: '1',
  color: 'white',
});

export const FlexBetslipHeading: CSSObject = {
  bg: 'transparent',
  h: '12',
  borderBottom: [null, null, null, '1px'],
  borderTopRadius: 'md',
  mx: '3px',
  mt: '0.5',
  '&&': {
    borderBottomColor: 'rgba(255, 255, 255, 0.06)',
  },
};

export const BetslipTitle: CSSObject = {
  fontWeight: 'normal',
  color: 'beta.400',
  fontFamily: 'Staatliches',
  textShadow: '0px 1px 5px rgba(0, 0, 0, 0.30)',
  fontSize: 'lg',
  svg: {
    color: 'beta.400',
  },
};

export const closeIcon: TCloseIcon = {
  boxSize: '8',
  as: CloseCircle,
  color: 'white',
};

export const CloseIconButton: CustomButtonProps = {
  border: 'none',
  bg: 'transparent',

  _hover: {
    border: 'none',
    bg: 'none',
  },
};

export const BetslipHeaderIcon: CSSObject = {
  color: 'gamma.400',
  boxSize: '5',
};

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const BetLengthIndicator: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'rgba(255, 183, 29, 1)',
  fontSize: 'xs',
  borderRadius: 'base',
  fontFamily: 'roboto',
  fontWeight: 'bold',
  boxSize: '5',
  lineHeight: 'normal',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'green.300' : 'red.300',
});

export const BetslipTitleText: CSSObject = {
  color: 'white',
  fontFamily: 'KHInterference',
  fontSize: 'md',
  textTransform: 'uppercase',
  fontWeight: 'bold',
};
