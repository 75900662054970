import { CSSObject } from '@chakra-ui/react';
import mascot from '@/assets/hyperbet/images/header/mascot.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxBallance: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  borderX: '2px',
  borderColor: 'white',
  '> div:first-of-type': {
    color: 'gamma.300',
    '> p': {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
  '> div:last-of-type': {
    color: 'white',
    '> p': {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    '> div': {
      '> p': {
        fontSize: 'md',
      },
    },
  },
};
export const buttonDepositProps: CustomButtonProps = {
  variant: 'secondary',
  fontWeight: 'normal',
  w: 'full',
  mt: '2',
  sx: {
    '& svg': {
      filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.40))',
      mb: '1',
    },
  },
};
export const ButtonNavItem = (): CSSObject => ({
  bg: 'white',
  color: 'gray.700',
  borderTop: '1px',
  borderColor: 'gray.300',
  '&& > p': { transition: 'all 0.2s ease-in-out' },
  ':first-child': {
    borderTop: 'none',
  },
  '&:hover, &:active': {
    bg: 'beta.300',
  },
});
export const BoxMascotImage: CSSObject = {
  bgImage: `url(${mascot})`,
  bgSize: '100%',
  bgPos: 'inherit',
  bgRepeat: 'no-repeat',
  zIndex: '-1',
  display: ['none', null, null, null, 'block'],
  w: '88px',
  h: '32',
  pos: 'absolute',
  top: '0.5',
  left: '0',
};
export const PopoverHeaderQA: CSSObject = {
  bg: 'alpha.300',
  borderTop: '2px',
  borderX: '2px',
  borderColor: 'white',
  borderBottom: 'none',
  borderTopRadius: 'md',
  pb: '0',
  color: 'white',
  lineHeight: 'normal',
};
export const PopoverContentQA: CSSObject = {
  boxShadow: '2xl',
  top: -2,
  _focus: {
    boxShadow: '2xl',
  },
};
export const FlexBallanceItem: CSSObject = {
  color: 'white',
  p: {
    _last: { fontWeight: 'extrabold' },
  },
};
export const TextBalanceValue: CSSObject = {
  color: 'alpha.500',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.2)',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};
export const TextQuickAccessHello: CSSObject = {
  fontSize: 'sm',
};
export const buttonBalanceProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  fontSize: 'sm',
  h: 'unset',
};
