import { SRMInfoSchema } from './SRMInfo.styles';

export const srmInfoStyles: SRMInfoSchema = {
  runnerDetails: {
    fontSize: 'xs',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'body',
  },
  placeNumber: {
    fontWeight: 'extrabold',
    fontSize: 'xs',
    color: 'white',
    fontFamily: 'body',
    sx: {
      '> span': { fontSize: '2xs', fontWeight: 'medium' },
    },
  },
  eventMarketName: {
    fontSize: 'xs',
    fontWeight: 'bold',
    color: 'beta.500',
    fontFamily: 'body',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    py: '0.5',
  },
  eventSubtitle: {
    fontSize: 'xs',
    fontWeight: 'bold',
    fontFamily: 'body',
  },
  srmContainer: {
    w: 'full',
    flexDir: 'column',
    mb: '1',
    pt: '2',
    borderBottom: '1px dashed',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderBottomColor: 'blackAlpha.400',
    _first: {
      borderTop: '1px dashed',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
      borderTopColor: 'blackAlpha.400',
    },
  },
};
