import React from 'react';
import { CSSObject } from '@chakra-ui/react';
import { TBetReturn } from '../HeaderRow.types';
import IconSvg from '@/components/common/IconSvg';
import { HeaderRowSchema } from './HeaderRow.styles';

export const RunnerDetails: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  svg: {
    color: 'beta.500',
    fill: 'black',
  },
};
export const RunnerDescription: CSSObject = {
  fontWeight: 'bold',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xs',
  color: 'white',
  span: {
    color: 'beta.500',
  },
  'span:nth-child(2)': {
    color: 'rgba(255, 255, 255, 0.24)',
  },
};
export const BetOdds: CSSObject = {
  color: 'white',
};
export const TextBarrierNumber: CSSObject = {
  color: 'beta.500',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const BetReturn = (status: TBetReturn): CSSObject => ({
  label: status,
  bg: () => {
    switch (status) {
      case 'won':
        return 'green.500';
      case 'pending':
        return 'beta.800';
      default:
        return 'beta.300';
    }
  },
  color: () => {
    switch (status) {
      case 'won':
        return 'blackAlpha.700';
      case 'pending':
        return 'blackAlpha.800';
      default:
        return 'blackAlpha.800';
    }
  },
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  py: '0',
});
export const HeaderSvg: CSSObject = {
  filter: 'drop-shadow(0px 0px 9px rgba(38, 215, 255, 0.5))',
  boxSize: '5',
};
export const BetMultiIcon: CSSObject = {
  boxSize: '5',
};
export const MultiIcon = () => (
  <IconSvg
    name="multi_betslip"
    className="svgIcon"
    color="gamma.300"
    boxSize="5"
    mb="1.5"
  />
);
export const TextDescription: CSSObject = {
  textTransform: 'capitalize',
  '> a': {
    ml: '0',
  },
};
export const headerRowStyles: HeaderRowSchema = {
  originalOdds: {
    color: 'gray.100',
  },
  headerRowSvg: {
    fill: 'beta.600',
  },
};
