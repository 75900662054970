import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    bgGradient: 'linear(to-b, gamma.300, gamma.400)',
    borderRadius: 'base',
    fontFamily: 'body',
    color: 'alpha.800',
    fontWeight: 'bold',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.2)',
    px: '2',
    fontSize: '2xs',
    textTransform: 'uppercase',
    sx: {
      '&[data-active="true"]': {
        color: 'alpha.300',
        bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
        boxShadow:
          '0px 2px 3px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
        textShadow: 'none',
      },
      '&&': {
        h: '21px',
      },
    },
  },
};

export const TextRaceAvailability: CSSObject = {
  color: 'white',
};
