import * as betprofessor from './account.styles.betprofessor';
import * as betroyale from './account.styles.betroyale';
import * as fiestabet from './account.styles.fiestabet';
import * as puntcity from './account.styles.puntcity';
import * as puntgenie from './account.styles.puntgenie';
import * as questbet from './account.styles.questbet';
import * as sterlingparker from './account.styles.sterlingparker';
import * as vikingbet from './account.styles.vikingbet';
import * as volcanobet from './account.styles.volcanobet';
import * as goldenrush from './account.styles.goldenrush';
import * as juicybet from './account.styles.juicybet';
import * as oldgill from './account.styles.oldgill';
import * as gigabet from './account.styles.gigabet';
import * as templebet from './account.styles.templebet';
import * as goldenbet888 from './account.styles.goldenbet888';
import * as hyperbet from './account.styles.hyperbet';
import * as bet777 from './account.styles.bet777';
import * as chromabet from './account.styles.chromabet';
import * as bet575 from './account.styles.bet575';
import * as betblast from './account.styles.betblast';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  goldenrush,
  juicybet,
  oldgill,
  gigabet,
  templebet,
  goldenbet888,
  hyperbet,
  bet777,
  chromabet,
  bet575,
  betblast,
};
