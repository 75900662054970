import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid rgba(255, 255, 255, 0.16)',
    borderRadius: 'lg',
    mt: '3',
  },
  body: {
    color: 'white',
    px: '4',
    pt: '3',
    pb: '0',
    fontSize: 'sm',
    fontWeight: 'semibold',
  },
};
