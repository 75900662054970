import React from 'react';
import IconSvg from '../../../../common/IconSvg';
import { TSideIcon } from '../../../services/NavIcon.types';

export const HomeIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="HomeHB" className="svgIcon" sx={styles.icon} />
);
export const DepositIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="DepositHB" className="svgIcon" sx={styles.icon} />
);
export const SportsIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="SportsHB" className="svgIcon" sx={styles.icon} />
);
export const PromoIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="PromoHB" className="svgIcon" sx={styles.icon} />
);
export const ContactIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="ContactUsHB" className="svgIcon" sx={styles.icon} />
);
export const MyAccountIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="MyAccountHB" className="svgIcon" sx={styles.icon} />
);
export const ResponsibleGamblingIcon = ({ styles }: TSideIcon) => (
  <IconSvg name="ResponsibleGambling" className="svgIcon" sx={styles.icon} />
);
