import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  borderRadius: 'md',
  boxShadow:
    '0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 2px 1px rgba(255, 255, 255, 0.25) inset',
  boxSize: '10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'blackAlpha.700',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  display: 'grid',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  px: '3',
  alignItems: 'center',
  gridTemplateColumns: '48px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  borderRadius: 'md',
  w: '100%',
  h: '64px',
  _hover: {
    bg: 'alpha.500',
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'normal',
  fontSize: 'md',
  color: 'white',
  mt: '1.5',
  fontFamily: 'accent',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'white',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'white',
  mx: '0',
  px: '0',
  boxSize: '7',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'white',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'extrabold',
  color: 'white',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'beta.300',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'full',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
