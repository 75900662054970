import { CSSObject } from '@chakra-ui/react';
import bg from '../../../../assets/questbet/images/racing/heading.png';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '8',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'alpha.800',
          color: 'white',
          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
          border: 'none',
        },
      },
      '&[data-closed="true"]': {
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
        bg: 'gray.300',
        color: 'blackAlpha.400',
        textShadow: 'unset',
        px: '3',
      },
    },
  },
};

export const TextRaceConditions: CSSObject = {
  color: 'alpha.800',
};

export const RaceRunnerListHeadings: CSSObject = {
  my: '2',
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: ['2xs', 'xs'],
  color: 'alpha.800',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  my: '0',
};

export const ButtonGroupWrapper: CSSObject = {
  py: '1',
  gap: '1',
  pt: 2,
  '&&': {
    '.quaddie': {
      boxShadow:
        '0px 0px 0px 2px #C73337 inset, 0px 2px 5px rgba(0, 0, 0, 0.15)',
      bgGradient: 'linear-gradient(to-b, #67C1D5, #126172)',
      color: 'white',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.30)',
      '&.active': {
        boxShadow:
          '0px 0px 0px 2px #C73337 inset, 0px 2px 5px rgba(0, 0, 0, 0.15)',
        color: 'white',
        bgGradient: 'linear-gradient(to-b, alpha.700, alpha.700)',
      },
      '&:hover': {
        bgGradient: 'linear-gradient(to-b, alpha.800, alpha.800)',
        boxShadow: '0px 0px 0px 2px #C73337 inset, 0px 2px 5px rgba(0, 0, 0, 0.15)',
      },
    },
  },
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  border: '1px',
  borderColor: 'alpha.100',
  bg: 'gray.50',
  borderRadius: 'base',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    w: '12',
    p: '0',
  },
};

export const RaceHeaderWrapper: CSSObject = {
  bgImage: [null, null, `url(${bg})`],
  bgSize: '100% 100%',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'md',
  boxShadow: [null, null, 'lg'],
  p: ['0', '0', '2'],
  transition: '.3s ease-in-out',
};

export const TextTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'md',
  color: 'alpha.800',
};

export const ResultedStatusLabel: CSSObject = {
  color: 'white',
  bg: '#05B60C',
};

export const RaceStatusText: CSSObject = {
  color: 'white',
  bg: '#718096',
};

export const RaceAndTimerContainer: CSSObject = {
  bg: ['transparent', 'transparent', 'rgba(237, 242, 247, 1)'],
  borderRadius: 'base',
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  mb: '0',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'beta.400',
    },
  },
  columnGap: '0',
  svg: {
    color: 'white',
  },
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'gray.800',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};
