import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'beta.500',

      background: {
        background: 'alphaAlpha.800',
        display: 'flex',
        flexDir: 'column',
        borderRadius: 'base',
        px: ['0', null, null, '3'],
        py: ['0', null, null, '1'],
        mb: ['0', null, null, '4'],
        border: ['unset', null, '1px solid rgba(149, 239, 255, 0.2)'],
        borderRight: [
          '1px solid rgba(255, 255, 255, 0.15)',
          null,
          '1px solid rgba(149, 239, 255, 0.2)',
        ],
        boxShadow:
          '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
        backdropFilter: 'blur(8px)',
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        borderRadius: 'none',
        background: 'alpha.500',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.40)',
        gap: '2',
        maxW: ['100%', '370px', null, null],
        backdropFilter: 'blur(8px)',
      },
      drawerBody: {
        label: 'drawerBody',
        '&::-webkit-scrollbar': { display: 'none' },
        '&&': {
          px: '2',
        },
      },
      icon: {
        ...base.icon,
        boxSize: '28px',
        mr: ['1', null, '2.5'],
        bgGradient: ['transparent', null, 'linear(to-b, alpha.100, alpha.200)'],
        boxShadow: [
          'unset',
          null,
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
        ],
        filter: [
          'drop-shadow(0px 0px 9px rgba(28, 213, 255, 0.5))',
          null,
          'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.3))',
        ],
        color: ['#1CD5FF', null, 'blackAlpha.800'],
        p: ['1', null, '1.5'],
        borderRadius: 'base',
      },
      headerSX: {
        cursor: 'default',
        color: ['beta.300', null, 'white'],
        fontSize: 'xs',
        height: 'auto',
        mb: '0',
        py: 2,
        mt: ['3', null, '0'],
        fontWeight: 'black',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
        textShadow: 'none',
        pointerEvents: 'none',
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: [null, null, null, 'md'],
        mb: '0',
      },
      link: {
        ...base.link,
        textTransform: 'uppercase',
        fontSize: 'xs',
        fontWeight: 'bold',
        height: ['40px', null, '45px'],
        color: 'white',
        fontFamily: 'accent',
        textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
        background: 'transparent',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
        py: ['0.5', null, '1.5'],
        px: ['1.5', null, '0'],
        ml: '0',
        '&&': {
          mb: '0',
        },
        ':last-child': {
          borderBottom: 'none',
          boxShadow: 'unset',
          mb: '0',
        },

        '~ .nested': {
          pl: '0',
        },

        ':hover': {
          color: 'beta.200',
          textShadow: '0px 0px 12px #FF6E20',
          '.icon, .svgIcon': {
            bgGradient: ['unset', null, 'linear(180deg, #FF965E, #F36417)'],
            color: ['beta.200', null, 'blackAlpha.800'],
            filter: ['drop-shadow(0px 0px 12px #FF6E20)', null, 'unset'],
          },
        },

        ':active': {
          background: 'alpha.700',
          opacity: '1',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          fill: 'none',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['3', null, null, '2'],
        },

        '> a, .dropDownIcon, a': {
          color: 'alpha.100',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            px: ['3', null, null, '2'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: '0',
        h: '45px',
        mb: '1.5',
        '.dropDownInner': {
          px: '2.5',
          color: 'beta.200',
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
          textShadow: '0px 0px 12px #FF6E20',
          'svg:not(.dropDownIcon)': {
            fill: 'gamma.400',
          },
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'gamma.500',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
