import { CSSObject, IconProps, TextProps } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonDepositsAvailableProps: CustomButtonProps = {
  variant: 'outline',
  fontSize: '11px',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontWeight: 'black',
  textTransform: 'uppercase',
};
export const DepositContainer: CSSObject = {
  mb: '2',
};
export const DefaultText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};
export const ImageCardLogo: CSSObject = {
  bg: 'white',
  border: '1px',
  borderColor: 'gray.300',
  borderRadius: 'base',
  p: '1',
  w: '12',
  h: '7',
};
export const RowDivider: CSSObject = {
  borderColor: 'rgba(0, 0, 0, 0.4) ',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
  borderStyle: 'dashed',
};
export const FlexWrapper: CSSObject = {
  py: '3',
  '.chakra-radio': {
    mr: 'auto',
  },
};
export const TextLastFour: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  ml: '3',
};
export const BadgeWrapper: CSSObject = {
  alignSelf: 'center',
  fontSize: 'xs',
  ml: '4',
  p: '1',
};
export const BoxCardsContainer: CSSObject = {
  mb: '2',
};
export const BoxInputContainer: CSSObject = {
  mb: '2',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, alpha.100, alpha.200)',
    border: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '#amount-input': {
      bg: 'alpha.800',
      border: '2px solid',
      borderColor: 'alpha.100',
      fontSize: 'sm',
      fontWeight: 'bold',
      color: 'white',
      borderLeft: 'none',
      borderLeftRadius: 'none',
      px: '2',
      fontFamily: 'accent',
      _placeholder: {
        fontWeight: 'bold',
      },
      _hover: {
        bg: 'alpha.500',
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 8px 0px rgba(28, 213, 255, 0.5)',
      },
      _focus: {
        boxShadow: 'none',
        border: '2px solid',
        borderColor: 'beta.200',
        borderLeft: 'none',
        borderLeftRadius: 'none',
        m: '0',
      },
    },
  },
  '.chakra-input__left-addon': {
    bgGradient: 'linear(to-b, alpha.100, alpha.200)',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-form__error-message': {
    bg: 'red.600',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    pt: '0',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const IncreaseAmountContainer: CSSObject = {
  mb: '2',
};
export const TextSSL: CSSObject = {
  color: 'white',
  fontSize: 'xs',
};
export const IconSSL: CSSObject = {
  boxSize: '4',
  mx: '1',
};
export const RadioCard: CSSObject = {
  bg: 'white',
  '&[data-checked]': {
    boxShadow: 'none',
    bg: 'alpha.400',
    borderColor: 'white',
    _hover: {
      bg: 'alpha.400',
      borderColor: 'white',
    },
    _before: {
      display: 'none',
      content: 'unset',
    },
  },
};
export const bannerHeaderProps: TextProps = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  lineHeight: '14.4px',
  color: 'alpha.100',
};
export const bannerTextProps: TextProps = {
  fontSize: 'xs',
  color: 'white',
  mt: '0.5',
};
export const buttonDepositProps: CustomButtonProps = {
  variant: 'solid',
  textTransform: 'uppercase',
  borderRadius: 'base',
};
export const IconButton: CSSObject = {
  h: '8',
  ml: '2.5',
  minWidth: '8',
  color: 'alpha.800',
};

export const TrashIcon: IconProps = {
  bg: 'none',
  bgGradient: 'linear(to-b, alpha.100, alpha.200)',
  color: 'blackAlpha.800',
  boxSize: '8',
  p: '2',
  borderRadius: 'base',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
};
