import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bgGradient: [
    'linear(to-b, #324361, #324361)',
    null,
    null,
    null,
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  ],
  h: '100%',
  maxH: '100dvh',
  borderRadius: 'lg',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', '320px', '272px'],
  border: '1px solid rgba(255, 255, 255, 0.16)',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '2',
  mx: '3px',
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipContainer: BoxProps = {
  borderRadius: 'base',
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'alpha.100',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: 'sm',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'beta.300',
  fontFamily: 'Roboto',
  fontSize: 'sm',
});

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'unstyled',
  display: 'flex',
  height: '6',
  margin: 'auto',
  mt: '-5',
  w: 'auto',
  pl: 1,
  pr: 2,
  fontWeight: 'bold',
  fontFamily: 'accent',
  bgGradient: 'linear(to-b, alpha.600, alpha.800)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  border: '2px solid rgba(255, 183, 29, 1)',
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '1',
    },
    '.chakra-icon': {
      boxSize: '3',
    },

    '&&': {
      fontSize: 'xs',
      fontFamily: 'accent',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: 'white',
      textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',

      '&:hover, &:active': {
        bgGradient: 'linear(to-b, alpha.700, alpha.800)',
      },
    },
  },
};

export const BetslipVStack: CSSObject = {
  gap: '2.5',
};

export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  height: '42px',
  sx: {
    '&&': {
      fontWeight: 'normal',
    },
  },
};
