import { Image, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as LinkDom } from 'react-router-dom';
import logo from '@/assets/hyperbet/images/logoHyper.png';
import TemplateAside from './template.default';
import { combineTemplate } from '@/lib/templateSwitch';

export default combineTemplate(TemplateAside, [
  {
    name: 'toggle',
    component: (
      <Link
        aria-label="aside logo Mobile"
        as={LinkDom}
        to="/"
        maxW={['58vw', '500px', '220px']}
        alignSelf="center"
        mb={['0', null, '12']}
        mt={['10', '12vw', '8']}
        ml={6}
      >
        <Image src={logo} />
      </Link>
    ),
    config: {
      before: 'slogan',
    },
  },
]);
