import { ButtonProps, CSSObject } from '@chakra-ui/react';
import { TIndex } from '@/components/FormElements/Input';

export const PageHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
};
export const TextSubheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};
export const Subheading: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const FormDivider: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'blackAlpha.500',
  width: '100%',
  borderTop: 'none',
  mb: '4',
};
export const DepositLimitGrid: CSSObject = {
  pb: '20',
};
export const ModalSubheading: CSSObject = {
  color: 'white',
  p: '0',
};
export const DepositButton: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
  color: 'alpha.900',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.3)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.300 0%, alpha.300 100%)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    '&&:hover': {
      bgGradient: 'linear(to-b, alpha.300 0%, alpha.700 100%)',
      color: 'alpha.900',
      opacity: 0.4,
    },
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const CancelButton: CSSObject = {
  variant: 'secondary',
};
export const ButtonSubmit: CSSObject = {
  variant: 'secondary',
};
export const DepositLimitContainer: CSSObject = {
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  p: '4',
  color: 'white',
  fontWeight: 'bold',
};
export const PendingLimitContainer: CSSObject = {
  border: 'none',
  color: 'white',
  fontWeight: 'bold',
};
export const DepositLimitInputContainer: CSSObject = {
  mb: '3',
  '.chakra-form-control': {
    mb: '0',
  },
  '.chakra-form__label': {
    color: 'white',
  },
  '.chakra-input__group': {
    borderRadius: 'base',
    bg: 'alpha.200',
    border: 'none',
    _hover: {
      boxShadow:
        '0px 0px 8px 0px rgba(173, 241, 6, 0.5), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
    },
  },
  '.chakra-input__left-addon': {
    fontWeight: 'bold',
    fontSize: 'md',
    fontFamily: 'accent',
    color: 'blackAlpha.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    border: 'none',
    borderLeftRadius: 'base',
    h: '42px',
    pt: '1',
  },
  '.chakra-select__wrapper': {
    svg: {
      color: 'rgba(173, 241, 6, 1) !important',
    },
  },
  '#frequency-select': {
    bg: 'blackAlpha.500',
    shadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: 'none',
    option: {
      color: 'black',
    },
  },
  '.chakra-form__error-message': {
    bg: 'orange.500',
    shadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset',
    color: 'white',
    h: '20px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '2xs',
    borderRadius: '3px',
    px: '8px',
    py: '4px',
    svg: {
      fontSize: 'sm',
    },
  },
};
export const ModalContentWrapper: CSSObject = {
  p: '0',
};
export const ModalDivider: CSSObject = {
  display: 'none',
};
export const buttonRemoveProps: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
  borderRadius: 'md',
  color: 'alpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    color: 'alpha.400',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'gray.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    boxShadow:
      '0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    pointerEvents: 'none',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};
export const buttonRemoveModalProps: ButtonProps = {
  variant: 'outline',
  fontWeight: 'normal',
  width: 'full',
};
export const buttonConfirmModalProps: ButtonProps = {
  variant: 'odds',
  fontWeight: 'normal',
};

export const inputProps: Partial<TIndex> = {
  sxWrapper: {
    '&&': {
      '.chakra-input': {
        h: '42px',
        bg: 'alpha.700',
        border: '3px solid',
        borderColor: 'beta.400',
        borderLeft: 'none',
        _hover: {
          bg: 'alpha.700',
          border: '3px solid',
          borderColor: 'beta.400',
          borderLeft: 'none',
        },
        _focus: {
          bg: 'alpha.900',
          border: '3px solid',
          borderColor: 'beta.400',
          borderLeft: 'none',
        },
      },
    },
  },
};
