import { CSSObject, FlexProps } from '@chakra-ui/react';

export const flextEventSubTitleProps: FlexProps = {
  alignItems: 'center',
  sx: {
    'div[class*="CountdownTimerBox"]': {
      mb: '0.5',
    },
  },
};

export const HeaderContainer = (): CSSObject => ({
  position: 'relative',
  px: '2',
  pt: '2',
  w: 'fill-available',
  borderRadius: 'none',
  color: 'white',
});

export const FlexIconPropName: CSSObject = {
  gap: '2',
};

export const SpanBarrierNumber: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  fontWeight: 'medium',
  pos: 'relative',
  bottom: '3px',
};

export const IconOddsChangeArrow = (direction: 'increase' | 'decrease') => ({
  color: direction === 'increase' ? 'beta.500' : 'red.400',
});

export const LinkMarket: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
};

export const CountdownTimerBox: CSSObject = {
  '.chakra-badge.state-inprogress': {
    bg: 'beta.500',
    color: 'beta.800',
    borderRadius: 'base',
  },

  '.chakra-badge.state-prior': {
    bg: 'blackAlpha.500',
    color: 'white',
    borderRadius: 'base',
  },

  '.chakra-badge.state-ended': {
    bg: 'gamma.400',
    color: 'white',
    borderRadius: 'base',
  },
};
