import * as betgalaxy from './AccountWrapper.styles.betgalaxy';
import * as betprofessor from './AccountWrapper.styles.betprofessor';
import * as betroyale from './AccountWrapper.styles.betroyale';
import * as fiestabet from './AccountWrapper.styles.fiestabet';
import * as goldenrush from './AccountWrapper.styles.goldenrush';
import * as juicybet from './AccountWrapper.styles.juicybet';
import * as puntcity from './AccountWrapper.styles.puntcity';
import * as puntgenie from './AccountWrapper.styles.puntgenie';
import * as questbet from './AccountWrapper.styles.questbet';
import * as sterlingparker from './AccountWrapper.styles.sterlingparker';
import * as vikingbet from './AccountWrapper.styles.vikingbet';
import * as volcanobet from './AccountWrapper.styles.volcanobet';
import * as wellbet from './AccountWrapper.styles.wellbet';
import * as forsport from './AccountWrapper.styles.forsport';
import * as junglebet from './AccountWrapper.styles.junglebet';
import * as oldgill from './AccountWrapper.styles.oldgill';
import * as sugarcastle from './AccountWrapper.styles.sugarcastle';
import * as buffalobet from './AccountWrapper.styles.buffalobet';
import * as titanbet from './AccountWrapper.styles.titanbet';
import * as slambet from './AccountWrapper.styles.slambet';
import * as gigabet from './AccountWrapper.styles.gigabet';
import * as templebet from './AccountWrapper.styles.templebet';
import * as goldenbet888 from './AccountWrapper.styles.goldenbet888';
import * as hyperbet from './AccountWrapper.styles.hyperbet';
import * as bet777 from './AccountWrapper.styles.bet777';
import * as chromabet from './AccountWrapper.styles.chromabet';
import * as bet575 from './AccountWrapper.styles.bet575';
import * as betblast from './AccountWrapper.styles.betblast';
import { themeName } from '@/helpers/getThemeConfig';

export const imports = {
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  forsport,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  templebet,
  goldenbet888,
  hyperbet,
  bet777,
  chromabet,
  bet575,
  betblast,
} as Record<string, any>;

export const styleImports = imports[themeName];
