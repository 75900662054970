import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  btnGroupWrapper: {
    borderRadius: '5px',
    bgGradient: 'linear(to-b, alpha.100 0%, alpha.300 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    minW: '90px',
    gap: 0,
    color: 'white',
  },
  btnGroupText: {
    fontSize: 'xs',
    bg: '#739dd9',
    color: 'alpha.500',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.2)',
    fontWeight: 'black',
    fontFamily: 'Roboto',
    h: 'calc(100% - 2px)',
    lineHeight: '36px',
    w: '35px',
  },
  btnGroupIcon: {
    color: 'white',
    filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.6))',
    w: '35px',
    fontWeight: 'black',
  },
};
export default styles;
