import { CSSObject } from '@chakra-ui/react';
import featBg from '@/assets/betblast/images/feature/featDesktop.png';
import featBgMb from '@/assets/betblast/images/feature/featMb.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    borderRadius: 'base',
    w: '42px',
    fontSize: 'xs',
    lineHeight: 'normal',
    sx: {
      '&[data-active]': {
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.35) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
        color: 'beta.400',
        textShadow: '0px 0px 9px rgba(243, 100, 23, 0.6)',
      },
    },
  },
};

export const BoxCard: CSSObject = {
  px: '4',
  py: '2',
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${featBg})`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    bgPos: ['top', null, '20% 30%'],
    pos: 'absolute',
    h: ['200px', 'auto'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', 'md'],
    m: '0.5',
  },
  boxShadow:
    '0px 0px 20px 0px rgba(255, 110, 32, 0.5), 0px 0px 0px 1px rgba(7, 16, 33, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.2) inset',
  border: '2px solid',
  borderColor: 'beta.400',
  bg: 'alpha.700',
  flex: '2',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flexDir: 'column',
  borderRadius: 'lg',
  minH: '250px',
  py: ['2', '4'],
  px: ['0', '3'],
  mx: ['1.5', '0'],
  mb: 0,
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: ['center', null, null, 'space-between'],
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mb: ['0', null, '60px'],
  mt: ['60px', null, '0'],
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: {
    base: `"."`,
    md: `". . ."`,
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'alpha.100',
  fontFamily: 'accent',
  fontSize: ['20px', '22px'],
  textTransform: 'uppercase',
  textShadow: '0px 5.36px 10.72px 0px rgba(0, 0, 0, 0.4)',
  fontWeight: 'bold',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'beta.500',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontFamily: 'accent',
  fontWeight: 'extrabold',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.20)',
  ml: '1',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'roboto',
  bg: 'beta.300',
  borderRadius: '3px',
  color: 'blackAlpha.800',
  px: '0.5',
  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: 'full',
  minH: '50px',
  bg: 'alphaAlpha.800',
  borderRadius: 'base',
  px: '1',
  border: '1px solid rgba(149, 239, 255, 0.2)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
  backdropFilter: 'blur(8px)',
};

export const LinkFeatureCard: CSSObject = {
  display: ['none', null, null, 'block'],
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
