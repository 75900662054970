import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bg: 'alpha.600',
  color: 'alpha.100',
  backdropFilter: 'blur(8px)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
  border: '1px solid',
  borderColor: 'alpha.400',
  h: 'full',
  borderRadius: 'md',
  _hover: {
    bg: 'alpha.400',
  },
  svg: {
    filter: 'drop-shadow(0px 0px 9px rgba(28, 213, 255, 0.5))',
  },
};
export const BoxDropdown: CSSObject = {
  bg: 'alpha.600',
  border: '1px solid',
  borderColor: 'alpha.400',
  backdropFilter: 'blur(8px)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'white',
  },
};
export const TextTitle: CSSObject = {
  color: 'white',
};
