import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  variant: 'odds',
  w: '12',
  h: '8',
  fontSize: 'xs',
  borderRadius: 'base',
};
export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '0.5',
  width: 'full',
  mx: 'px',
  maxH: 'calc(var(--bc-space-3) + 2px)',
  position: 'absolute',
  top: '0',
  borderTopRadius: 'base',
  bg: 'alpha.600',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  borderBottom: 'none',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px -1px 0px 0px rgba(0, 0, 0, 0.05) inset',
};
export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'white',
  pos: 'relative',
  bottom: 'px',
};
export const favouriteTextProps: CSSObject = {
  fontSize: '9px',
  color: 'white',
  textShadow: '0px 2px 0px rgba(0, 0, 0, 0.15)',
  textTransform: 'uppercase',
};
