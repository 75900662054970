import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: ['0', null, '4'],
  mb: ['2', null, 'unset'],
  mt: 'unset',
  borderRadius: 'md',
  bg: 'alpha.600',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset, 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
  backdropFilter: 'blur(8px)',
};

export const BoxWrapper: CSSObject = {
  padding: '2.5',
};

export const TextHeading: CSSObject = {
  color: 'alpha.100',
  fontSize: '13px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'accent',
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  borderTop: '1px solid rgba(149, 239, 255, 0.2)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'blackAlpha.800',
  mr: '0',
  textAlign: 'center',
  fontSize: 'xs',
  fontWeight: 'black',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  width: 'full',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, beta.100, beta.100)',
  },
};
