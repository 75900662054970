import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  my: ['0', null, '4'],
  mb: ['2', null, 'unset'],
  mt: 'unset',
  fontFamily: 'body',
  borderRadius: 'lg',
  bgGradient: 'linear(to-b, #FFFFFF1F, #FFFFFF0D)',
  bg: 'alpha.400',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 2px 12px 0px rgba(0, 0, 0, 0.45)',
};

export const BoxWrapper: CSSObject = {
  padding: '11px',
};

export const TextHeading: CSSObject = {
  color: 'white',
  fontSize: 'base',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'accent',
};

export const ButtonViewMore: CSSObject = {
  bgGradient: 'linear(to-b, beta.200, beta.500)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'blackAlpha.700',
  mr: '0',
  textAlign: 'center',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  width: 'full',
  _hover: {
    opacity: 0.9,
  },
};
