import { CSSObject } from '@chakra-ui/react';

export const TakeActionWrapper: CSSObject = {
  py: '2',
};
export const BoxTakeActionTable: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  border: '0',
  p: '3',
};
export const TextTakeAction: CSSObject = {
  color: 'white',
};
