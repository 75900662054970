import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    display: 'flex',
    variant: 'secondary',
    h: '22px',
    px: '1.5',
    w: 'fit-content',
    borderRadius: 'base',
    spanProps: {
      fontFamily: 'accent',
      fontWeight: 'bold',
      fontSize: '11px',
      textTransform: 'uppercase',
      pt: '0.5',
    },
    _disabled: {
      boxShadow: 'none',
      border: 'none',
      bg: 'blackAlpha.300',
      color: 'beta.50',
      opacity: '1',
      '& > span': {
        _first: {
          color: 'green.300',
          mr: '1',
        },
      },
    },
    sx: {
      '.chakra-button__icon': {
        display: 'none',
      },
      ':active, &[data-active=true]': {
        bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'beta.300',
      },
      '&&[data-isbonusbet="true"]': {
        _disabled: {
          w: '100%',
          mt: '-3',
          py: '0.5',
          bgGradient: 'linear(to-b, blackAlpha.600, blackAlpha.600)',
          borderRadius: 'none',
          borderBottomRadius: 'md',
          pointerEvents: 'none',
          '.chakra-button__icon': {
            display: 'block',
            color: 'green.400',
            mr: '0.5',
          },
          textShadow: 'none',
          lineHeight: 'normal',
          span: {
            fontWeight: 'medium',
            fontSize: '2xs',
            fontFamily: 'body',
            color: 'white',
            pt: '0.5',
            textTransform: 'uppercase',
          },
        },
        bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
        color: 'beta.300',
        border: 'none',
        px: '1.5',
        h: '22px',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        textShadow: 'none',
        spanProps: {
          fontFamily: 'accent',
          fontWeight: 'normal',
          fontSize: '11px',
          pt: '0.5',
        },
        _hover: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    py: 2,
    alignItems: 'flex-start',
  },
  iconMysteryBetFooter: {
    alignSelf: 'unset',
    color: 'beta.500',
  },
  flexBetInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'body',
    fontSize: 'sm',
    lineHeight: '1.25',
    sx: {
      'class*="textSlash"': {
        color: 'whiteAlpha.400',
        textShadow: 'none',
      },
    },
  },
  spanBetOdds: {
    fontFamily: 'body',
    fontSize: 'xs',
    color: 'alpha.100',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  spanPropositionType: {
    fontFamily: 'body',
    color: 'beta.300',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'capitalize',
    fontSize: 'xs',
  },
  flexRaceInfoOverride: {
    fontFamily: 'body',
    fontSize: 'xs',
    ml: 0,
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  spanRaceInfo: {
    color: 'white',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontSize: 'xs',
    textTransform: 'capitalize',
  },
  buttonBetPropositionClosedProps: {
    variant: 'unstyled',
    bg: 'beta.400',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
    borderRadius: 'base',
    color: 'alpha.600',
    fontWeight: 'medium',
    fontSize: 'xs',
    px: '3',
    py: '2',
    paddingLeft: '12px',
    paddingRight: '12px',
    fontFamily: 'body',
  },
  buttonStakeProps: {
    variant: 'solid',
    fontFamily: 'body',
    fontSize: 'xs',
    fontWeight: 'black',
    h: '42px',
    maxW: '47px',
    borderRadius: 'base',
    lineHeight: 'normal',
  },
  iconBetCardSvgMysteryBet: {
    sx: {
      '&&': {
        svg: {
          color: 'white',
        },
      },
    },
  },
  flexWrapperMysteryBetFooterBetReview: {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    rounded: 'base',
    fontFamily: 'accent',
  },
  betCardSVG: {
    fill: 'alpha.700',
  },
};
export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};
export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'alpha.50',
  },
};
export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'sm',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'white',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'beta.500',
  fontWeight: 'bold',
  textTransform: 'capitalize',
});

export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    color: 'white',
  },
};
export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  ml: '8',
};
export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'alpha.700',
};
export const FlexPlacedContainer: CSSObject = {
  p: '2',
};
export const BetPlaced: CSSObject = {
  bg: 'gray.200',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 1) inset',
  color: 'gray.700',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};
export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};
export const TextPlacedLabel: CSSObject = {
  flex: '1',
};
export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);
export const EventRule: CSSObject = {
  color: 'alpha.700',
};
export const BetPropositionClosedError: CSSObject = {
  bg: 'rgb(0,112,215,0.7)',
  borderRadius: 'md',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};
export const BetOdds: CSSObject = {
  mb: '2px',
};
export const DefaultStakeFlexbox: CSSObject = {
  gap: '1',
};
export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
  pr: '2',
  pl: '10',
};
