import { CSSObject } from '@chakra-ui/react';

export const BoxSectionWrapper: CSSObject = {
  py: '2',
  px: '3',
  mb: ['2', null, '4'],
  my: '2',
  bg: 'alpha.500',
  border: 'none',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  borderRadius: 'md',
};
export const BoxWrapper: CSSObject = {
  pt: '2',
  px: '0',
};
export const TextHeading: CSSObject = {
  color: 'beta.300',
  fontSize: 'md',
  fontFamily: 'heading',
  fontWeight: 'normal',
  textTransform: 'capitalize',
};
export const ButtonViewMore: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'beta.300',
  boxShadow: '0px 0px 10px 0px rgba(173, 241, 6, 0.7)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  bg: 'transparent',
  mb: '1',
  w: 'full',
  _hover: {
    borderColor: 'white',
    boxShadow: 'none',
    color: 'beta.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};
