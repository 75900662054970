import * as betgalaxy from './MarketGroupsAccordion.styles.betgalaxy';
import * as betprofessor from './MarketGroupsAccordion.styles.betprofessor';
import * as betroyale from './MarketGroupsAccordion.styles.betroyale';
import * as fiestabet from './MarketGroupsAccordion.styles.fiestabet';
import * as forsport from './MarketGroupsAccordion.styles.forsport';
import * as juicybet from './MarketGroupsAccordion.styles.juicybet';
import * as goldenrush from './MarketGroupsAccordion.styles.goldenrush';
import * as puntcity from './MarketGroupsAccordion.styles.puntcity';
import * as puntgenie from './MarketGroupsAccordion.styles.puntgenie';
import * as questbet from './MarketGroupsAccordion.styles.questbet';
import * as sterlingparker from './MarketGroupsAccordion.styles.sterlingparker';
import * as vikingbet from './MarketGroupsAccordion.styles.vikingbet';
import * as volcanobet from './MarketGroupsAccordion.styles.volcanobet';
import * as wellbet from './MarketGroupsAccordion.styles.wellbet';
import * as oldgill from './MarketGroupsAccordion.styles.oldgill';
import * as bet777 from './MarketGroupsAccordion.styles.bet777';
import * as titanbet from './MarketGroupsAccordion.styles.titanbet';
import * as chromabet from './MarketGroupsAccordion.styles.chromabet';
import * as betblast from './MarketGroupsAccordion.styles.betblast';

import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  forsport,
  juicybet,
  goldenrush,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  oldgill,
  bet777,
  titanbet,
  chromabet,
  betblast,
};
