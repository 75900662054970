import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'beta.800';
      }
      if (state === 'ended') {
        return 'red.400';
      }
      return 'alpha.100';
    },
    color: 'blackAlpha.800',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
    borderRadius: '3px',
    px: 1,
    fontWeight: 'bold',
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
});
