import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '8',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 14,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'alpha.800',
          boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.30) inset',
          color: 'white',
          textShadow: '1px 1px 0px rgba(0, 0, 0, 0.50)',
        },
      },
      '&[data-closed="true"]': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'gamma.400',
        boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
        bg: 'alpha.500',
        color: 'alpha.50',
        textShadow: 'unset',
        px: '3',
      },
    },
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  mb: '2',
  mr: '2.5',
  alignItems: 'center',
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: ['2xs', 'xs'],
  color: 'white',
  textTransform: 'capitalize',
  fontFamily: 'Montserrat',
};

export const InfoContainer: CSSObject = {
  my: '0',
  pt: '1',
};

export const ButtonGroupWrapper: CSSObject = {
  py: '1',
  gap: '1',
  pt: '3px',
  '&&': {
    '.quaddie': {
      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.35)',
      bg: 'beta.300',
      color: 'alpha.50',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.30)',
      border: '2px solid',
      borderColor: 'gamma.400',
      '&[data-active="false"]': {
        bg: 'alpha.500',
        '&:hover, &.active': {
          bg: 'alpha.700',
          boxShadow:
            '0px 0px 0px 2px #19226D inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
          opacity: '1',
        },
      },
      '&.active': {
        border: '2px solid',
        borderColor: 'gamma.400',
        boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
        color: 'white',
        bg: 'alpha.800',
        '&:hover': {
          bg: 'alpha.700',
          boxShadow:
            '0px 0px 0px 2px #19226D inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
          opacity: '1',
        },
      },
      '&:hover': {
        bg: 'alpha.700',
        boxShadow:
          '0px 0px 0px 2px #19226D inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
        opacity: '1',
      },
    },
  },
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  border: '2px',
  borderColor: 'gamma.500',
  bg: 'alpha.500',
  borderRadius: 'lg',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    w: 'auto',
  },
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    w: '9',
    p: '0',
  },
};

export const RaceHeaderWrapper: CSSObject = {
  bgSize: '100% 100%',
  transition: '.3s ease-in-out',
};

export const TextTitle: CSSObject = {
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
  fontFamily: 'Montserrat',
};

export const ResultedStatusLabel: CSSObject = {
  color: 'gamma.900',
  bg: 'gamma.600',
  px: '1',
  py: '1',
  fontSize: '2xs',
  textTransform: 'capitalize',
  fontWeight: 'bold',
  borderRadius: 'sm',
};

export const RaceStatusText: CSSObject = {
  bg: 'gray.500',
  p: '1',
};

export const RaceAndTimerContainer: CSSObject = {
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  borderRadius: 'base',
};

export const TextRaceConditions: CSSObject = {
  color: 'white',
  fontFamily: 'Montserrat',

  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: 'gamma.500',
    pos: 'relative',
    mx: '1.5',
  },

  _last: {
    _after: {
      display: ['none', 'none', 'block'],
      color: 'red',
    },
  },

  span: {
    fontWeight: 'black',
    mr: ['2px', '1'],
    color: 'white',
  },

  b: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
  },
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge.state-prior': {
    color: 'white',
    fontSize: '11px',
  },
};

export const RaceRunnerHeadingContainer: CSSObject = {
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'beta.500',
      pb: '3px',
    },
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '5',
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'white',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};
