import { CSSObject } from '@emotion/react';

export const ButtonIncrease: CSSObject = {
  variant: 'secondary',
  fontWeight: 'bold',
};

export const buttonIncreaseProps: CSSObject = {
  variant: 'secondary',
  fontWeight: 'bold',
  h: '26px',
};
