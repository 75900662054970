import { BetCardInputSchema } from './BetCardInput.styles';

export const betCardInputStyles: BetCardInputSchema = {
  betCardInputGroup: {
    overflow: 'hidden',
    p: 0.5,
  },
  betCardInputLeftAddon: {
    fontWeight: 'black',
    fontSize: 'sm',
    color: 'blackAlpha.800',
    sx: {
      '&&': {
        bgGradient: 'linear(to-b, alpha.100, alpha.200)',
        border: 'none',
        borderLeftRadius: 'base',
        ':has(+ .chakra-input:focus)': {
          bgGradient: 'linear(to-b, beta.200, beta.400)',
        },
      },
    },
  },
  betCardInput: {
    color: 'white',
    fontWeight: 'bold',
    boxShadow: '0px 2px 3px 1px rgba(0, 0, 0, 0.1)',
    fontFamily: 'accent',
    px: '2',
    fontSize: 'sm',
    sx: {
      '&&': {
        borderRightRadius: 'base',
        border: '2px solid',
        borderColor: 'alpha.100',
        bg: 'alpha.800',
        _hover: {
          borderRightRadius: 'base',
          bg: 'alpha.500',
          boxShadow:
            '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 8px 0px rgba(28, 213, 255, 0.5)',
        },
        _focus: {
          '&&': {
            borderColor: 'beta.200',
          },
          borderRightRadius: 'base',
        },
      },
    },
  },
};
