import * as betprofessor from './ResultsTableHeader.styles.betprofessor';
import * as betroyale from './ResultsTableHeader.styles.betroyale';
import * as fiestabet from './ResultsTableHeader.styles.fiestabet';
import * as puntcity from './ResultsTableHeader.styles.puntcity';
import * as puntgenie from './ResultsTableHeader.styles.puntgenie';
import * as questbet from './ResultsTableHeader.styles.questbet';
import * as sterlingparker from './ResultsTableHeader.styles.sterlingparker';
import * as volcanobet from './ResultsTableHeader.styles.volcanobet';
import * as juicybet from './ResultsTableHeader.styles.juicybet';
import * as junglebet from './ResultsTableHeader.styles.junglebet';
import * as sugarcastle from './ResultsTableHeader.styles.sugarcastle';
import * as oldgill from './ResultsTableHeader.styles.oldgill';
import * as buffalobet from './ResultsTableHeader.styles.buffalobet';
import * as titanbet from './ResultsTableHeader.styles.titanbet';
import * as slambet from './ResultsTableHeader.styles.slambet';
import * as gigabet from './ResultsTableHeader.styles.gigabet';
import * as templebet from './ResultsTableHeader.styles.templebet';
import * as goldenbet888 from './ResultsTableHeader.styles.goldenbet888';
import * as hyperbet from './ResultsTableHeader.styles.hyperbet';
import * as bet777 from './ResultsTableHeader.styles.bet777';
import * as chromabet from './ResultsTableHeader.styles.chromabet';
import * as bet575 from './ResultsTableHeader.styles.bet575';
import * as betblast from './ResultsTableHeader.styles.betblast';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  volcanobet,
  juicybet,
  junglebet,
  sugarcastle,
  oldgill,
  buffalobet,
  titanbet,
  slambet,
  gigabet,
  templebet,
  goldenbet888,
  hyperbet,
  bet777,
  chromabet,
  bet575,
  betblast,
};
