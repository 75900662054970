import React from 'react';
import { Flex, Img, Hide, Show } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  LocationNotAllowedContainer,
  CenterWrapper,
} from '../styles/LocationNotAllowed.styles';
import logo from '@/assets/betblast/images/logo/logo.png';
import Heading from '../components/Heading';
import Body from '../components/Body';

const { REACT_APP_THEME_NAME } = window.BETCLOUD_ENV;

const template: TTemplate = {
  wrapper: <LocationNotAllowedContainer />,
  main: {
    wrapper: <CenterWrapper />,
    logo: (
      <Flex>
        <Hide above="lg">
          <Img
            src={logo}
            alt={REACT_APP_THEME_NAME}
            alignSelf="center"
            w="225px"
            mt="2"
            mb="0"
          />
        </Hide>
        <Show above="lg">
          <Img
            src={logo}
            alt={REACT_APP_THEME_NAME}
            alignSelf="center"
            w="285px"
            mt="2"
            mb="0"
          />
        </Show>
      </Flex>
    ),
    heading: (
      <Flex direction="column" alignItems="center">
        <Heading />
      </Flex>
    ),
    desc: <Body />,
    body: (
      <Global styles={{ body: { background: 'var(--bc-colors-alpha-900)' } }} />
    ),
  },
};

export default template;
