import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';

export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};
export const BetCardContainer = (): CSSObject => ({
  fontFamily: 'accent',
  bg: 'rgba(34, 92, 161, 0.5)',
  border: '2px solid',
  borderColor: 'beta.500',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(0, 0, 0, 0.5) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset',
  backdropFilter: 'blur(5px)',
  color: 'white',
  mb: '0',
  borderRadius: 'base',
  'div[class*="FormsHStack"]:has(div[class*="info-InfoExoticsContainer"]) > div':
    {
      pt: '2',
      borderTop: '1px dashed',
      borderColor: 'rgba(0, 0, 0, 0.25)',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset',
      w: 'full',
    },
  'div[class*="FormsHStack"]:has(div[class*="info-InfoExoticsContainer"]):last-child':
    {
      mb: '2',
    },
});
export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'beta.400',
  },
};
export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontFamily: 'body',
  fontWeight: 'bold',
  fontSize: 'sm',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};
export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'white',
  fontFamily: 'body',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  svg: {
    mb: '0.5',
    color: 'beta.300',
  },
};
export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'beta.500',
  fontFamily: 'body',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
});
export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  lineHeight: 'normal',
  ml: '7',
  '&&': {
    color: 'white',
  },
};
export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  ml: '7',
};
export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'body',
};
export const FlexPlacedContainer: CSSObject = {
  p: '2',
};
export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, gray.200, gray.200)',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 1) inset',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};
export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'body',
  flex: 1,
};
export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const BetPropositionClosedButton: CSSObject = {
  bg: 'alpha.700',
  boxShadow: 'md',
  color: 'white',
  borderRadius: 'base',
  fontSize: 'xs',
  px: '3.5',
  h: '8',
  display: 'flex',
  gap: '1',
};
export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
  fontFamily: 'body',
};
export const TextPlacedLabel: CSSObject = {
  flex: '1',
  fontFamily: 'body',
};
export const BetCardSVG = (header: boolean) => ({
  color: 'alpha.100',
  boxSize: '5',
  display: header ? 'block' : 'none',
  mr: '0',
  filter: 'drop-shadow(0px 0px 9px rgba(38, 215, 255, 0.5))',
});
export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
  py: '0',
  flexDir: 'column-reverse',
  gap: '1.5',
});
export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);
export const EventRule: CSSObject = {
  color: 'white',
  fontFamily: 'body',
};
export const BetPropositionClosedError: CSSObject = {
  bg: 'blackAlpha.700',
  borderRadius: 'md',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};
export const BetOdds: CSSObject = {
  mb: '2px',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '1',
};
export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
  pr: '2',
  pl: '10',
};
export const BetCardVStack: CSSObject = {
  pb: '2',
  dl: {
    mx: '0.5',
    borderTop: '1px dashed rgba(0, 0, 0, 0.25)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07) inset',
  },
};
// THEMING MYSTERY BET
export const mysteryBetFooterPrimaryContainerWrapperProps: CSSObject = {
  w: '95%',
  bg: 'alpha.800',
  borderRadius: '5px',
  boxShadow: 'md',
  color: 'beta.500',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2',
  p: '2',
  mt: '1',
  m: '3',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const mysteryBetFooterSecondaryContainerWrapperProps: CSSObject = {
  w: '95%',
  bg: 'alpha.800',
  borderRadius: '5px',
  boxShadow: 'md',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  gap: '2',
  p: '2',
  mt: '1',
  m: '3',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const ExoticsNumberContainer: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  minW: '30px',
  fontFamily: 'body',
};
