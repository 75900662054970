import { CSSObject } from '@chakra-ui/react';
import { EGreenIDStatus } from '../../VerifyIdentity/Services/Config.VerifyIdentity';

export type TStatusProps = {
  status?: EGreenIDStatus;
};
export const ButtonVerified = ({ status }: TStatusProps): CSSObject => ({
  border: 'none',
  borderRadius: '3px',
  fontSize: '2xs',
  fontWeight: 'bold',
  fontFamily: 'body',
  h: '5',
  pl: '0.5',
  pr: '1.5',
  mb: '0.5',
  textTransform: 'uppercase',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  color: () => {
    switch (status) {
      case EGreenIDStatus.PENDING:
      case EGreenIDStatus.VERIFIED:
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
      default:
        return 'blackAlpha.700';
    }
  },
  bg: () => {
    switch (status) {
      case EGreenIDStatus.VERIFIED:
      case EGreenIDStatus.VERIFIED_ADMIN:
      case EGreenIDStatus.VERIFIED_WITH_CHANGES:
        return 'beta.200';
      case EGreenIDStatus.PENDING:
        return 'beta.800';
      default:
        return 'red.500';
    }
  },
  _hover: {
    bg: 'alpha.400',
    color: 'alpha.900',
    border: 'none',
  },
  _disabled: {
    border: 'none',
    bgGradient: 'none',
    cursor: 'default',
    opacity: '1',
    color: 'blackAlpha.700',
    bg: 'beta.200',
  },
  '.chakra-button__icon': {
    ml: '1',
    mr: '0.5',
  },
  '.chakra-icon': {
    boxSize: '3',
    bg: 'blackAlpha.700',
    borderRadius: 'full',
    color: () => {
      switch (status) {
        case EGreenIDStatus.VERIFIED:
        case EGreenIDStatus.VERIFIED_ADMIN:
        case EGreenIDStatus.VERIFIED_WITH_CHANGES:
          return 'beta.200';
        default:
          return 'white';
      }
    },
    m: '1px',
    p: '1px',
    boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.50)',
  },
  '.btn-span': {
    textShadow: 'none',
    fontWeight: 'bold',
    lineHeight: 'normal',
    pb: 'px',
  },
});
export const BoxOverviewTitle: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'bold',
  fontFamily: 'body',
  pb: '2',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};
