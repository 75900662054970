import { CSSObject } from '@chakra-ui/react';

export const BadgeCountdown = (
  state: 'inprogress' | 'prior' | 'ended'
): CSSObject => ({
  '&&&&': {
    bg: () => {
      if (state === 'inprogress') {
        return 'gamma.300';
      }
      if (state === 'ended') {
        return 'red.500';
      }
      // 'prior'
      return 'beta.300';
    },
    color: () => {
      if (state === 'inprogress') {
        return 'gamma.700';
      }
      if (state === 'ended') {
        return 'white';
      }
      // 'prior'
      return 'beta.800';
    },
    borderRadius: 'sm',
    px: 1,
    fontFamily: 'body',
    fontWeight: 'bold',
    textTransform: state === 'prior' ? 'uppercase' : 'capitalize',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  },
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
});
