import { CSSObject } from '@emotion/react';

export const buttonIncreaseProps: CSSObject = {
  variant: 'odds',
  fontWeight: 'black',
  fontFamily: 'accent',
  borderRadius: 'base',
};

export const IncreaseAmountWrapper: CSSObject = {
  gap: '1',
};
