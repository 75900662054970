import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
  },
  FlexCard: {
    my: '1',
    border: 'none',
    borderRadius: 'md',
    bgGradient: `linear(to-b, alpha.300, alpha.500)`,
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  LinkCardHeader: {
    bg: 'transparent',
  },
  TextCardHeading: {
    color: 'white',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    lineHeight: 'normal',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    pt: '0.5',
  },
  TextRaceNumber: {
    bg: 'delta.300',
    borderRadius: '4px',
    color: 'delta.900',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
    lineHeight: '11px',
  },
  GridCardContent: {
    color: 'white',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.500',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'medium',
    lineHeight: 'normal',
    fontFamily: 'body',
    fontSize: '8px',
    px: '2',
    h: '20px',
  },
  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px solid',
    borderTop: '1px solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.3)',
    borderTopColor: 'rgba(255, 255, 255, 0.05)',
    px: '2.5',
    sx: {
      ':nth-last-child(1), :nth-last-child(2)': {
        borderBottom: 'none',
      },
      ':nth-child(odd)': {
        pl: '0',
        ml: '2',
      },
      ':nth-child(even)': {
        pr: '0',
        mr: '2',
      },
    },
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'semibold',
  },
  TextRunnerNumber: {
    sx: {
      _last: {
        color: 'white',
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },
  TextScratched: {
    color: 'white',
  },
  LinkRaceDetails: {
    lineHeight: '25px',
    h: '42px',
    borderRadius: 'base',
    border: '2px',
    borderColor: 'beta.300',
    boxShadow: '0px 0px 10px 0px rgba(173, 241, 6, 0.7)',
    color: 'white',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    _hover: {
      borderColor: 'white',
      boxShadow: 'none',
      color: 'beta.300',
    },
    mx: '2.5',
    mt: '1',
    mb: '2.5',
  },
  buttonRaceCardOddsProps: {
    variant: 'odds',
    fontFamily: 'heading',
    fontSize: 'sm',
    borderRadius: 'base',
    h: '8',
    w: '46px',
    pt: '1',
  },
};
export const TextRunner: CSSObject = {
  color: 'white',
};
export const IconCardHeader: CSSObject = {
  color: 'rgba(173, 241, 6, 1)',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
