import { CSSObject } from '@chakra-ui/react';

export const TextS: CSSObject = {
  fontSize: 'xs',
  color: 'white',
};

export const FormWrapper: CSSObject = {
  color: 'white',
  bg: 'transparent',
  borderRadius: 'md',
  p: '0',
  '.chakra-checkbox__label': {
    color: 'white',
  },
  div: {
    maxW: 'full',
  },
};
