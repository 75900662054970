import { OnRaceBetsSchema } from './OnRaceBets.styles';

export const onRaceBetStyles: OnRaceBetsSchema = {
  wrapper: {
    mb: ['4', null, '2'],
    bg: 'rgba(34, 92, 161, 0.5)',
    boxShadow:
      '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
    border: 'none',
    borderRadius: 'md',
    flexDirection: 'column',
    py: '1',
  },
  headerWrapper: {
    px: '3',
    flexDirection: 'row',
    alignItems: 'center',
    sx: {
      ':has(+ div)': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
      },
    },
  },
  headerText: {
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white',
  },
  headerCountText: {
    ml: '1',
    fontSize: 'xs',
    fontWeight: 'extrabold',
    color: 'blackAlpha.800',
    bg: 'beta.500',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.2)',
    p: '0.5',
    borderRadius: '3px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  headerExpandButton: {
    variant: 'unstyled',
    ml: 'auto',
    mr: '1',
    color: 'beta.400',
    boxSize: '7',
  },
  toggleIcon: {
    boxSize: '4',
  },
  betsContainer: {
    flexDirection: 'column',
  },
  betCategoryWrapper: {
    alignItems: 'center',
    mt: '2',
  },
  betCategory: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'alpha.700',
  },
  betCategoryCount: {
    fontSize: 'xs',
    fontWeight: 'semibold',
    color: 'alpha.700',
    ml: '1',
  },
  divider: {
    borderWidth: 'px',
    opacity: '1',
    borderColor: 'blackAlpha.400',
    mt: '1.5',
  },
  betRow: {
    py: '2',
    px: '2',
    gap: '2',
    bg: 'blackAlpha.300',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    borderRadius: 'base',
    mb: '1',
    _last: {
      mb: '0',
    },
  },
  betRowDivider: {
    bg: 'blackAlpha.300',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    borderRadius: 'base',
    mb: '1',
    p: '2',
    _last: {
      mb: '0',
    },
  },
  groupDivider: {
    borderBottomWidth: '1px',
    borderBottomColor: 'blackAlpha.400',
  },
  categoryIcon: {
    color: 'beta.400',
    boxSize: '6',
  },
  groupContainer: {
    flexDir: 'column',
    py: 2,
    px: '3',
    border: 'none',
    boxShadow: 'none',
  },
  groupTitleText: {
    fontFamily: 'body',
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    mb: '1',
  },
  runnerText: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    mr: '0.5',
    mb: '0.5',
  },
  runnerBarrierText: {
    color: 'white',
    fontSize: '2xs',
  },
  stakeText: {
    color: 'beta.400',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  potentialWinningsText: {
    color: 'beta.400',
    fontSize: 'xs',
    fontWeight: 'black',
  },
  subtitleText: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'normal',
    mb: '0.5',
  },
  exoticsPositionText: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'extrabold',
    w: 9,
  },
  viewMoreButton: {
    alignItems: 'center',
    justifyContent: 'center',
    mb: -2,
    borderTopRadius: 0,
    height: 8,
  },
  viewMoreButtonText: {
    fontWeight: 'black',
    fontSize: 'xs',
    textTransform: 'uppercase',
  },
  srmBlendedLegContainer: {
    flexDir: 'row',
    flex: '1',
  },
  srmBlendedRunnerText: {
    width: 32,
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'xs',
    textTransform: 'capitalize',
    mb: '0.5',
  },
  srmMarketText: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: 'xs',
  },
  srmBlendedWrapper: {
    flexDir: 'column',
    flex: 1,
    sx: {
      '> div:has(+ p.chakra-text)': {
        display: 'flex',
        flexDirection: ['column', 'row'],
      },
    },
  },
  flexiText: {
    fontWeight: 'normal',
    color: 'white',
    fontSize: 'xs',
  },
};
