import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: 'alpha.700',
  borderRadius: 'md',
  p: '3',
  color: 'gamma.200',
  m: 'auto',
};

export const HeaderText: CSSObject = {
  color: 'gamma.600',
  fontSize: 'lg',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  p: '0',
};

export const HeaderContainer: CSSObject = {
  p: '0',
};

export const DepositLimitModalSubheading: CSSObject = {
  '&&': {
    color: 'gamma.200',
  },
};

export const CloseButton: CSSObject = {
  display: 'none',
};
