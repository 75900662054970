import { DepositSchema } from '@/views/account/Deposit/styles/Deposit.styles';

export const depositStyles: DepositSchema = {
  inputCardHolderNameProps: {
    sx: {
      '&&&': {
        h: 10,
        fontSize: 'xs',
        px: 3,
        fontWeight: 'semibold',
        border: 'none',
        boxShadow:
          '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
        bg: '#0f2440',
        color: 'white',
        '&&&': {
          ':hover': {
            bg: '#0f2440',
            color: 'white',
          },
          ':active': {
            bg: '#0f2440',
            color: 'white',
          },
        },
      },
    },
  },
};
