import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'lg',
    my: 2,
    pl: 4,
  },
  heading: {
    color: 'white',
    fontWeight: 'semibold',
    mt: '-2',
  },
  boxWrapper: {
    borderTop: '1px dashed rgba(255, 255, 255, 0.1)',
    py: 3,
    px: 0,
    mx: 3,
  },
  filteredPromotionsWrapper: {
    p: 3,
  },
  promotionWrapper: {
    my: '3',
    bg: 'alpha.300',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
    borderRadius: 'base',
    sx: {
      img: {
        borderTopRadius: 'md',
        pt: '1px',
        px: '1px',
      },
      '.chakra-button': {
        borderRadius: 'md',
        border: 'none',
        bgGradient: 'linear(to-b, beta.200, beta.400)',
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
        color: 'blackAlpha.800',
        fontFamily: 'accent',
        fontSize: 'xs',
        fontWeight: 'black',
        textTransform: 'uppercase',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
        _hover: {
          bgGradient: 'linear(to-b, beta.100, beta.100)',
          boxShadow:
            '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.35) inset',
        },
        ':active, &[data-active=true]': {
          bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
          boxShadow:
            '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
          color: 'alpha.100',
          textShadow: 'none',
        },
        _disabled: {
          opacity: 0.4,
          pointerEvents: 'none',
        },
      },
    },
  },
  flexWrapper: {
    gap: '4',
    p: '3',
    justifyContent: 'space-between',
    borderTop: '3px solid',
    borderColor: 'beta.500',
    mx: '1px',
  },
  termsAndConditions: {
    color: 'white',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
  },
};
