import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/hyperbet/images/onboarding-aside.png';
import bgMobile from '@/assets/hyperbet/images/onboarding-aside-mb.png';

export const WrapperAside: CSSObject = {
    bg: [
      `url(${bgMobile}), #0D151F`,
      null,
      `url(${bgDesktop}), var(--bc-colors-alpha-800)`,
    ],
    bgSize: ['cover', null, 'contain'],
    bgRepeat: ['no-repeat', null, 'no-repeat'],
    display: 'flex',
    flexDir: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    px: [null, null, '10px'],
    borderBottom: ['3px', null, 'unset'],
    borderBottomColor: ['beta.500', null, 'unset'],
    h: ['50vw', '55vw', 'full'],
    borderLeftRadius: [null, null, '10px'],
    borderRightRadius: ['unset'],
    pt: ['4', null, '6'],
    flex: 1,
    a: {
      h: 40,
      img: {
        pos: 'absolute',
        maxW: ['300px', '500px', '230px'],
        top: ['100px', '120px', '100px'],
        left: ['200px', '150px', '150px'],
        transform: 'translate(-50%, -50%)',
      },
    },
  };