import { Image } from '@chakra-ui/react';
import React from 'react';
import footerLogo from '@/assets/betblast/images/logo/logoFooter.png';

export default function index() {
  return (
    <Image
      src={footerLogo}
      minW="180px"
      maxW="201px"
      minH="73px"
      marginLeft="-2.5"
    />
  );
}
