import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  px: 3,
  py: 2,
  mx: 2,
  mb: 2,
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.1))',
  border: '1px solid rgba(255, 255, 255, 0.11)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'md',
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'accent',
};
export const IconSGM: CSSObject = {
  boxSize: '30px',
  display: 'block',
  mr: '2',
  color: 'beta.400',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  ...(isVS && { color: 'beta.300', mx: '1' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  p: '1.5',
  boxSize: '8',
  color: 'blackAlpha.700',
  bg: 'beta.400',
  borderRadius: 'md',
  boxShadow: '0px 3px 4px 0px #00000033, 0px 0px 2px 1px #FFFFFF40 inset',
};

export const IconChevron: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: '2xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.400',
    boxSize: '5',
  },
};
