import { CSSObject } from '@chakra-ui/react';

export const IconContainer: CSSObject = {
  m: 0,
  bgGradient: 'linear(to-b, alpha.100, alpha.200)',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
  boxSize: '10',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const Icon: CSSObject = {
  ml: 'unset',
  color: 'blackAlpha.800',
  boxSize: '8',
  display: 'flex',
  justifyContent: 'center',
};

export const MatchCardContainer: CSSObject = {
  mx: -1,
  display: 'grid',
  px: '2',
  alignItems: 'center',
  gridTemplateColumns: '48px 1fr 20px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
    "icon matchInfo arrowIcon"
  `,
  bg: 'rgba(34, 92, 161, 0.5)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(149, 239, 255, 0.2) inset',
  borderRadius: 'md',
  h: '56px',
  _hover: {
    bg: 'alpha.500',
  },
};

export const MatchName: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'white',
  mt: '1.5',
  fontFamily: 'body',
  textTransform: 'uppercase',
};

export const MatchDate: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'semibold',
  color: 'alpha.100',
};

export const TeamsContainer: CSSObject = {
  flexDir: 'row',
  flex: '1',
  display: 'flex',
  mt: '0',
};

export const Seperator: CSSObject = {
  color: 'whiteAlpha.400',
  mx: '0',
  px: '0',
  boxSize: '4',
};

export const NameContainer: CSSObject = {
  gridTemplateColumns: '70px 1fr 40px',
  gridTemplateRows: '1fr',
  gridTemplateAreas: `
  "icon matchInfo arrowIcon",`,
  color: 'white',
};

export const CompetitionName: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'semibold',
  color: 'alpha.100',
};

export const ArrowIcon: CSSObject = {
  boxSize: '5',
  color: 'white',
  border: '2px',
  borderColor: 'white',
  borderRadius: 'full',
};

export const MatchDetails: CSSObject = {
  py: '0',
  alignItems: 'center',
};

export const BodyContainer: CSSObject = {
  py: '1',
};
