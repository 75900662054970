import { CSSObject } from '@chakra-ui/react';

export const LinkContainer = () => ({
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.3)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  bg: 'transparent',
  _hover: {
    bg: 'blackAlpha.100',
  },
});
export const BoxNextToJumpContainer: CSSObject = {
  bg: 'rgba(34, 92, 161, 0.5)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
  mx: '0',
  borderRadius: 'md',
  mb: '2',
  '> div:last-child': {
    'a[class*="nextToJump-LinkContainer"]': {
      borderBottom: 'none',
      boxShadow: 'none',
    },
  },
};
export const Icon: CSSObject = {
  boxSize: '8',
  color: 'blackAlpha.800',
  bgGradient: 'linear(to-b, alpha.100, alpha.200)',
  border: 'none',
  borderRadius: 'md',
  p: '2',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
  mr: '2',
};
export const TextRace: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'body',
  fontSize: 'xs',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'bold',
};
export const TextNumber: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'beta.300',
  color: 'blackAlpha.800',
  py: '0',
  pt: 'px',
  borderRadius: '3px',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  '&&': {
    h: '16px',
    maxH: '16px',
    minH: '16px',
    lineHeight: '16px',
  },
};
export const BoxHeading: CSSObject = {
  display: 'none',
};
export const FlexWrapper: CSSObject = {
  '.chakra-badge': {
    borderRadius: 'sm',
    h: '4',
    minH: '4',
    pt: 'px',
    mr: '0.5',
  },
};
