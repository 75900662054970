import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  alignItems: 'center',
  h: '16',
  bg: 'blackAlpha.400',
};

export const IconCircleOutline: CSSObject = {
  boxSize: '8',
  color: 'beta.300',
};
