import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/bet575/images/close-filter.svg';

const primary = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.24) inset',
  color: 'blackAlpha.700',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, beta.100, beta.100)',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, alpha.100, alpha.300)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, whiteAlpha.400, whiteAlpha.400)',
  },
  ':active, &[data-active=true]': {
    bg: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.100',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'beta.400',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: 'none',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  _hover: {
    border: '2px',
    borderColor: 'white',
    color: 'beta.300',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const quickAccess = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.24) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  color: 'blackAlpha.700',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  _hover: {
    bgGradient: 'linear(to-b, beta.100, beta.100)',
  },
  ':active, &[aria-expanded=true]': {
    bg: 'white',
    boxShadow: 'none',
    textShadow: 'none',
    borderBottomRadius: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    color: 'white',
    border: '2px solid blackAlpha.300',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const odds = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  boxShadow:
    '0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.24) inset',
  color: 'blackAlpha.700',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, beta.100, beta.100)',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 1,
    pointerEvents: 'none',
    bg: 'blackAlpha.300',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
    color: 'whiteAlpha.300',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const tab = {
  fontSize: 'xs',
  textShadow: 'none',
  fontWeight: 'extrabold',
  borderRadius: 'none',
  fontFamily: 'Roboto',
  h: '8',
  textTransform: 'uppercase',
  color: 'white',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  px: '3',
  pt: '1',
  flex: '0 0 auto',
  _hover: {
    color: 'beta.300',
  },
  ':active, &[data-active="true"]': {
    color: 'beta.300',
    fontWeight: 'extrabold',
    borderBottom: '3px solid',
    borderBottomColor: 'beta.300',
  },
};

const filter = {
  borderRadius: 'base',
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: '7',
  minW: 'auto',
  p: '2',
  textTransform: 'uppercase',
  border: '2px',
  borderColor: 'white',
  bg: 'transparent',
  _hover: {
    color: 'white',
    bg: 'alpha.100',
  },
  _active: {
    bg: 'alpha.100',
    color: 'white',
    border: 'none',
    '&:after': {
      border: 'none',
      content: '""',
      position: 'absolute',
      display: 'block',
      boxSize: '14px',
      top: '7px',
      right: '2',
      borderRadius: 'full',
      pr: '2',
      backgroundImage: `url(${CloseButton})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
};

const segment = {
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: '5',
  px: '3',
  py: '3px',
  textTransform: 'uppercase',
  bg: 'transparent',
  _hover: {
    color: 'alpha.400',
  },
  _active: {
    bg: 'alpha.400',
    color: 'alpha.700',
  },
};

const quaddiesTab = {
  boxShadow: 'none',
  fontSize: 'xs',
  textShadow: 'none',
  fontWeight: 'extrabold',
  borderRadius: 'none',
  fontFamily: 'body',
  h: '8',
  textTransform: 'uppercase',
  color: 'white',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  px: '3',
  pt: '1',
  _hover: {
    color: 'alpha.200',
  },
  ':active, &[data-active="true"]': {
    color: 'alpha.600',
    fontWeight: 'extrabold',
    borderBottom: '3px solid',
    borderBottomColor: 'alpha.600',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    quickAccess: () => ({
      ...quickAccess,
    }),
    odds: () => ({
      ...odds,
    }),
    tab: () => ({
      ...tab,
    }),
    filter: () => ({
      ...filter,
    }),
    segment: () => ({
      ...segment,
    }),
    quaddiesTab: () => ({
      ...quaddiesTab,
    }),
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
