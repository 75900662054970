import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'primary',
    px: '4',
    py: '2',
    fontFamily: 'accent',
    fontSize: 'xs',
  },
};
export const FlexBannerContainer: CSSObject = {
  borderRadius: 'md',
  backgroundRepeat: 'repeat',
  bgColor: 'alpha.500',
  color: 'white',
  gap: '0',
  p: ['3.5', null, '3'],
  minH: '107px',
  boxShadow: '0px 1px 0px 0px #FFFFFF1A,  0px 1px 5px 0px #00000040 inset',
};

export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
};

export const FlexAccountName: CSSObject = {
  fontSize: 'lg',
  fontWeight: 'extrabold',
  color: 'beta.300',
};

export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  py: [null, null, '2'],
};

export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
};

export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'extrabold',
  textAlign: ['start', null, 'end'],
  color: 'white',
};

export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'lg',
  fontWeight: 'extrabold',
  color: 'gamma.500',
  textTransform: 'uppercase',
};

export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'beta.300',
});

export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'beta.300',
});

export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'beta.300',
});

export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '1',
  borderTop: '1px solid',
  borderTopColor: 'whiteAlpha.300',
  pt: '2',
  fontFamily: 'body',
};

export const TextButton: CSSObject = {
  h: '4',
  pt: 'px',
  color: 'blackAlpha.700',
  textTransform: 'uppercase',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
};

export const FlexBalanceBetInfo: CSSObject = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'beta.300',
  color: 'white',
  fontSize: 'xs',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  fontWeight: 'bold',
  alignItems: 'center',
  h: '10',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
  gap: '1',
};
