import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderColor: 'blackAlpha.400',
    boxShadow: '0 1px rgba(255, 255, 255, .07)',
    pb: '2',
    px: '2.5',
  },
  btnGroupWrapper: {
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    borderRadius: '5px',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.2)',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
    minW: '90px',
    gap: 'initial',
  },
  btnGroupText: {
    color: 'alpha.600',
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontSize: 'sm',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'whiteAlpha.500',
    h: '100%',
    border: 'none',
  },
  btnGroupIcon: {
    fill: 'white',
    color: 'white',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.60)',
    sx: {
      filter: 'drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.6))',
      h: '5',
      w: '7',
    },
  },
  name: {
    color: 'white',
  },
};
export default styles;
