import { CSSObject } from '@chakra-ui/react';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';
import bgFeaturedRace from '@/assets/bet575/images/background/bg-featured-race.png';
import featBgMb from '@/assets/bet575/images/background/bg-featured-mb.png';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    minW: '48px',
    fontSize: '13px',
    variant: 'odds',
    h: '34px',
    pt: '0.5',
    borderRadius: 'md',
  },
  svgMoreRacesArrowProps: {
    color: 'beta.300',
    boxSize: '5',
  },
};

export const BoxCard: CSSObject = {
  p: '2',
};

export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${bgFeaturedRace})`],
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    bgPosition: 'center top',
    pos: 'absolute',
    h: ['200px', 'auto'],
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', 'md'],
    m: '0.5',
  },
  fontFamily: 'accent',
  boxShadow: [
    'unset',
    '0px 2px 5px 0px #00000040, 0px 0px 0px 4px #FFFFFF26 inset, 0px 0px 0px 3px #1A202A inset',
  ],
  border: '2px solid',
  borderColor: 'beta.400',
  flex: '2',
  pos: 'relative',
  contain: 'paint',
  flexDir: 'column',
  borderRadius: 'lg',
  minH: '299px',
  py: '4',
  px: '3',
  mb: [3, 1.5],
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: ['center', null, null, 'space-between'],
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  mb: ['1', null, '80px'],
  mt: ['60px', null, '0'],
  '> p': {
    fontSize: ['2xl', null, null, '30px'],
    color: 'beta.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'body',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  alignItems: 'center',
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'beta.300',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: '15px',
  color: 'white',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'end',
};

export const TextRaceNumber: CSSObject = {
  px: '1',
  h: '16px',
  maxH: '16px',
  minH: '16px',
  bg: 'whiteAlpha.400',
  borderRadius: 'sm',
  color: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  lineHeight: '4',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: '100%',
  minH: '42px',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  borderRadius: 'base',
  border: '1px solid rgba(255, 255, 255, 0.11)',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
};

export const LinkFeatureCard: CSSObject = {
  display: ['none', null, null, 'block'],
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
