import { CSSObject, IconProps } from '@chakra-ui/react';
import { BottomRowSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/styles/BottomRow.styles';

export const bottomRowStyles: BottomRowSchema = {
  flexWrapper: {
    w: 'full',
  },
  buttonCashOutDesc: {
    w: 'full',
    height: '29px',
    fontWeight: 'normal',
    fontSize: 'sm',
    textTransform: 'capitalize',
    color: 'alpha.900',
    sx: {
      '.cashoutValue': {
        color: 'alpha.900',
      },
      '&:active': {
        '.cashoutValue': {
          color: 'beta.300',
        },
      },
    },
  },
  buttonCashOutAmount: {
    variant: 'solid',
    height: '29px',
    fontFamily: 'accent',
    fontWeight: 'bold',
    _hover: {
      bgGradient: 'linear(180deg, beta.400 0%, beta.500 100%)',
      textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
      color: 'beta.300',
    },
  },
  flexOddsBoostBanner: {
    bg: 'blackAlpha.700',
    color: 'beta.300',
    sx: {
      svg: {
        fill: 'beta.300',
      },
    },
  },
  textOddsBoost: { color: 'beta.300', fontFamily: 'accent', pt: '1' },
  vstackBetsWrapper: {
    w: 'full',
    ml: 'inherit',
  },
  buttonGroupWrapper: {
    w: 'full',
  },
  iconCashOut: {
    fill: 'currentColor',
    pb: '0.5',
  },
  cashoutText: {
    color: 'currentColor',
    fontWeight: 'bold',
    pt: '0.5',
  },
  cashoutValue: {
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  venueRaceWrapper: {
    ml: '26px',
  },
};
export const LinkVenueName: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const RaceTime: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
  alignSelf: 'flex-end',
};

export const BonusBetCheckMark: CSSObject = {
  '&&': {
    display: 'none',
  },
};

export const BonusBetText: CSSObject = {
  color: 'gamma.300',
  fontSize: '11px',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontFamily: 'accent',
  bg: 'blackAlpha.700',
  borderRadius: 'md',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
  px: '2',
  pt: '2.5',
  pb: '2',
  lineHeight: 'normal',
};
export const BetReturn: CSSObject = {
  bg: 'blackAlpha.500',
  color: 'white',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.09), 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset',
  backdropFilter: 'blur(4px)',
  borderRadius: 'base',
  fontWeight: 'medium',
  fontSize: '2xs',
};
export const BoxBetReturnValue: CSSObject = {
  py: '0.5',
  p: '1',
  fontWeight: 'extrabold',
  color: 'gamma.300',
};
export const BoxBetReturnText: CSSObject = {
  my: '1',
  px: '1',
  borderRight: '1px solid',
  borderRightColor: 'beta.800',
};
export const iconLegProps: IconProps = {
  boxSize: '18px',
  border: '2px solid',
  borderColor: 'white',
  color: 'orange.500',
};
