import {
  ComponentWithAs,
  HStack,
  Link,
  LinkProps,
  StackProps,
} from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { navStyles } from './styles/Nav.styles';

export default function Nav(props: StackProps) {
  const location = useLocation();
  const isOnSportsAll = location.pathname === '/sports/All';

  return <HStack {...navStyles.wrapper} {...props} data-isOnSportsAll={isOnSportsAll} />;
}

export const NavLink: ComponentWithAs<'a', LinkProps> = (props) => (
  <Link {...navStyles.link} {...props} />
);
