import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'solid',
  height: '40px',
  textTransform: 'uppercase',
};

export const FlexWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  borderBottomRadius: 'base',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
  },

  '&&': {
    input: {
      fontFamily: 'accent',
      px: '2',
    },
    '.chakra-form__label': {
      color: 'white',
      mt: '0',
    },
    '.chakra-input__group': {
      border: 'none',
      boxShadow: 'none',
      p: '0',
    },
    '.chakra-input__left-addon': {
      bg: ' beta.400',
      h: 'auto',
      border: 'none',
      fontWeight: 'bold',
      w: '45px',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  bg: 'alpha.600',
  borderY: 'none',
  borderBottomRadius: 'base',
  zIndex: '10',
  mx: ['0', null, '-4'],
  mb: ['0', null, '-4'],
};

export const InfoHeader: CSSObject = {
  borderTop: '4px solid',
  borderColor: 'beta.400',
  bg: 'alpha.600',
  h: '9',
  px: '2.5',
  py: '2.5',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '3',
  display: 'flex',
  fontSize: 'xs',
  fontWeight: 'bold',
  bg: 'alpha.400',
  borderBottom: '1px dashed',
  borderColor: 'rgba(0, 0, 0, 0.25)',
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
    '&.last-element': {
      borderBottom: 'none',
    },
  },
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  '+ div': {
    pt: '0',
  },
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
};
