import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};

export const FlexWrapper: CSSObject = {
  bgGradient: 'linear(to-b, alpha.600, alpha.800)',
  bgSize: 'cover',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.12)',
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};

export const CountdownTimer = {
  '&': {
    '.countdown-flex': {
      background: 'transparent',
    },
  },
};

export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, #FFFFFF1F, #FFFFFF0D)',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  color: 'white',
  boxShadow: '0px 2px 5px 0px #00000040',
  textOverflow: 'ellipsis',
  _hover: {
    bg: 'alpha.400',
  },
  '.chakra-badge.state-inprogress': {
    mr: '2',
    mt: '1.5',
    borderRadius: 'md',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    bg: 'alpha.100',
    color: 'alpha.800',
    w: '29px',
    h: '15px',
  },

  '.chakra-badge.state-prior': {
    mr: '2',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '.chakra-badge.state-ended': {
    mr: '2',
    mt: '1.5',
    borderRadius: 'md',
    fontSize: '9px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    w: '35px',
    h: '15px',
  },
};

export const RaceNumber: CSSObject = {
  color: 'white',
};

export const FlexDetails: CSSObject = {
  color: 'white',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'beta.400',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  boxSize: '7',
  minW: '7',
  color: 'beta.400',
  p: '0',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, #FFFFFF1F, #FFFFFF0D)',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  boxShadow: '0px 2px 5px 0px #00000040',
  _hover: {
    bg: 'alpha.400',
  },
  '& > svg': { boxSize: '6' },
};
