import { CSSObject } from '@chakra-ui/react';
import featBg from '@/assets/hyperbet/images/feature/featRaceBg.png';
import featBgMb from '@/assets/hyperbet/images/feature/featRaceBgMb.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    fontFamily: 'heading',
    fontSize: 'sm',
    h: '8',
    w: '46px',
    borderRadius: 'base',
    lineHeight: 'normal',
  },
  svgMoreRacesArrowProps: {
    color: 'blackAlpha.800',
    boxSize: '5',
  },
};
export const BoxCard: CSSObject = {
  px: '2',
  contain: 'unset',
};
export const FlexFeatureWrapper: CSSObject = {
  _after: {
    content: `''`,
    bgImage: [`url(${featBgMb})`, `url(${featBg})`],
    bgRepeat: 'no-repeat',
    bgSize: ['contain', 'cover'],
    bgPos: ['top', null, '20% 30%'],
    pos: 'absolute',
    h: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: '-1',
    borderRadius: ['base', null, 'lg'],
    boxShadow: [
      'unset',
      null,
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px rgba(25, 32, 40, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.15) inset',
    ],
    m: ['1', null, '0'],
  },
  boxShadow: [
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 3px rgba(25, 32, 40, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.15) inset',
    null,
    'unset',
  ],
  bg: 'rgba(78, 178, 255, 0.14)',
  flex: '2',
  pos: 'relative',
  flexDir: 'column',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.300',
  minH: '250px',
  py: '4',
  px: '3',
};
export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: ['column', null, 'row'],
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  pos: 'relative',
  flex: '1.5',
  textShadow: '0px 4px 6px rgba(0, 0, 0, 0.20)',
  mt: ['16', null, '-2'],
  mb: '2',
  color: 'white',
  fontStyle: 'accent',
};
export const TextFeatureHeading: CSSObject = {
  display: 'none',
};
export const TextFeatureSubHeading: CSSObject = {
  display: 'none',
};
export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
};
export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};
export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'normal',
  _last: {
    color: 'white',
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};
export const HeadingSlogan: CSSObject = {
  color: 'beta.300',
  fontFamily: 'accent',
  fontWeight: 'bold',
  fontSize: '3xl',
  textShadow: '0px 0px 6px rgba(173, 241, 6, 0.7)',
  textTransform: 'capitalize',
};
export const LinkRaceDetails: CSSObject = {
  display: 'none',
};
export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  svg: {
    color: 'beta.300',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
};
export const TextCardHeader: CSSObject = {
  fontSize: 'sm',
  color: 'white',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  pt: '1',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.3)',
};
export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'center',
};
export const TextRaceNumber: CSSObject = {
  fontFamily: 'body',
  bg: 'delta.300',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  borderRadius: 'sm',
  color: 'delta.900',
  px: '1',
  h: '16px',
  maxH: '16px',
  minH: '16px',
  lineHeight: '16px',
};
export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: 'full',
  minH: '50px',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  borderRadius: 'base',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  border: 'none',
};
export const LinkFeatureCard: CSSObject = {
  color: 'blackAlpha.800',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontWeight: 'bold',
  pl: '2',
  pr: '1',
  pt: '1',
  pb: '0.5',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  _hover: {
    textDecor: 'underline',
  },
};
export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};
