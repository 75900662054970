import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'header.betslipBtn.desc': 'Slip',
  'error.heading': `Houston, we have a problem!`,
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.heading': `System malfunction!`,
  'onboarding.locationnotallowed.header': 'Out of orbit!',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing BetBlast from outside of Australia.',
  'betslip.betslipbetcard.errors.propositionclosed': 'This event is closed',
  'sports.allsports.azheading': 'Sports',
};
