import { CSSObject } from '@chakra-ui/react';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};
export const FlexWrapper: CSSObject = {};
export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};
export const CountdownTimer = {
  '&': {
    '.countdown-flex': {
      background: 'transparent',
    },
  },
};
export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  color: 'beta.300',
  fontFamily: 'accent',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  textOverflow: 'ellipsis',
  _hover: {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
  },
  '.chakra-badge.state-inprogress': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  },
  '.chakra-badge.state-prior': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  },
  '.chakra-badge.state-ended': {
    mr: '1',
    mt: '1.5',
    borderRadius: 'md',
    maxW: '10',
    fontSize: '9px',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  },
};
export const RaceNumber: CSSObject = {
  color: 'beta.300',
};
export const FlexDetails: CSSObject = {
  color: 'beta.300',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
export const Icon: CSSObject = {
  color: 'beta.300',
  filter: 'drop-shadow(0px 0px 9px rgba(173, 241, 6, 0.3))',
};
export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};
export const ButtonChevron: CSSObject = {
  boxSize: '7',
  minW: '7',
  color: 'beta.300',
  p: '0',
  borderRadius: 'base',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
  },
  '& > svg': {
    boxSize: '6',
    filter: 'drop-shadow(0px 0px 9px rgba(173, 241, 6, 0.3))',
  },
};
