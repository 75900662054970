import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    fontSize: 'md',
    fontWeight: 'bold',
    fontFamily: 'body',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    pb: ['0', null, '3'],
    textTransform: 'uppercase',
    mx: ['3', null, '0'],
    pt: ['3', null, '0'],
  },
  pageButtonWellWrapperOverride: {
    h: '28px',
    w: 'full',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'normal',
    bgGradient: 'linear(to-b, beta.200, beta.400)',
    textTransform: 'uppercase',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
    fontFamily: 'body',
    borderRadius: 'base',
    sx: {
      '&&': {
        button: {
          color: 'blackAlpha.800',
          textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
        },
        fontWeight: 'bold',
        fontSize: '11px',
        color: 'blackAlpha.800',
        svg: {
          bg: 'blackAlpha.700',
          p: '0.5',
          color: 'beta.200',
          borderRadius: 'sm',
        },
      },
    },
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: '0',
  },
  hStackWrapper: {
    justifyContent: 'flex-end',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'extrabold',
    textTransform: 'uppercase',
    color: 'white',
    fontFamily: 'body',
  },
  scrollButtonGroup: {
    bg: 'none',
    boxShadow: 'none',
    variant: 'filter',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
  },
  mainStackInner: {
    spacing: '4',
    sx: {
      '> div[class*="chakra-stack"]:first-child': {
        mt: '2',
      },
      'a[data-testid*="pageButton-button"]': {
        h: '28px',
        w: 'full',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 'normal',
        bgGradient: 'linear(to-b, beta.200, beta.400)',
        textTransform: 'uppercase',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
        fontFamily: 'body',
        borderRadius: 'base',
        color: 'blackAlpha.800',
        textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
        svg: {
          bg: 'blackAlpha.700',
          p: '0.5',
          color: 'beta.200',
          borderRadius: 'sm',
        },
        sx: {
          '&&': {
            fontWeight: 'bold',
            fontSize: '11px',
          },
        },
      },
    },
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'sm',
    gap: '1',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'body',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white',
  },
  headingIcon: {
    color: 'beta.400',
    sx: {},
  },
  propositionButton: {
    variant: 'odds',
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  boxSportWrapper: {
    display: 'none',
  },
  buttonSport: {
    pr: '28px',
    sx: {
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        border: '1px',
        borderColor: 'white',
        boxSize: '2.5',
        top: '7px',
        right: '2.5',
        borderRadius: 'full',
        pr: '2',
      },
      ':hover': {
        '&:after': {
          borderColor: 'whiteAlpha.700',
        },
      },
    },
  },
  buttonAllSports: {
    _active: {
      bg: 'beta.400',
      color: 'blackAlpha.700',
      border: 'none',
      '&:after': {
        display: 'none',
      },
    },
  },
  segmentedButtonSortByTime: {
    h: '6',
    color: 'white',
    fontSize: '11px',
    textTransform: 'uppercase',
    fontWeight: 'extrabold',
    borderRadius: 'base',
    _hover: {
      color: 'beta.400',
    },
    _active: {
      bgGradient: 'linear(to-b, beta.200, beta.400)',
      color: 'blackAlpha.700',
    },
  },
  segmentedButtonSortBySport: {
    h: '6',
    color: 'white',
    fontWeight: 'extrabold',
    fontSize: '11px',
    textTransform: 'uppercase',
    borderRadius: 'base',
    _hover: {
      color: 'beta.400',
    },
    _active: {
      bgGradient: 'linear(to-b, beta.200, beta.400)',
      color: 'blackAlpha.700',
    },
  },
};
