import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const TextBetCount: CSSObject = {
  fontSize: 'xs',
  fontFamily: 'body',
  pl: '0.5',
  color: 'white',
  pos: 'relative',
  bottom: '1',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const GroupChevron: CSSObject = {
  boxSize: '6',
  color: 'beta.400',
  minW: 'unset',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  transition: 'all .3s ease-in-out',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  _hover: {
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
  },
  _after: {
    opacity: '0',
  },
};

export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};

export const GroupIcon: CSSObject = {
  color: 'white',
};
