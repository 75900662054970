import { NavigationSchema } from './Navigation.styles';

export const navigationStyles: NavigationSchema = {
  boxWrapperOuterProps: {
    h: '100%',
    py: ['0', '0', '2.5'],
    bgGradient: ['unset', null, 'linear(to-b, alpha.300, alpha.500)'],
    boxShadow: [
      'unset',
      null,
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    ],
    borderRadius: 'lg',
    mb: 4,
  },

  boxWrapperProps: {},
  boxNavigationWrapperProps: {
    overflowX: 'unset',
  },
  navigationBoxProps: {
    mx: '2',
    boxShadow: [
      'unset',
      null,
      '0px 0px 9px 0px rgba(173, 241, 6, 0.3), 0px 0px 0px 1px rgba(36, 38, 40, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset',
    ],
    border: ['unset', null, '2px solid rgba(173, 241, 6, 1)'],
  },
};
