import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'odds',
    boxSize: '9',
    lineHeight: 'normal',
    h: 8,
    w: 'auto',
    minW: '8',
  },
};

export const FlexOdds: CSSObject = {
  p: '2',
  borderRadius: 'none',
  fontWeight: 'bold',
  fontSize: 'sm',
  bg: 'none',
  border: 'none',
  boxShadow: 'none',
  color: 'white',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.30)',
  fontFamily: 'body',
  opacity: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mr: '0',
  pr: '0',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
  mb: '1',
};

export const HStackActions: CSSObject = {
  alignItems: ['flex-end', 'center'],
  flexDir: 'row',
};
