import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'bold',
  fontFamily: 'body',
  color: 'beta.600',
  textTransform: 'capitalize',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['4px', null, '0px'],
};
