import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'xl',
  fontWeight: 'normal',
  fontFamily: 'Staatliches',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  mx: ['2', null, '0'],
};
export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: '0',
};
