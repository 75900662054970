import { CashOutModalSchema } from '@/views/account/MyBets/Components/MyBetsCard/components/CashOutModal/styles/CashOutModal.styles';

export const cashOutModalStyles: CashOutModalSchema = {
  modalContent: {
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    borderRadius: 'lg',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    border: 'none',
    sx: {
      '.chakra-modal__close-btn': {
        color: 'white',
      },
    },
  },
  stackWrapper: {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
  },
  heading: { color: 'white' },
  moneyValues: { color: 'beta.300' },
  cashedOutText: {},
  cashedOutQuestion: { color: 'white' },
  declineButton: {
    variant: 'outline',
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  acceptButton: {
    variant: 'odds',
    fontWeight: 'bold',
    lineHeight: 'normal',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
    fontFamily: 'accent',
  },
  doneButton: {},
};
