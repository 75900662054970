import { CustomButtonProps } from '@/components/Button/Button';

export const buttonLifeTimeExclusionProps: CustomButtonProps = {
  variant: 'secondary',
};

export const buttonSubmitExclusionProps: CustomButtonProps = {
  variant: 'secondary',
  width: 'full',
  sx: {
    span: {
      color: 'blackAlpha.800',
    },
  },
};
