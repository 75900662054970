import { CSSObject } from '@chakra-ui/react';

export const ContainerForms: CSSObject = {
  'button[data-cy="updateDepositLimitButton"]': {
    pt: '0.5',
    position: 'relative',
    borderRadius: 'md',
    border: 'none',
    bgGradient: 'linear(to-b, beta.300, beta.500)',
    boxShadow: '0px 2px 5px 0px #00000040',
    color: 'alpha.700',
    fontFamily: 'accent',
    fontSize: 'sm',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    _hover: {
      opacity: '0.8',
    },
    ':active, &[data-active=true]': {
      bg: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
      boxShadow:
        '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      textShadow: 'none',
    },
    _disabled: {
      opacity: '0.4',
      pointerEvents: 'none',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    '&&&': {
      '& > svg, & > span': { zIndex: 9 },
    },
  },
};
