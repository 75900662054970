import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '2',
    mb: '3',
    fontFamily: 'accent',
  },
  FlexCard: {
    my: '1',
    borderRadius: 'lg',
    bgGradient: 'linear(to-b, #FFFFFF1F, #FFFFFF0D)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 2px 12px 0px rgba(0, 0, 0, 0.45)',
  },
  LinkCardHeader: {
    bg: 'transparent',
    color: 'beta.400',
  },
  TextCardHeading: {
    color: 'white',
    fontSize: 'xs',
    fontWeight: 'bold',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  },
  TextRaceNumber: {
    bg: 'whiteAlpha.400',
    borderRadius: 'sm',
    color: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    lineHeight: '4',
    fontFamily: 'body',
  },
  GridCardContent: {
    color: 'blackAlpha.400',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.400',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 'normal',
    boxShadow: '0px 1px 0px 0px #FFFFFF1A, 0px 2px 4px 0px #00000026 inset',
    fontFamily: 'body',
    fontSize: '8px',
  },
  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px solid',
    borderBottomColor: 'blackAlpha.300',
    px: '2.5',
    fontFamily: 'body',
    minH: '44px',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'bold',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  TextRunnerNumber: {
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    sx: {
      _last: {
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },
  TextScratched: {
    color: 'white',
  },
  LinkRaceDetails: {
    boxShadow: '0px 2px 5px 0px #00000040',
    color: 'white',
    border: '2px solid',
    borderColor: 'beta.400',
    position: 'relative',
    m: '2',
    py: '3',
    lineHeight: 'normal',
    borderRadius: 'md',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    transition: 'all .2s ease-in-out',
    _hover: {
      opacity: 0.8,
    },
    _disabled: {
      opacity: 0.4,
      pointerEvents: 'none',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonRaceCardOddsProps: {
    minW: '48px',
    fontSize: '13px',
    variant: 'odds',
    h: '34px',
    pt: '0.5',
  },
};

export const TextRunner: CSSObject = {
  color: 'white',
};

export const IconCardHeader: CSSObject = {
  color: 'beta.400',
};
