import { CompetitionsMatchCardSchema } from './CompetitionsMatchCard.styles';

export const competitionsMatchCardStyles: CompetitionsMatchCardSchema = {
  vsBoxWrapper: {
    position: 'relative',
    p: '1',
  },
  vsDivider: {
    borderStyle: 'dashed',
    borderColor: '#222',
    ml: '5',
    opacity: '0.2',
    w: '97%',
  },
  absoluteCenter: {
    px: '2',
    left: '2',
    ml: '1px',
    fontWeight: 'bold',
    color: 'beta.300',
    fontFamily: 'accent',
    fontSize: 'sm',
    textTransform: 'uppercase',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))',
  },
  matchCardOverride: {
    mb: '2',
    bgGradient:
      'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid rgba(255, 255, 255, 0.16)',
  },
  propositionButtonOverride: {
    w: '100%',
  },
  gridItem: {
    w: '100%',
  },
  flexWrapper: {
    alignItems: 'center',
  },
  flexText: {
    as: 'span',
    flex: '1',
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'accent',
    fontSize: 'sm',
    color: 'white',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  },
  gridWrapper: {
    gap: 2,
  },
  propositionButtonOverrideSecondary: {
    variant: 'odds',
    w: ['50px', '100px'],
    px: ['0', null, 'inherit'],
  },
  gridItemTextNoProposition: {
    w: ['50px', '100px'],
    h: 'full',
    minH: '10',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'body',
    fontWeight: 'black',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'md',
    border: '2px',
    borderColor: 'blackAlpha.300',
    bg: 'transparent',
  },
  flexAwayTeam: {
    alignItems: 'center',
  },
  flexAwayTeamText: {
    flex: '1',
    textAlign: 'left',
    fontFamily: 'accent',
    fontWeight: 'bold',
    fontSize: 'sm',
    color: 'white',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
  },
};
