import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  overflow: 'hidden',
  borderRadius: 'md',
  '.chakra-accordion__item': {
    mb: '2',
    bg: 'rgba(34, 92, 161, 0.5)',
    boxShadow:
      '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset, 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
    backdropFilter: 'blur(8px)',
    borderRadius: 'md',
    borderTop: 'none',
  },
  '.chakra-accordion__panel': {
    color: 'white',
    borderTop: '1px solid',
    borderTopColor: 'rgba(255, 255, 255, 0.05)',
    pt: '3',
    px: '3',
    '.chakra-text': {
      fontFamily: 'body',
      fontSize: 'xs',
      _first: {
        fontWeight: 'semibold',
        color: 'white',
      },
      _notFirst: {
        fontWeight: 'medium',
        borderRadius: 'base',
        bg: 'whiteAlpha.300',
        color: 'whiteAlpha.700',
        opacity: '0.5',
        border: 'none',
      },
    },
  },
};
export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  fontFamily: 'body',
  fontSize: 'sm',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  '> button': {
    px: '3',
    py: '1.5',
    lineHeight: 'normal',
  },
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'white',
  },
  _hover: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
};
export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    borderRadius: 'xs',
    border: '1px solid black',
  },
};
export const AccordionHeaderLogOut: CSSObject = {
  border: 'none',
  bg: 'rgba(34, 92, 161, 0.5)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset, 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
  backdropFilter: 'blur(8px)',
  borderRadius: 'md',
  fontSize: 'sm',
  mt: '-2',
  marginLeft: '0',
  marginRight: '0',
  px: '3',
  py: '2.5',
  _hover: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.200',
    color: 'white',
  },
};
export const IconPerson: CSSObject = {
  '&&': {
    display: 'none',
  },
};
export const TextAccordion: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontFamily: 'body',
  mr: 'auto',
};
export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'alpha.800',
  borderRadius: 'md',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  fontSize: 'sm',
  gap: '1',
  p: '2',
  '.chakra-text': {
    fontWeight: 'var(--bc-fontWeights-normal) !important',
    color: 'white',
    textDecoration: 'none',
    lineHeight: 'normal',
  },
  '.chakra-icon': {
    color: 'white',
  },
  mb: '2',
};
export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'white',
  },
  border: 'none',
  mb: '0',
};
