import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'lg'],
  bg: 'beta.800',
  mb: '1',
  mr: '0',
  ml: '0',
  px: ['2', '3'],
  pt: '1',
  mt: '3',
  gap: '1',
  height: 'auto',
  boxShadow:
    '0px 1px 0px 0px var(--bc-colors-whiteAlpha-300), 0px 3px 5px 0px var(--bc-colors-blackAlpha-400) inset',
  '.same-race-multi': {
    bg: 'transparent',
  },

  pl: ['0', null, '1.5'],
  h: 'fit-content',
  '&:has(+ .subTabBar)': {
    borderBottomRadius: 'none',
    mb: '0',
  },
  '&.tabBar': {
    bg: 'beta.800',
    boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.20) inset',
    fontFamily: 'accent',
    borderRadius: ['none', 'lg'],
    h: 'fit-content',
    mt: 2,
    py: 0,
    '&:has(+ .subTabBar)': {
      pb: '0',
      borderBottomRadius: 'none',
    },
  },
  '&.subTabBar': {
    bg: 'delta.400',
    fontFamily: 'accent',
    borderBottomRadius: ['none', 'lg'],
    borderTopRadius: 'none',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
    mt: 0,
    py: 0,
    h: 'fit-content',
  },
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'quaddiesTab',
  height: '8',
  sx: {
    '&&': {
      marginLeft: '0',
      marginRight: '0',
      _hover: {
        borderColor: 'delta.400',
      },
    },
    pt: '2',
    '&&[data-active="true"]': {
      bg: 'delta.400',
      borderBottom: 'none',
      h: '34px',
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        bg: 'beta.900',
        color: 'delta.400',
        fontWeight: 'normal',
        fontSize: 'sm',
        h: '5',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
    },
    '&.subTab': {
      color: 'beta.900',
      _hover: {
        borderBottomColor: 'delta.700',
        borderBottom: '3px solid',
      },
    },
  },
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  height: '8',
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: 'delta.800',
  top: '-5px',
  right: '-5px',
  borderRadius: '3px',
  _before: {
    content: "' '",
    height: '6px',
    width: '2px',
    backgroundColor: 'beta.500',
    position: 'absolute',
    top: '2px',
    right: '3px',
    transform: 'rotate(45deg)',
  },
  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'beta.500',
    position: 'absolute',
    top: '6px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};
