import { CSSObject } from '@chakra-ui/react';

export const StyledDatePicker: CSSObject = {
  '&&&': {
    '.chakra-input, .react-date-picker__wrapper': {
      h: '38px',
      background: 'blackAlpha.500',
      color: 'white',
      borderRadius: 'base',
      transition: 'all .2s ease-in-out',
      fontSize: 'xs',
      border: 'none',
      fontFamily: 'body',
      boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
      px: '2',
      'input, span': {
        fontFamily: 'body',
        fontWeight: 'normal',
      },
      button: {
        color: 'alpha.100',
      },
      _hover: {
        bg: 'blackAlpha.600',
      },
    },
  },
};
