import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'white',
};

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: '2xs',
    textTransform: 'uppercase',
    h: '24px',
    sx: {
      '&[data-active="false"]': {
        p: '2.5',
        bgGradient: 'linear(to-b, alpha.200, alpha.400)',
        boxShadow:
          '0px 4px 10px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset',
        color: 'white',
        borderRadius: 'md',
        border: 'none',
      },
      '&[data-active="true"]': {
        p: '2.5',
        bgGradient: 'linear(to-b, alpha.800, alpha.800)',
        color: 'beta.500',
        textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset',
        borderRadius: 'md',
        fontWeight: 'bold',
        fontSize: '2xs',
        border: 'none',

        '&:hover, &:focus': {
          bgGradient: 'linear(to-b, alpha.600 , alpha.700)',
        },
      },
    },
  },
};
