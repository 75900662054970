import { CSSObject } from '@chakra-ui/react';

export const LinkContainer = () => ({
  borderTop: '1px dashed',
  borderTopColor: 'blackAlpha.400',
  borderBottom: '1px dashed',
  borderBottomColor: 'whiteAlpha.200',
  boxShadow: 'none',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  mt: '-0.5',
  bg: 'transparent',
  _hover: {
    bg: 'blackAlpha.100',
  },
});
export const BoxNextToJumpContainer: CSSObject = {
  bgGradient: `linear(to-b, alpha.300, alpha.500)`,
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  mx: '0',
  borderRadius: 'md',
  overflowY: 'hidden',
  mb: '2',
  '> div': {
    _last: {
      a: {
        borderBottom: 0,
      },
    },
  },
};
export const Icon: CSSObject = {
  boxSize: '8',
  p: '2',
  color: 'beta.300',
  bgGradient: 'linear(to-b, alpha.400, alpha.200)',
  boxShadow:
    '0px 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  border: 'none',
  borderRadius: 'md',
};
export const TextRace: CSSObject = {
  color: 'white',
  textTransform: 'uppercase',
  fontFamily: 'accent',
  fontSize: 'xs',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'bold',
};
export const TextNumber: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'delta.300',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  color: 'delta.900',
  py: '0',
  pt: '0.5',
  borderRadius: 'base',
  '&&': {
    h: '18px',
    maxH: '18px',
    minH: '18px',
    lineHeight: '18px',
  },
};
export const BoxHeading: CSSObject = {
  display: 'none',
};
export const FlexWrapper: CSSObject = {
  '.state-inprogress': {
    color: ['white', 'beta.900'],
  },
  '.chakra-badge': {
    borderRadius: 'sm',
    h: '4',
    minH: '4',
    py: '0',
    mr: '0.5',
    color: 'alpha.800',
  },
};
