import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '9',
    variant: 'raceNumbers',
    color: 'blackAlpha.700',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
      },
      '&:active, &[data-active="true"]': {
        '&&': {
          bg: 'alpha.800',
          color: 'beta.500',
          boxShadow:
            '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.40) inset',
          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
        },
      },
      '&[data-closed="true"]': {
        borderWidth: 'none',
        boxShadow: 'none',
        bg: 'gray.300',
        color: 'blackAlpha.600',
        textShadow: 'unset',
        px: '3',

        _hover: {
          bg: 'alpha.400',
        },
      },
    },
  },
};

export const TextRaceConditions: CSSObject = {
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    pos: 'relative',
    mx: '1.5',
    bg: 'beta.500',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  borderRadius: 'base',
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  mb: ['1.5', null],
};

export const FlexPageHeaderTitleWrapper: CSSObject = {
  mx: ['0', '0'],
};

export const RaceHeaderWrapper: CSSObject = {
  '& .state-inprogress': {
    color: 'black',
  },
  ml: ['0', '0'],
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: '12px',
  color: 'white',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  py: '2',
  my: '-2',
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  bg: 'zeta.400',
  mx: ['2', '4'],
  mb: '4',
  borderRadius: 'base',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'alpha.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const TextTitle: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
};

export const ResultedStatusLabel: CSSObject = {
  bg: 'delta.600',
  color: 'white',
};

export const RaceStatusText: CSSObject = {
  bg: 'orange.500',
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    w: '14',
    p: '0',
  },
};

export const RaceResultListWrapper: CSSObject = {
  gap: '2',
  mx: ['2', '4'],
  mb: '2',
  flexDirection: 'column',
};

export const FlexHeaderMetaWrapper: CSSObject = {
  '.state-prior': {
    color: 'white',
  },
};

export const CountdownTimerContainer: CSSObject = {
  borderRadius: '3px',

  '.chakra-badge.state-prior': {
    px: '2',
    py: '1',
    bg: 'blackAlpha.400',
    color: 'white',
  },
};

export const ButtonGroupWrapper: CSSObject = {
  ml: '0',
  gap: '1',
  pt: '3px',
  '&&': {
    '.quaddie': {
      color: 'beta.500',
      border: '2px solid',
      borderColor: 'beta.500',
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
      '&[data-active="false"]': {
        bgGradient: 'linear(to-b, alpha.500, alpha.600)',
        _hover: {
          bgGradient: 'linear(to-b, alpha.400, alpha.400)',
          color: 'alpha.600',
          textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
          boxShadow:
            '0px 0px 0px 2px #8D4522 inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      },
      '&.active': {
        boxShadow: 'none',
        border: '2px solid',
        borderColor: 'beta.500',
        bg: 'blackAlpha.600',
        _hover: {
          bgGradient: 'linear(to-b, alpha.400, alpha.400)',
          color: 'alpha.600',
          textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
          boxShadow:
            '0px 0px 0px 2px #8D4522 inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        },
      },
      '&:hover': {
        bg: 'blackAlpha.500',
        boxShadow: 'none',
      },
    },
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  alignItems: 'center',
  mt: ['1.5', null, 'unset'],
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'alpha.800',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};
