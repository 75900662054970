import { CSSObject } from '@chakra-ui/react';
import bg from '../../../assets/betblast/images/onboarding/bg.png';

export const WrapperFlexOnboarding: CSSObject = {
  bg: `url(${bg}), var(--bc-colors-alpha-900)`,
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  justifyContent: 'center',
  position: 'relative', // Ensure the position is set
};

export const WrapperOnboarding: CSSObject = {
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '300px'],
  gridTemplateRows: ['auto 1fr', null, '1fr'],
  gap: '0px 0px',
  gridAutoFlow: ['row', null, 'unset'],
  gridTemplateAreas: [
    `
    "."
    "."
  `,
    null,
    `
    ". ."
  `,
  ],
  maxH: ['full', null, 'unset'],
  minH: [null, null, '670px'],
  mx: [null, null, 'auto'],
  my: [null, null, 'auto'],
  borderRadius: [null, null, 'lg'],
  bg: 'alpha.600',
  border: ['unset', null, '2px solid #FF6E20'],
  boxShadow: [
    'unset',
    null,
    null,
    '0px 0px 20px 0px rgba(255, 110, 32, 0.5), 0px 0px 0px 1px rgba(7, 16, 33, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.2) inset',
  ],
  backdropFilter: 'blur(2.5px)',
  pos: 'relative',
  w: ['full', null, 'unset'],
  p: '2px',
};

export const WrapperContent: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  py: ['4', null, '10'],
  px: ['2', null, '10'],
  flex: [null, null, 1],
  my: ['0', null, 'auto'],
  maxW: ['auto', null, '460px'],
  h: '100%',
  bg: 'alpha.600',
  borderRightRadius: ['unset', null, 'lg'],
  '#email': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    color: 'white',
    fontWeight: 'normal',
    borderRadius: 'base',
    h: '38px',
    fontSize: 'xs',
    border: 'none',
    ':hover, :focus': {
      bg: 'blackAlpha.600',
    },
  },
};

export const Title: CSSObject = {
  color: 'alpha.100',
  fontSize: '13px',
  fontFamily: 'accent',
  fontStyle: 'normal',
  fontWeight: 'bold',
  display: 'flex',
  textTransform: 'uppercase',
  mb: '4',
};

export const Input: CSSObject = {
  alignSelf: 'stretch',
  h: '38px',
  '&&&': {
    borderRadius: 'base',
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    border: 'none',
    color: 'white',
    fontWeight: 'normal',
    fontSize: 'xs',
    '+.chakra-input__right-element': {
      color: 'white',
    },
    '&&:hover, &&:focus': {
      bg: 'blackAlpha.600',
    },

    '&&:active, &&:focus': {
      color: 'white',
    },

    _placeholder: {
      color: 'whiteAlpha.600',
    },
  },
};
