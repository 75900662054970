import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import { MatchesModalSchema } from '@/views/sports/components/MatchesModal/styles/MatchesModal.styles';

export const matchesModalStyles: MatchesModalSchema = {
  modalWrapper: { size: 'lg', isCentered: true, scrollBehavior: 'inside' },
  modalContent: {
    color: 'white',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    overflow: 'hidden',
  },
  modalHeader: {
    alignItems: 'center',
    borderBottom: '1px',
    borderColor: 'rgba(0, 0, 0, 0.3)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
    display: 'flex',
    gap: '4',
    justifyContent: 'space-between',
    p: '4',
    fontWeight: 'medium',
    fontSize: 'lg',
  },
  closeButton: {
    bg: 'white',
    color: 'blackAlpha.800',
    isRound: true,
    p: '1',
    size: 'xs',
    variant: 'link',
    _hover: { bg: 'gamma.300' },
  },
  divider: {
    as: 'div',
    borderColor: 'rgba(0, 0, 0, 0.3)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
  },
  modalBody: { p: '0', maxH: '400px' },
  stackWrapper: { spacing: '0' },
  linkWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: '3',
    justifyContent: 'space-between',
    p: '3',
    _hover: { bg: 'alpha.100', textDecoration: 'none' },
  },
  flexSportName: {
    align: 'center',
    fontSize: 'sm',
    fontWeight: 'medium',
    gap: '1',
    color: 'white',
  },
  iconSportName: { fontSize: '2xl', color: 'beta.300' },
  hStack: { spacing: '1' },
  countdown: {
    color: 'inherit',
    fontSize: '9px',
    h: '16px',
    py: '0',
    sx: {
      '&&': {}, // Reset
      ':not(.state-prior)': { color: 'white' },
    },
  },
  duotoneSgmLogoOverride: {
    h: 'auto',
    w: '9',
  },
  hStackIcon: {
    as: ChevronRight,
    color: 'white',
    fontSize: '2xl',
  },
};
