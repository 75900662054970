import { LinkItemSchema } from '@/views/sports/components/CompetitionsMenu/styles/LinkItem.styles';

export const linkItemStyles: LinkItemSchema = {
  link: {
    bg: 'blackAlpha.300',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: '2',
    pl: '3',
    pr: '2',
    _hover: {
      textDecoration: 'none',
    },
    _last: {
      mb: 0,
    },
  },
  textCompetitionName: {
    color: 'white',
    fontFamily: 'accent',
    letterSpacing: '1px',
    fontWeight: 'bold',
    fontSize: 'sm',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxW: '140px',
  },
  textMatchCount: {
    px: '1',
    fontSize: '2xs',
    fontWeight: 'bold',
  },
};
