import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  p: '2',
  mb: '2',
  borderRadius: 'lg',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
};
export const RunnerName: CSSObject = {
  color: 'white',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};
export const TextBarrierNumber: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'semibold',
  position: 'relative',
  top: '-0.5',
};
export const FlexDeductionsContainer: CSSObject = {
  mx: '3',
  flexDir: 'column-reverse',
  fontWeight: 'semibold',
  fontSize: 'xs',
};
export const FlexFlucsPopupContainer: CSSObject = {
  '.badgeCollapse': {
    borderLeft: '1px solid',
    borderColor: ['transparent', null, 'whiteAlpha.200'],
    pl: ['0', null, '2'],
    ml: ['0', null, '2'],
    '> div': {
      py: '1',
    },
  },
};
export const RunnerScratchedWrapper: CSSObject = {
  color: 'white',
};
export const TextDeductions: CSSObject = {
  color: 'white',
};
export const TextScratchedInfo: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'bold',
};
export const ScratchedText: CSSObject = {
  color: 'white',
  fontWeight: 'semibold',
};
export const RunnerPropositionWrapper: CSSObject = {
  columnGap: '1',
};