import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  bgGradient: 'linear(to-b, #FFFFFF1F, #FFFFFF0D)',
  border: '1px solid',
  borderColor: 'whiteAlpha.300',
  color: 'white',
  h: 'full',
  borderRadius: 'base',
  _hover: {
    bg: 'alpha.400',
  },
};

export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, alpha.600, alpha.500)',
  color: 'white',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'white',
  },
};

export const TextTitle: CSSObject = {
  color: 'white',
};
