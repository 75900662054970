import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDir: 'column',
  color: 'white',
  fontWeight: 'medium',
  height: 'auto',
  border: 'none',
  fontFamily: 'body',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  svg: {
    pt: '1',
    pos: 'relative',
    bottom: '1',
  },
};

export const FlexHeadingMiscWrapper: CSSObject = {
  zIndex: 'overlay',
  '&&&&': {
    fontSize: 'xs',
    flexDir: ['column', 'row'],
    alignItems: ['flex-start', 'center'],

    '.timestamp': {
      bg: 'beta.200',
      boxShadow:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
      borderRadius: '3px',
      color: 'blackAlpha.700',
      fontWeight: 'bold',
      fontFamily: 'body',
      textTransform: 'capitalize',
      p: '1',
      fontSize: '9px',
      mb: '0.5',
    },
  },
};

export const BoxDot: CSSObject = {
  display: ['none', 'block'],
  bg: 'whiteAlpha.400',
  border: 'none',
};

export const TextSportName: CSSObject = {
  '&&&&': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'body',
    mb: '0.5',
  },
};

export const TextVS: CSSObject = {
  alignSelf: 'end',
  color: 'white',
};
