import { BoxProps, CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bgSize: 'cover',
  bgRepeat: 'repeat',
  h: 'full',
  maxH: '100dvh',
  borderRadius: 'lg',
  p: ['0', null, null, '2'],
  bgGradient: [
    'linear(to-b, alpha.600, alpha.600)',
    null,
    'linear(to-b, alpha.300, alpha.500)',
  ],
  boxShadow: [
    'unset',
    null,
    null,
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  ],
  maxW: ['full', null, null, null, '272px'],
  w: ['full', null, '320px', null, '272px'],
  marginTop: '1',
};
export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  // when copying this file for each theme, edit this value as necessary
  // depending on the difference between the keypad and betslip footer heights
  p: '8px',
  mx: '3px',
  borderTop: ['unset', null, '1px solid'],
  borderTopColor: ['unset', null, 'rgba(255, 255, 255, 0.01)'],
  mb: isKeypadVisible ? 'unset' : '1',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});
export const BetSlipContainer: BoxProps = {
  borderRadius: 'md',
  boxShadow: [
    'unset',
    null,
    null,
    '0px 0px 9px 0px rgba(173, 241, 6, 0.3), 0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset, 0px 0px 0px 1px rgba(36, 38, 40, 1) inset',
  ],
  border: ['none', null, null, '2px solid'],
  borderColor: ['unset', null, null, 'beta.300'],
};
export const BetSlipFooterContainer: CSSObject = {
  bg: 'blackAlpha.400',
  color: 'alpha.100',
  borderBottomRadius: 'md',
  '&&': {
    pt: '2',
    pb: '3',
    px: '3',
    mb: '0.5',
  },
};
export const BetSlipFooterRowTitle: CSSObject = {
  fontWeight: 'medium',
  color: 'white',
  fontFamily: 'body',
  fontSize: 'sm',
};
export const BetSlipFooterRowValue = (): CSSObject => ({
  fontWeight: 'bold',
  color: 'beta.300',
  fontFamily: 'body',
  fontSize: 'sm',
});
export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'normal',
};
export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};
export const buttonClearBetsProps: CustomButtonProps = {
  variant: 'unstyled',
  height: '6',
  width: 'auto',
  margin: 'auto',
  mt: '-4',
  pl: 1,
  pr: 2,
  display: 'flex',
  color: 'white',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontSize: '2xs',
  fontFamily: 'accent',
  fontWeight: 'bold',
  borderRadius: 'md',
  bg: 'alpha.600',
  textTransform: 'uppercase',
  border: '2px solid',
  borderColor: 'beta.300',
  boxShadow: '0px 0px 10px 0px rgba(173, 241, 6, 0.7)',
  _hover: {
    border: '2px solid',
    borderColor: 'white',
    color: 'beta.300',
  },
  sx: {
    '.chakra-button__icon': {
      marginInlineEnd: '0.5',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3.5',
    },
  },
};
export const BetslipVStack: CSSObject = {
  gap: '2.5',
};
export const BetSlipFooterMotion: CSSObject = {
  position: 'sticky',
  mx: ['0', null, null, null, '0.5'],
};
export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'solid',
  height: '42px',
  sx: {
    '&&': {
      fontWeight: 'bold',
      fontSize: 'xs',
      pt: '1',
      svg: {
        pb: '1',
      },
    },
  },
};
