import { CSSObject, BoxProps } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'beta.500',
  mb: '1',
  fontSize: '2xs',
  fontWeight: 'semibold',
};

export const FooterMoreOpenText: CSSObject = {
  color: 'beta.500',
};

export const BoxMoreText: CSSObject = {
  w: '8',
};

export const DividerMulti: CSSObject = {
  borderStyle: 'dashed',
  borderColor: 'blackAlpha.300',
  mx: '2',
};

export const footerWrapperExpanded: BoxProps = {
  sx: {
    '& > div': {
      color: 'white',
    },
  },
};

export const toteMultiGrid: CSSObject = {
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '6',
  display: 'grid',
};

export const toteMultiGridText: CSSObject = {
  fontSize: 'xs',
  w: 'full',
  color: 'whiteAlpha.700',
  mb: '2',
  '&.raceNumber': {
    fontWeight: 'bold',
    borderBottom: '1px dashed',
    borderColor: '#00000040',
    boxShadow: '0px 1px 0px 0px #FFFFFF1A',
    color: 'whiteAlpha.700',
  },
};
