import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    variant: 'tab',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '2xs',
    px: '2',
    borderRadius: 'md',
    sx: {
      '&&': {
        h: '24px',
      },
      '&[data-active="true"]': {
        bgGradient: 'linear(to-b, gray.200, gray.300)',
        color: 'gray.600',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.20) inset',

        '&:hover, &:focus': {
          color: 'gray.700',
          bgGradient: 'linear(to-b, gray.300 , alpha.400)',
        },
      },
    },
  },
};

export const FlexRaceAvailableWrapper: CSSObject = {
  color: 'white',
};
