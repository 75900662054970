import { CSSObject } from '@chakra-ui/react';
import {
  TButtonProp,
  TFlexPropWrapper,
  TTextProp,
} from './Propositions.styles';

export const FlexPropWrapper = ({
  propLength,
}: TFlexPropWrapper): CSSObject => ({
  ...(!!propLength && {}),
});

export const ButtonProp = ({
  propLength,
  isSelected,
}: TButtonProp): CSSObject => ({
  '&&': {
    borderRadius: 0,
    bg: 'transparent',
    boxShadow: 'none',
    border: 'none',

    '&:disabled': { bg: 'transparent' },

    ...((propLength ?? 0) === 2 && {
      p: 0,
    }),
    ...((propLength ?? 0) > 2 && {
      borderTop: '1px',
      borderTopColor: 'blackAlpha.200',
      p: '2',

      _first: { borderTop: 0 },
    }),
    ...(!!isSelected && {}),
  },
});

export const TextProp = ({ propLength, isSelected }: TTextProp): CSSObject => ({
  '&&': {
    fontSize: 'xs',

    _first: {
      color: 'white',
      textTransform: 'uppercase',
      fontSize: 'xs',
      fontWeight: 'bold',

      ...((propLength ?? 0) === 2 && {
        mb: '1',
      }),
      ...((propLength ?? 0) > 2 && {
        fontSize: 'xs',
        fontWeight: 'medium',
      }),
    },

    '&.return-amount': {
      borderRadius: 'base',
      border: 'none',
      bgGradient: 'linear(to-b, alpha.100, alpha.200)',
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
      color: 'blackAlpha.800',
      fontFamily: 'body',
      fontSize: 'xs',
      fontWeight: 'black',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
      _hover: {
        bgGradient: 'linear(to-b, alpha.400, alpha.400)',
        color: 'alpha.100',
        textShadow: 'none',
        boxShadow:
          '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #1CD5FF inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
      },
      ':active, &[data-active=true]': {
        bg: 'blackAlpha.500',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.35) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
        color: 'beta.400',
        textShadow: '0px 0px 9px rgba(243, 100, 23, 0.6)',
      },
      _disabled: {
        opacity: 1,
        pointerEvents: 'none',
        bg: 'blackAlpha.400',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
        color: 'whiteAlpha.300',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
      },

      ...((propLength ?? 0) > 2 && {
        maxW: '48px',
      }),
    },

    ...(!!isSelected && {}),
  },
});
