import { CSSObject, BoxProps } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'semibold',
};
export const FooterMoreOpenText: CSSObject = {
  color: 'white',
  '&': {
    px: '0',
  },
  fontSize: 'xs',
  '+ div[class*="myBetsCard-ContentRow"]': {
    position: 'relative',
    borderTop: '1px #1A345C dashed',
    '&::before': {
      position: 'absolute',
      content: '""',
      width: 'full',
      height: '0',
      top: '0',
      left: '0',
      borderBottom: '1px rgba(255, 255, 255, 0.1) dashed',
    },
  },
};
export const BoxMoreText: CSSObject = {
  w: '8',
};
export const DividerMulti: CSSObject = {
  position: 'relative',
  borderStyle: 'dashed',
  borderColor: 'rgba(0, 0, 0, 0.35)',
  width: 'unset',
  '&::before': {
    position: 'absolute',
    content: '""',
    width: 'full',
    height: 'full',
    top: '1px',
    left: '0',
    borderBottom: '1px rgba(255, 255, 255, 0.1) dashed',
  },
};
export const footerWrapperExpanded: BoxProps = {
  sx: {
    '& > div': {
      color: 'white',
    },
  },
};
