import { CompetitionBySportV2Schema } from '@/views/sports/CompetitionsBySport/styles/CompetitionBySportV2.styles';

export const competitionBySportV2Styles: CompetitionBySportV2Schema = {
  containerNav: { mb: '3' },
  scrollButtonGroup: {
    mt: '3',
    variant: 'filter',
  },
  scrollButtonGroupDivider: {
    mb: '3',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    borderColor: 'blackAlpha.400',
  },
  competitionListBoxWrapper: {
    w: 'full',
    px: ['2', null, '0'],
  },
  scrollButtonGroupButtonCompetition: {
    sx: {
      pr: '6',
      '&:after': {
        border: '1px solid',
        borderColor: 'white',
        content: '""',
        position: 'absolute',
        display: 'block',
        boxSize: '10px',
        top: '7px',
        right: '2',
        borderRadius: 'full',
        pr: '2',
        backgroundImage: `none`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      },
      '&[data-active]': {
        pr: '2',
        _after: {
          pos: 'relative',
          top: 'unset',
          right: '-2px',
        },
      },
    },
  },
  scrollButtonGroupButtonAllLeagues: {
    sx: {
      '&:hover, &:active, &[data-active]': {
        bg: 'gamma.300',
        color: 'blackAlpha.700',
        '&:after': {
          display: 'none',
        },
      },
    },
  },
};
