import * as betgalaxy from './LocationNotAllowed.styles.betgalaxy';
import * as betprofessor from './LocationNotAllowed.styles.betprofessor';
import * as betroyale from './LocationNotAllowed.styles.betroyale';
import * as fiestabet from './LocationNotAllowed.styles.fiestabet';
import * as goldenrush from './LocationNotAllowed.styles.goldenrush';
import * as juicybet from './LocationNotAllowed.styles.juicybet';
import * as puntcity from './LocationNotAllowed.styles.puntcity';
import * as puntgenie from './LocationNotAllowed.styles.puntgenie';
import * as questbet from './LocationNotAllowed.styles.questbet';
import * as sterlingparker from './LocationNotAllowed.styles.sterlingparker';
import * as vikingbet from './LocationNotAllowed.styles.vikingbet';
import * as volcanobet from './LocationNotAllowed.styles.volcanobet';
import * as wellbet from './LocationNotAllowed.styles.wellbet';
import * as junglebet from './LocationNotAllowed.styles.junglebet';
import * as oldgill from './LocationNotAllowed.styles.oldgill';
import * as sugarcastle from './LocationNotAllowed.styles.sugarcastle';
import * as buffalobet from './LocationNotAllowed.styles.buffalobet';
import * as titanbet from './LocationNotAllowed.styles.titanbet';
import * as bet777 from './LocationNotAllowed.styles.bet777';
import * as slambet from './LocationNotAllowed.styles.slambet';
import * as gigabet from './LocationNotAllowed.styles.gigabet';
import * as luckystride from './LocationNotAllowed.styles.luckystride';
import * as goldenbet888 from './LocationNotAllowed.styles.goldenbet888';
import * as hyperbet from './LocationNotAllowed.styles.hyperbet';
import * as chromabet from './LocationNotAllowed.styles.chromabet';
import * as templebet from './LocationNotAllowed.styles.templebet';
import * as bet575 from './LocationNotAllowed.styles.bet575';
import * as betblast from './LocationNotAllowed.styles.betblast';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  titanbet,
  bet777,
  slambet,
  gigabet,
  luckystride,
  goldenbet888,
  hyperbet,
  chromabet,
  templebet,
  bet575,
  betblast,
};
