import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  mt: '2',
};

export const TextRaceNumber: CSSObject = {
  flex: 0,
  py: '1',
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '5',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'delta.300',
  borderRadius: 'base',
  color: 'delta.900',
  ml: '0.5',
  lineHeight: '0.7',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
};
export const LinkRaceContainer: CSSObject = {
  borderRadius: 'base',
  bgGradient: `linear(to-b, alpha.300, alpha.500)`,
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  mb: '2.5',
  transition: 'all .3s ease-in-out',
  _hover: {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
  },
  '.chakra-badge': {
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};
export const TextRaceType: CSSObject = {
  fontFamily: 'heading',
  color: 'beta.300',
  fontSize: 'md',
  textTransform: 'capitalize',
  fontWeight: 'normal',
};
export const TextRace: CSSObject = {
  fontFamily: 'accent',
  color: 'white',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  pt: '0.5',
};
export const Icon: CSSObject = {
  boxSize: '5',
  color: 'rgba(173, 241, 6, 1)',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
