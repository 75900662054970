import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'header.betslipBtn.desc': 'Slip',
  'betslip.betslipmodal.emptybetslipheading': 'Ready To Place Some Bets?',
  'betslip.betslipmodal.emptybetslipsubheading':
    'Your bet slip is empty. To get started, add your racing and sports selections.',
  'onboarding.locationnotallowed.header': 'Cyber Restriction',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing HyperBet from outside of Australia',
  'maintenance.heading': 'Cyber enhancements in progress.',
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'error.heading': 'Lights out, please try again.',
  'sports.allsports.azheading': 'Sports',
  'generic.sportAZ': 'A-Z',
};
