import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import toggleLeft from '@/assets/betblast/images/switch/toggleLeft.png';
import toggleRight from '@/assets/betblast/images/switch/toggleRight.png';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: 'md',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, 'unset'],
    mb: 4,
    gap: '2',
    boxShadow: [
      null,
      null,
      '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(149, 239, 255, 0.2) inset, 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
    ],
    backdropFilter: 'blur(8px)',
    bg: 'alphaAlpha.800',
    h: '16',
    pos: 'relative',
    _before: {
      bg: '#FF6E20',
      h: '20px',
      w: '3px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRightRadius: '2px',
      content: '""',
      pos: 'absolute',
      top: '23px',
      left: '0',
    },
    _after: {
      bg: '#FF6E20',
      h: '20px',
      w: '3px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderLeftRadius: '2px',
      content: '""',
      pos: 'absolute',
      top: '23px',
      right: '0',
    },
  }),
});

const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'accent',
    fontWeight: 'black',
    fontSize: 'sm',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 0px 12px rgba(255, 110, 32, 0.7)'
      : '0px 1px 0px rgba(255, 255, 255, 0.15)',
    color: isActive ? 'beta.400' : 'blackAlpha.700',
    letterSpacing: 'wide',
    px: '3',
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  label: 'desktopToggle-Switcher',
  shouldForwardProp: (prop) => !['isChecked'].includes(prop),
  baseStyle: ({ isChecked }: any) => ({
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-3))',
    '--switch-track-width': '46px',
    bg: 'transparent',
    pos: 'relative',
    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      _before: {
        bgImg: isChecked ? `url(${toggleRight})` : `url(${toggleLeft})`,
        boxSize: '12',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-18px',
        right: '0',
        top: '-2',
      },
    },
    '.chakra-switch__track': {
      boxShadow:
        '0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.1)',
      bg: 'blackAlpha.600',
      borderRadius: 'base',

      _before: {
        bg: 'blackAlpha.600',
        h: '7px',
        w: '34px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        borderRadius: '2em',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
        content: '""',
        pos: 'absolute',
        left: '2',
        top: '2.5',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        <FormattedMessage id="sport" defaultMessage="Sports" />
      </TextToggle>
    </FlexWrapper>
  );
}
