import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'odds',
    boxSize: '34px',
    minWidth: '34px',
    minHeight: '34px',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
  pt: '3px',
  '&&': {
    '.quaddie': {
      boxShadow:
        '0px 3px 5px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px rgba(255, 255, 255, 0.30) inset',
      bg: 'linear-gradient(180deg, var(--bc-colors-alpha-200) 0%, var(--bc-colors-alpha-400) 100%)',
      color: 'white',
      textShadow: '0px 1px 3px rgba(0, 0, 0, 0.30)',
      border: '2px solid',
      borderColor: 'beta.400',
      '&[data-active="false"]': {
        bg: 'linear-gradient(180deg, var(--bc-colors-alpha-200) 0%, var(--bc-colors-alpha-400) 100%)',
        boxShadow: 'none',
        _after: {
          position: 'inherit',
        },
      },
      '&.active': {
        border: '2px solid',
        borderColor: 'beta.400',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.30) inset',
        color: 'beta.500',
        bg: 'blackAlpha.500',
        _hover: {
          border: '2px solid',
          borderColor: 'beta.400',
          color: 'white',
          bg: 'alpha.500',
          boxShadow:
            '0px 3px 5px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px #002F6F inset',
        },
      },
      '&:hover': {
        border: '2px solid',
        borderColor: 'beta.400',
        color: 'white',
        bg: 'alpha.500',
        boxShadow:
          '0px 3px 5px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 1px #002F6F inset',
      },
    },
  },
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  bg: 'none',
  boxShadow: 'none',
};

export const TextRaceConditions: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  _after: {
    content: `""`,
    w: '1px',
    h: '4',
    bg: 'whiteAlpha.200',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'white',
  bg: 'green.500',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const RaceStatusText: CSSObject = {
  color: 'orange.800',
  bg: 'orange.400',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'white',
  textTransform: 'none',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'normal',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  m: '0',
  py: '3',
};

export const RaceRunnerList: CSSObject = {
  bg: 'transparent',
  p: '0',
};

export const TextTitle: CSSObject = {
  fontFamily: 'Staatliches',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontSize: 'xl',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'normal',
  textTransform: 'uppercase',
};

export const RaceName: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  mt: '1',
  pb: '1.5',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  columnGap: '0',
  '&&': {
    mr: ['unset', null, 'auto'],
  },
  '.chakra-switch__track': {
    background: 'alpha.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
    '& .chakra-switch__thumb': {
      background: 'white',
      '&[data-checked]': {
        bg: 'beta.500',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset',
      },
    },
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mr: '2',
  pb: '2',
  alignItems: 'center',
  justifyContent: 'space-between',
  '> div:last-child': {
    flexGrow: ['1', 'unset'],
    alignItems: ['end', 'center'],
  },
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '12',
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  mb: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RaceResultListWrapper: CSSObject = {
  flexDirection: 'column',
};

export const ShowMoreText: CSSObject = {
  pt: '2',
  color: 'gray.800',
  fontSize: 'xs',
  mb: '2',
  cursor: 'pointer',
  fontWeight: 'bold',
};
