import { FlexProps, StackProps } from '@chakra-ui/react';
import { styleImports } from './Nav.styles.imports';
import { getThemeName } from '@/helpers/getThemeConfig';

const themeName = getThemeName();

export type NavSchema = Partial<{
  wrapper: StackProps;
  link: FlexProps;
}>;

export const navStyles: NavSchema = {
  wrapper: {
    'data-testid': 'allSports-nav-wrapper',
    sx: {
      '&[data-isOnSportsAll="true"]': {
        pb: '4',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderColor: '#C2C2C2',
      },
    },
    ...(() => {
      try {
        return styleImports[themeName]?.navStyles?.wrapper;
      } catch (err) {
        return {};
      }
    })(),
  },

  link: {
    'data-testid': 'allSports-nav-link',

    ...(() => {
      try {
        return styleImports[themeName]?.navStyles?.link;
      } catch (err) {
        return {};
      }
    })(),
  },
};
