import { CSSObject } from '@chakra-ui/react';

export const LinkWrapper: CSSObject = {
  px: 2,
  py: 1,
  mx: 3,
  mb: 1.5,
  bg: 'rgba(34, 92, 161, 0.5)',
  border: '1px solid rgba(149, 239, 255, 0.2)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
  backdropFilter: 'blur(8px)',
  borderRadius: 'md',
  _last: {
    mb: 3,
  },
};

export const TextListName: CSSObject = {
  color: 'white',
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};
export const IconSGM: CSSObject = {
  w: '35px',
  display: 'block',
  mr: '2',
  color: 'beta.300',
};

export const TextSpanName = (isVS: boolean): CSSObject => ({
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  ...(isVS && { color: 'beta.400', mx: '1' }),
});

export const FlexSubTextWrapper: CSSObject = {
  color: 'alpha.100',
  fontSize: '2xs',
  fontWeight: 'semibold',
  flex: 1,
};

export const IconSport: CSSObject = {
  p: '2',
  boxSize: '32px',
  color: 'blackAlpha.800',
  bg: 'unset',
  bgGradient: 'linear(to-b, alpha.100, alpha.200)',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
};

export const IconChevron: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  ml: '0',
  w: 4,
  h: 4,
};

export const FlexEndWrapper: CSSObject = {
  '> div': { gap: '4px' },
  mr: '1',
  '.countdown-flex, .timestamp': {
    color: 'white',
    fontSize: '2xs',
    bg: 'transparent',
  },
};

export const SubTextIcon: CSSObject = {
  '&&': {
    color: 'whiteAlpha.400',
    boxSize: '5',
  },
};
