import { CSSObject } from '@chakra-ui/react';

export const FormWrapper: CSSObject = {
  color: 'white',
  bg: 'transparent',
  '.chakra-checkbox__label': {
    color: 'white',
  },
  '.chakra-checkbox__control': {
    bg: 'white',
    color: 'alpha.400',
    border: '1px solid',
    borderColor: 'gray.300',
    width: '1rem',
    height: '1rem',
    borderRadius: 'base',
    _disabled: {
      opacity: '0.4',
    },
    _invalid: {
      bg: 'red.100',
    },
    _hover: {
      bg: 'alpha.400',
      boxShadow: '0px 0px 8px 0px rgba(43, 108, 176, 0.40)',
    },
    _checked: {
      boxShadow: 'none',
      color: 'alpha.400',
      _hover: {
        bg: 'white',
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        bg: 'alpha.400',
      },
    },
  },
  '.chakra-stack': {
    mb: '0',
    'div[class*="FormControlGroup"]': {
      mb: '0',
    },
  },
  'button[data-cy="promotionsNotificationsSubmit"]': {
    mt: '4',
    h: '10',
    borderRadius: 'base',
    lineHeight: 'normal',
    border: 'none',
    bgGradient: 'linear(to-b, beta.200, beta.400)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
    color: 'blackAlpha.800',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'black',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    _hover: {
      bgGradient: 'linear(to-b, beta.100, beta.100)',
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.35) inset',
    },
    ':active, &[data-active=true]': {
      bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
      boxShadow:
        '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      color: 'alpha.100',
      textShadow: 'none',
    },
    _disabled: {
      opacity: 0.4,
      pointerEvents: 'none',
    },
  },
};
