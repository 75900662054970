import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,

      readableColor: 'beta.500',

      background: {
        bg: ['alpha.600', null, 'transparent'],
        borderRadius: 'lg',
        px: ['0', null, null, '5'],
        py: ['0', null, null, '3'],
        w: 'unset',
      },

      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',

          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        boxSize: ['20px', null, '28px'],
        mr: '2',
        filter: 'drop-shadow(0px 0px 9px rgba(173, 241, 6, 0.3))',
        color: 'rgba(173, 241, 6, 1)',
        bgGradient: ['unset', null, 'linear(to-b, alpha.400, alpha.200)'],
        boxShadow: [
          'unset',
          null,
          '0px 3px 4px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
        ],
        borderRadius: ['unset', null, 'base'],
        p: ['unset', null, '1'],
      },
      headerSX: {
        cursor: 'default',
        color: ['gamma.300', null, 'white'],
        fontFamily: ['accent', null, 'subHeading'],
        fontWeight: ['bold', null, 'black'],
        fontSize: ['xs', null, '2xs'],
        textShadow: ['none', null, '0px 0px 10px rgba(255, 255, 255, 0.6)'],
        height: '45px',
        borderBottom: ['1px dashed rgba(0, 0, 0, 0.2)', null, 'none'],
        _hover: {
          paddingLeft: '2',
          color: ['gamma.300', null, 'white'],
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: 'none',
        mb: '0',
      },
      link: {
        ...base.link,
        textTransform: 'uppercase',
        fontSize: 'xs',
        fontWeight: 'bold',
        height: '45px',
        color: 'white',
        fontFamily: 'accent',
        transition: 'all, 0.2s linear',
        borderTop: '1px solid',
        borderTopColor: 'rgba(255, 255, 255, 0.05)',
        borderBottom: '1px solid',
        borderBottomColor: 'rgba(0, 0, 0, 0.3)',
        textShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',

        ':first-child': {
          borderTop: 'none',
          textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
        },
        ':last-child': {
          borderBottom: 'none',
        },

        py: '3',
        px: ['0', null, null, '2'],
        ml: '0',

        '~ .nested': {
          pl: '0',
        },

        ':hover': {
          bg: 'beta.300',
          color: 'alpha.800',
          boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.3)',
        },

        ':active': {
          bg: 'blackAlpha.500',
          color: 'beta.300',
          boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.3)',
        },

        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },

        '.dropDownIcon': {
          filter: 'drop-shadow(0px 0px 9px rgba(173, 241, 6, 0.3))',
          color: 'rgba(173, 241, 6, 1)',
          fill: 'none',
          bgGradient: 'unset',
          boxShadow: 'unset',
        },
      },

      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['0', null, null, '2'],
          borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
          borderTop: '1px solid rgba(255, 255, 255, 0.05)',
        },

        '> a, .dropDownIcon, a': {
          filter: 'drop-shadow(0px 0px 9px rgba(173, 241, 6, 0.3))',
          color: 'rgba(173, 241, 6, 1)',
        },

        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            px: ['3', null, null, '2'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,

        p: '0',

        '.dropDownInner': {
          color: 'beta.300',
          bg: 'blackAlpha.500',
          boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.3)',
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          borderTop: '1px solid rgba(255, 255, 255, 0.05)',
          'svg:not(.dropDownIcon)': {
            filter: 'drop-shadow(0px 0px 9px rgba(173, 241, 6, 0.3))',
            fill: 'rgba(173, 241, 6, 1)',
          },
        },

        '> .dropDownIcon': {
          filter: 'drop-shadow(0px 0px 9px rgba(173, 241, 6, 0.3))',
          fill: 'rgba(173, 241, 6, 1)',
          color: 'rgba(173, 241, 6, 1)',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
