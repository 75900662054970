import { CSSObject, TextProps } from '@chakra-ui/react';

export const formLabelError: TextProps = {
  color: 'red.400',
  fontSize: '2xs',
  fontWeight: 'medium',
  mt: '1.5',
  sx: { svg: { top: 0 } },
};

export const FormControlGroup: CSSObject = {
  'div.chakra-input__group > input[data-cy="passwordInput"]': {
    bg: 'blackAlpha.500',
    border: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
    _placeholder: {
      color: 'white',
    },
    '&:hover, &:focus': {
      border: 'none',
      bg: 'blackAlpha.500',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.15) inset',
    },
  },
  '.chakra-input__left-element, .chakra-input__right-element': {
    top: '-1px',
  },
  'input#email': {
    border: 0,
    boxShadow: '0px 1px 5px 0px #00000026 inset, 0px 1px 0px 0px #FFFFFF1A',
    '&:hover, &:focus': {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'beta.300',
      borderLeftRadius: 'md',
    },
  },
  '#title-input[disabled], #firstName-input[disabled], #lastName-input[disabled], #mobile-input[disabled], #email-input[disabled], #address-input[disabled], #city-input[disabled], #state-input[disabled], #dateOfBirth-input[disabled]':
    {
      border: 'none',
      boxShadow: 'none',
      bg: 'whiteAlpha.300',
      opacity: 0.5,
      color: 'whiteAlpha.700',
      fontSize: 'xs',
      p: 3,
      '&:hover, &:focus': {
        boxShadow: 'none',
      },
    },
};

export const FormLabelThemed: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  color: 'white',
  label: {
    color: 'white',
  },
  'div.chakra-input__group > input[data-cy="passwordInput"]': {
    bg: 'gray.50',
    borderLeft: '1px',
    borderColor: 'gray.300',
    color: 'alpha.900',
    boxShadow: 'none',
    fontWeight: 'normal',
    fontSize: 'xs',
    _placeholder: {
      color: 'gray.400',
    },
    _hover: {
      bg: 'gray.100',
      borderColor: 'gray.400',
    },
    _focus: {
      bg: 'gray.50',
      borderColor: 'blue.300',
    },
  },
  'input#email': {
    borderRadius: 'md',
    borderLeft: '1px',
    borderColor: 'gray.300',
    bg: 'gray.50',
    color: 'gray.700',
    _hover: {
      bg: 'gray.50',
    },
  },
  'div.chakra-input__group > input[data-cy="field-x"]': {
    bg: 'blackAlpha.600',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
    borderRadius: 'md',
    border: 'none',
    textShadow: 'none',
    _focus: {
      color: 'whiteAlpha.500',
    },
  },
};
