import { CSSObject } from '@chakra-ui/react';

export const BoxBordered: CSSObject = {
  pt: '1',
};
export const TextTitle: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
};
export const PageInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};
export const SmallHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
};
export const Text: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};
export const ImageGamblersHelp: CSSObject = {
  bg: 'white',
  p: '2',
  minW: '184px',
  w: '184px',
  borderRadius: 'lg',
  boxShadow:
    '0px 0px 0px 2px #12171E inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.45) inset, 0px 3px 6px 0px rgba(0, 0, 0, 0.35)',
};
export const FlexResponsibleGamblingWrapper: CSSObject = {
  my: '2',
};
export const Link: CSSObject = {
  color: 'gamma.300',
  fontSize: 'xs',
  fontWeight: 'bold',
  _hover: {
    textDecoration: 'none',
  },
};
export const SubHeading: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  mb: '2',
};
export const GridWrapper: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1',
  maxW: 'container.sm',
  pb: '0',
};
export const ButtonAction: CSSObject = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.200, gamma.400)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  border: '1px solid',
  borderColor: 'transparent',
  _hover: {
    bgGradient: 'linear(to-b, gamma.500, gamma.500)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
    border: '1px solid',
    borderColor: 'rgba(255, 255, 255, 0.45)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'gamma.300',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};
