import { CSSObject } from '@chakra-ui/react';

export const Accordion: CSSObject = {
  border: 'none',
  overflow: 'hidden',
  borderRadius: 'md',
  '.chakra-accordion__item': {
    mb: '1.5',
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    borderRadius: 'md',
    px: '1',
  },
  '.chakra-accordion__panel': {
    color: 'white',
    borderTop: '2px solid',
    borderTopColor: 'whiteAlpha.200',
    pt: '3',
    '.chakra-text': {
      _first: {
        color: 'white',
      },
      _notFirst: {
        borderRadius: 'base',
        bg: 'whiteAlpha.300',
        color: 'whiteAlpha.700',
        opacity: '0.5',
        border: 'none',
      },
    },
  },
};
export const AccordionHeader: CSSObject = {
  color: 'white',
  transitionDuration: 'normal',
  transitionProperty: 'common',
  fontFamily: 'accent',
  fontSize: 'sm',
  borderBottom: '1px solid',
  borderBottomColor: 'blackAlpha.400',
  '.chakra-accordion__icon': {
    boxSize: '6',
    color: 'white',
  },
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
};
export const OverviewPromotionsPanel: CSSObject = {
  color: 'white',
  '.chakra-checkbox__control': {
    borderRadius: 'xs',
    border: '1px solid black',
  },
};
export const AccordionHeaderLogOut: CSSObject = {
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  border: 'none',
  borderRadius: 'md',
  fontFamily: 'accent',
  fontSize: 'sm',
  mt: '-2',
  marginLeft: '0',
  marginRight: '0',
  px: '4',
  _hover: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _focus: {
    bg: 'blackAlpha.300',
    color: 'white',
  },
  _last: {
    mb: '1',
  },
};
export const IconPerson: CSSObject = {
  '&&': {
    display: 'none',
  },
};
export const TextAccordion: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontFamily: 'heading',
  mr: 'auto',
  mt: '0.5',
};
export const FlexContactToChange: CSSObject = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'alpha.100',
  borderRadius: 'base',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
  fontSize: 'xs',
  fontWeight: 'normal',
  gap: '1',
  p: '1',
  pl: '2',
  color: 'white',
  '.chakra-text': {
    pt: '0.5',
  },
  '.chakra-icon': {
    color: 'white',
  },
  mb: '2',
  span: {
    textDecoration: 'none',
    fontWeight: 'normal',
  },
};
export const OverviewAccordionItem: CSSObject = {
  '.chakra-checkbox__label': {
    color: 'white',
  },
  border: 'none',
  bg: 'alpha.500',
  mb: '0',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
};
