import { CSSObject } from '@chakra-ui/react';

export const GroupText: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};
export const TextBetCount: CSSObject = {
  fontSize: '2xs',
  fontFamily: 'body',
  fontWeight: 'bold',
  pl: '0.5',
  color: 'beta.500',
  pos: 'relative',
  bottom: '1',
};
export const GroupChevron: CSSObject = {
  borderRadius: 'md',
  boxSize: '6',
  minW: 'unset',
  border: 'none',
  transition: 'all .1s ease-in-out',
  bgGradient: 'linear(to-b, alpha.100, alpha.200)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'black',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400, alpha.400)',
    color: 'alpha.100',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #1CD5FF inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.2) inset',
  },
  ':active, &[data-active=true]': {
    bg: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const FlexGroupWrapper: CSSObject = {
  mb: '1.5',
};
export const GroupIcon: CSSObject = {
  color: 'white',
};
