import { CSSObject } from '@chakra-ui/react';

export const TitleContainer = (): CSSObject => ({
  bg: 'transparent',
  borderTopRadius: 'lg',
  w: '100%',
  overflow: 'visible',
  fontSize: 'md',
  borderBottom: 'none',
  pb: '4',
  pt: '0',
  mt: ['2', null, '0'],
  '&&': { px: ['2', '4'], pb: ['1', null, '0'] },
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'bold',
  fontSize: 'lg',
  fontFamily: 'KHInterference',
  textTransform: 'uppercase',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  mx: '0',
});

export const BoxHeading: CSSObject = {
  color: 'white',
  mb: '0',
};

export const BoxWrapper: CSSObject = {
  borderRadius: 'lg',
  minHeight: '0',
  bg: 'alpha.400',
  color: 'beta.300',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
};

export const WrapperContainer: CSSObject = {
  px: ['2', null, '4'],
  pb: '4',
  borderTopRadius: 'none',
  borderBottomRadius: 'lg',
  borderTop: 'none',
  mb: '3',
};
