import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'primary',
  height: '38px',
};

export const FlexWrapper: CSSObject = {
  bg: 'blackAlpha.400',
  borderBottomRadius: 'lg',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'semibold',
  },

  '.chakra-form__label': {
    color: 'white',
    mt: '0',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, gamma.300 0%, gamma.400 100%)',
    boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.3)',
    border: 'none',
    _hover: {
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  '.chakra-input__left-addon': {
    bg: ' transparent',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    bg: 'beta.700',
    border: 'none',
    m: '2px',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'beta.400',
      border: 'none',
    },
    _focus: {
      bgGradient: 'linear(to-b, beta.800, beta.500)',
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.2)',
      border: 'none',
      // m: '0',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  mx: 0,
  mb: 0,
  bg: 'beta.400',
  borderBottomRadius: 'md',
  zIndex: '10',
};

export const InfoHeader: CSSObject = {
  h: '9',
  alignItems: 'center',
  px: '4',
  py: '3',
  bg: 'beta.600',
  borderTop: '4px solid',
  borderColor: 'gamma.300',
};

export const InfoText: CSSObject = {
  _first: {
    fontSize: 'sm',
    color: 'gamma.300',
    fontFamily: 'accent',
    fontWeight: 'normal',
  },
  _last: {
    fontSize: 'sm',
    color: 'white',
    fontFamily: 'roboto',
    fontWeight: 'bold',
  },
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
};

export const toteMultiListSelections: CSSObject = {
  color: 'white',
  px: '4',
  py: '2',
  display: 'flex',
  fontSize: 'sm',
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
  fontWeight: 'bold',
  _last: {
    borderBottom: 'none',
  },
  sx: {
    '.toteMultiLegNum': {
      mr: '8',
      '& + p > span': {
        ml: '2',
        fontWeight: 'normal',
      },
    },
  },
};

export const toteMultiListSelectionsIcon: CSSObject = {
  mt: '-3px',
  width: '24px',
  height: '24px',
  color: 'gamma.300',
};
