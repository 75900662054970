import { CSSObject } from '@chakra-ui/react';
import featuredMatchMb from '@/assets/betblast/images/feature/featSportMb.png';
import featuredMatchDt from '@/assets/betblast/images/feature/featSportDesktop.png';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '215px'],
  boxShadow:
    '0px 0px 20px 0px rgba(255, 110, 32, 0.5), 0px 0px 0px 1px rgba(7, 16, 33, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.2) inset',
  bgImage: [`url(${featuredMatchMb})`, null, `url(${featuredMatchDt})`],
  bgSize: ['cover', 'auto 100%'],
  bgRepeat: 'no-repeat',
  bgPos: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'accent',
  border: '2px solid',
  borderColor: '#FF6E20',
  borderRadius: 'lg',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
};

export const HeadingSlogan: CSSObject = {
  color: 'alpha.100',
  fontSize: ['md', '2xl'],
  textTransform: 'uppercase',
  fontWeight: 'bold',
  textShadow: '0px 0px 9px rgba(28, 213, 255, 0.5)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'transparent',
  color: 'beta.500',
  border: '2px solid',
  borderColor: 'beta.500',
  fontSize: 'xs',
  fontWeight: 'bold',
  borderRadius: 'md',
  fontFamily: 'body',
  textShadow: 'none',
  boxShadow: 'none',
};

export const TextMisc: CSSObject = {
  bg: 'whiteAlpha.300',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: 'base',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  fontFamily: 'body',
  span: {
    color: 'whiteAlpha.300',
    mx: 1,
  },
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: ['15px', null, 'lg'],
  fontWeight: 'bold',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontSize: '2xl',
  fontWeight: 'bold',
  color: 'beta.300',
  textTransform: 'uppercase',
};

export const puntButtonProps: CSSObject = {
  fontSize: 'xs',
  color: 'blackAlpha.800',
  textTransform: 'uppercase',
  h: '9',
};
