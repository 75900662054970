import { CSSObject } from '@chakra-ui/react';

import { CustomButtonProps } from '@/components/Button/Button';

export const buttonRunnerPropositionButtonProps: CustomButtonProps = {
  variant: 'solid',
  w: '12',
  h: '8',
  fontFamily: 'heading',
  fontWeight: 'normal',
  fontSize: 'sm',
  _disabled: {
    '&&': {
      _hover: {
        border: 'none',
        bg: 'blackAlpha.400',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
      },
    },
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
    '> span': {
      color: '#4A4C4E',
      fontFamily: 'heading',
      fontWeight: 'normal',
      textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    },
  },
};

export const favouriteFlexProps: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5',
  py: '3px',
  width: 'calc(100% + 2px)',
  maxH: 'calc(var(--bc-space-3) + 4px)',
  marginRight: '-2px',
  marginLeft: '-2px',
  position: 'absolute',
  top: '-2px',
  borderTopLeftRadius: 'md',
  borderTopRightRadius: 'md',
  border: 'none',
  borderTop: '1px solid rgba(255, 255, 255, 0.10)',
  borderRight: '1px solid rgba(255, 255, 255, 0.10)',
  borderLeft: '1px solid rgba(255, 255, 255, 0.10)',
  bg: 'alpha.600',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px -1px 0px 0px rgba(0, 0, 0, 0.05) inset',
};

export const favouriteIconProps: CSSObject = {
  boxSize: '2',
  color: 'white',
};

export const favouriteTextProps: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textShadow: '0px 2px 0px rgba(0, 0, 0, 0.15)',
};
