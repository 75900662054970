import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bg: 'beta.500',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'md',
  color: 'beta.800',
  fontSize: 'xs',
  fontWeight: 'bold',
  mb: '2',
  px: '3',
  py: '2',
};
export const ButtonGetVerified: CSSObject = {
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  borderRadius: 'base',
  color: 'white',
  boxShadow:
    '0px 0px 0px 1px rgba(55, 140, 255, 0.50) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.30)',
  fontSize: 'sm',
  fontWeight: 'normal',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  minW: 'auto',
  ml: 'auto',
  px: '4',
  py: '2',
  textTransform: 'uppercase',
  _hover: {
    bgGradient: 'linear(to-b, beta.400, beta.500)',
    boxShadow:
      '0px 2px 8px 0px rgba(0, 0, 0, 0.20), 0px 0px 0px 1px rgba(255, 255, 255, 0.50) inset',
    color: 'beta.800',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  _active: {
    bg: 'alpha.800',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.30) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.500',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
};
