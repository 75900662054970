import { CSSObject } from '@chakra-ui/react';
import bgFeaturedMatch from '@/assets/bet575/images/background/bg-featured-match.png';

export const FlexMiscWrapper: CSSObject = {
  py: 2,
};

export const FlexWrapper: CSSObject = {
  h: ['280px', '200px'],
  bgImage: `url(${bgFeaturedMatch})`,
  bgSize: 'cover',
  boxShadow:
    '0px 1px 5px 0px #00000040, 0px 0px 0px 1px #1A202A inset, 0px 0px 0px 2px #FFFFFF26 inset',
  border: '2px solid',
  borderColor: 'beta.400',
  bgPos: ['bottom right -290px', 'bottom'],
  bgRepeat: 'no-repeat',
  alignItems: 'center',
  justifyContent: 'center',
  transformStyle: 'preserve-3d',
  fontFamily: 'accent',
  mb: ['2', null, '0'],
  mt: ['4', null, '0'],
  pt: ['1', '5'],
  pl: '0',
  flexDir: ['column', 'row'],
  borderRadius: 'lg',
};

export const HeadingSlogan: CSSObject = {
  color: 'beta.300',
  fontSize: ['xl', null, null, '27px'],
  textTransform: 'uppercase',
  fontWeight: 'bold',
  mt: '2',
  textShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
};

export const FlexFeatureCardWrapper: CSSObject = {
  w: 'full',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const FlexTeamWrapper: CSSObject = {
  flexDir: ['column', 'row'],
  gap: ['1', '3'],
  maxW: '450px',
  mb: '3',
  mt: '2',
};

export const FlexTextWrapper: CSSObject = {
  width: ['unset', '60%'],
};

export const TextVS: CSSObject = {
  bg: 'alpha.500',
  color: 'beta.500',
  border: '2px solid',
  borderColor: 'beta.300',
  fontSize: 'sm',
  fontWeight: 'black',
  borderRadius: 'md',
  textShadow: 'none',
  boxShadow: 'none',
  mt: '1',
  mb: '2',
  fontFamily: 'body',
};

export const TextMisc: CSSObject = {
  bg: 'alpha.300',
  fontFamily: 'body',
  borderRadius: 'base',
  color: 'beta.300',
  fontSize: 'xs',
  fontWeight: 'bold',
  textShadow: 'none',
  span: {
    color: 'whiteAlpha.300',
    mx: 1,
  },
};

export const TextTeam: CSSObject = {
  color: 'white',
  fontSize: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'normal',
};

export const BoxHeadingWrapper: CSSObject = {
  fontSize: '2xl',
  fontWeight: 'bold',
  color: 'beta.300',
  textTransform: 'uppercase',
};

export const puntButtonProps: CSSObject = {
  fontSize: 'xs',
  textTransform: 'uppercase',
  color: 'blackAlpha.700',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  lineHeight: 'normal',
  pt: '0.5',
  h: '9',
  _after: {
    opacity: 0,
  },
};
