import { CSSObject } from '@chakra-ui/react';
import { MyBetsSchema } from './MyBets.styles';

export const FlexBetCard: CSSObject = {
  rowGap: '2',
  w: '100%',
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};

export const myBetsStyles: MyBetsSchema = {
  buttonShowMoreProps: {
    variant: 'solid',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
};
