import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    pl: ['2', null, '4'],
    h: ['fit-content', null, '38px'],
    display: 'flex',
    alignItems: 'center',
    overflowX: 'unset',
    sx: {
      '&::-webkit-scrollbar': { display: 'none' },
    },
    whiteSpace: 'normal',
  },
  breadcrumbLinkItem: {
    fontSize: '10px',
    fontFamily: 'Roboto',
    fontWeight: '700',
    color: 'white',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'underline',
    },
    sx: {
      '&:not([href])': {
        color: 'beta.300',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
