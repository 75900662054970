import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'quaddiesTab',
  sx: {
    '&&': {
      marginLeft: '0',
      marginRight: '0',
      _hover: {
        borderColor: 'alpha.500',
      },
    },
    py: '2',
    '&&[data-active="true"]': {
      bg: 'alpha.500',
      borderBottom: 'none',
      h: '34px',
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        bg: 'alpha.900',
        color: 'alpha.500',
        fontWeight: 'bold',
        fontSize: 'xs',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
    },
    '&.subTab': {
      color: 'gray.700',
      _hover: {
        borderBottomColor: 'beta.400',
      },
    },
  },
};

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'base'],
  mb: '0',
  mx: ['-2', '0'],
  mr: ['-1', 'unset'],
  px: ['2', '0'],
  mt: '2',
  gap: '1',
  pb: '1.5',
  pl: ['0', null, '1.5'],
  h: 'fit-content',
  '&:has(+ .subTabBar)': {
    borderBottomRadius: 'none',
    mb: '0',
  },
  '&.tabBar': {
    bg: 'blackAlpha.400',
    boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.20) inset',
    borderRadius: ['none', 'lg'],
    h: 'fit-content',
    mt: 2,
    pb: '0',
    mx: 'auto',
    '&:has(+ .subTabBar)': {
      pb: '0',
      borderBottomRadius: 'none',
    },
  },
  '&.subTabBar': {
    bg: 'alpha.500',
    borderBottomRadius: ['none', 'lg'],
    borderTopRadius: 'none',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
    mt: 0,
    pb: '0',
    h: 'fit-content',
    mx: 'auto',
  },
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: 'alpha.500',
  top: '-5px',
  right: '-5px',
  borderRadius: '3px',
  _before: {
    content: "' '",
    height: '6px',
    width: '2px',
    backgroundColor: 'beta.600',
    position: 'absolute',
    top: '2px',
    right: '3px',
    transform: 'rotate(45deg)',
  },
  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'beta.600',
    position: 'absolute',
    top: '6px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};
