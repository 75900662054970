import { PromosStylesSchema } from './Promos.styles';

export const promosStyles: PromosStylesSchema = {
  container: {
    bg: 'rgba(34, 92, 161, 0.5)',
    boxShadow:
      '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
    borderRadius: 'md',
    mt: 3,
  },
  body: {
    borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',
    py: 3,
    px: 0,
    mx: 3,
  },
};
