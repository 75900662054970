import { CSSObject } from '@chakra-ui/react';

export const FlexGetVerifiedContainer: CSSObject = {
  alignItems: 'center',
  bg: 'alpha.800',
  boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.20)',
  borderRadius: 'md',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'normal',
  mb: '2',
  px: '3',
  py: '2',
};
export const ButtonGetVerified: CSSObject = {
  ml: 'auto',
  px: '3',
  borderRadius: 'md',
  bg: 'alpha.500',
  border: '2px',
  borderColor: 'alpha.100',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'black',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  boxShadow: '0px 0px 10px 0px rgba(28, 213, 255, 0.5)',
  _hover: {
    color: 'beta.300',
    border: '2px',
    borderColor: 'beta.300',
    boxShadow: '0px 0px 10px 0px rgba(255, 110, 32, 0.5)',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.100',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};
