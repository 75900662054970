import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  ':has(+ div[class*="FlexBonusBetsWrapper"]) div[class*="ReviewContainer"]': {
    borderBottomLeftRadius: 'none',
    borderBottomRightRadius: 'none',
  },
};
export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  textTransform: 'uppercase',
  mb: '1',
};
export const EachWayText: CSSObject = {
  color: 'white',
  fontWeight: 'medium',
};
export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
  borderBottomColor: 'rgba(0, 0, 0, 0.25)',
};
export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'body',
};
export const ReviewStake: CSSObject = {
  fontSize: 'sm',
  color: 'beta.300',
  fontWeight: 'black',
  fontFamily: 'body',
};
export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'body',
};
export const ReviewEst: CSSObject = {
  fontSize: 'lg',
  color: 'beta.300',
  fontWeight: 'black',
  fontFamily: 'body',
};
export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'bold',
  fontSize: 'sm',
  color: 'blackAlpha.800',
  bgGradient: 'linear(to-b, alpha.100, alpha.200)',
  borderLeftRadius: isPrefix ? 'base' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'base',
  h: '10',
  _groupFocusWithin: {
    bgGradient: 'linear(to-b, beta.200, beta.400)',
  },
});
export const InputBetSlip: CSSObject = {
  border: '2px solid',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
  fontFamily: 'accent',
  h: '10',
  pl: '2.8rem',
  lineHeight: 'normal',
  ':first-child': {
    pl: '2',
  },
  '&&': {
    borderRightRadius: 'base',
    borderLeft: '2px solid',
    borderColor: 'beta.400',
    borderLeftRadius: 'base',
    bg: 'alpha.800',
    '&&': {
      borderLeft: '2px solid',
      borderColor: 'alpha.100',
    },
  },
  _hover: {
    bg: 'alpha.500',
    '&&': {
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 8px 0px rgba(28, 213, 255, 0.5)',
    },
  },
  _focus: {
    bg: 'alpha.800',
    '&&': {
      borderColor: 'beta.300',
    },
  },
};
export const FlexiReview: CSSObject = {
  pl: '7',
  "[class$='ReviewStake']": {
    color: 'alpha.100',
  },
};
export const FlexReviewExotic: CSSObject = {
  textAlign: 'left',
};
