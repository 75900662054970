import { CSSObject } from '@chakra-ui/react';

export const ModalBoxWrapper: CSSObject = {
  bg: 'rgba(34, 92, 161, 0.5)',
  borderRadius: 'md',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
  p: '3',
  color: 'white',
  backdropFilter: 'blur(8px)',
  m: 'auto',
};

export const HeaderText: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'accent',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  p: '0',
};

export const HeaderContainer: CSSObject = {
  p: '0',
};

export const ContentContainer: CSSObject = {
  '&&': {
    color: 'white',
    fontWeight: 'medium',
    mt: '3',
  },
};

export const CloseButton: CSSObject = {
  display: 'none',
};
