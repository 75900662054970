import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    fontSize: '2xs',
    fontWeight: 'bold',
    fontFamily: 'body',
    px: '2',
    borderRadius: 'base',
    textTransform: 'uppercase',
  },
};
