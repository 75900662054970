import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'quad',
  sx: {
    '&&': {
      marginLeft: '0',
      marginRight: '0',
      border: 'none',
    },
    py: '2',
    '&&[data-active="true"]': {
      bg: 'beta.400',
      borderBottom: 'none',
      h: '34px',
      '& > span': {
        borderRadius: '3px',
        padding: '1',
        bg: 'alpha.800',
        color: 'beta.400',
        fontWeight: 'bold',
        fontSize: 'xs',
      },
    },
    '&&[data-active]': {
      borderRadius: 'none',
    },
    '&.subTab': {
      color: 'gray.700',
    },
  },
};

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'base'],
  gap: 2.5,
  mb: 0,
  mx: 0,
  h: 'fit-content',
  pl: '2',

  '&:has(+ .subTabBar)': {
    borderBottomRadius: 'none',
  },

  '&.tabBar': {
    bg: 'alpha.800',
    borderRadius: ['none', 'lg'],
    h: 'fit-content',
    mt: 2,

    '&:has(+ .subTabBar)': {
      borderBottomRadius: 'none',
    },
  },

  '&.subTabBar': {
    bg: 'beta.400',
    borderBottomRadius: ['none', 'lg'],
    borderTopRadius: 'none',
    mt: 0,
    h: 'fit-content',
  },
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  maxH: '6',
  minW: 'auto',
  fontSize: '2xs',
  px: '2',
  py: '1',
  _active: {
    textShadow: '0px -1px 0px rgba(0, 0, 0, 0.25)',
  },
};

export const tickForToteMulti: CSSObject = {
  position: 'absolute',
  width: '11px',
  height: '11px',
  bg: 'beta.300',
  top: '-5px',
  right: '-5px',
  borderRadius: '2.2px',

  _before: {
    content: "' '",
    height: '5px',
    width: '2px',
    backgroundColor: 'alpha.800',
    position: 'absolute',
    top: '2px',
    right: '3px',

    transform: 'rotate(45deg)',
  },

  _after: {
    content: "' '",
    height: '2px',
    width: '4px',
    backgroundColor: 'alpha.800',
    position: 'absolute',
    top: '6px',
    right: '5px',
    transform: 'rotate(210deg)',
  },
};

export const buttonWinPlaceSingleProps: CSSObject = {
  variant: 'unstyled',
  fontFamily: 'body',
  fontSize: 'xs',
  ml: '4',
};
