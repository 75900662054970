import { CSSObject } from '@chakra-ui/react';

export const FlexBetCard: CSSObject = {
  rowGap: '1',
  w: '100%',
};

export const NoResultsText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'bold',
  color: 'white',
};
