import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'md',
  fontWeight: 'bold',
  fontFamily: 'body',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  pb: ['0', null, '2'],
  textTransform: 'uppercase',
  mx: ['3', null, '0'],
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['4px', null, '0px'],
};
