import { MarketGroupsAccordionSchema } from './MarketGroupsAccordion.styles';

export const marketGroupsAccordionStyles: MarketGroupsAccordionSchema = {
  accordionItemBox: {
    flex: '1',
    textAlign: 'left',
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'body',
    fontSize: 'xs',
    textTransform: 'uppercase',
  },
};
