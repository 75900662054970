import { CSSObject } from '@chakra-ui/react';
import bgDesktop from '@/assets/betblast/images/onboarding/bgAsideDt.png';
import bgMobile from '@/assets/betblast/images/onboarding/bgAsideMb.png';

export const WrapperAside: CSSObject = {
  bg: [`url(${bgMobile})`, null, `url(${bgDesktop}) #0E1C37`],
  bgSize: ['cover', null, '100%'],
  bgRepeat: ['no-repeat', null, 'no-repeat'],
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  borderBottom: ['3px', null, 'unset'],
  borderBottomColor: ['beta.500', null, 'unset'],
  h: ['50vw', '55vw', 'full'],
  borderLeftRadius: [null, null, '10px'],
  borderRightRadius: ['unset'],
  flex: 1,
  pt: ['10', null, '14'],
  a: {
    h: 48,
    img: {
      maxW: ['60vw', '400px', '210px'],
    },
  },
  fontFamily: 'accent',
};
