import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = { bg: 'transparent', h: 'full' };

export const ButtonDropdown: CSSObject = {
  border: 'none',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  color: 'beta.300',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  h: 'full',
  borderRadius: 'base',
  _hover: {
    bgGradient: 'linear(to-b, alpha.100, alpha.100)',
  },
};
export const BoxDropdown: CSSObject = {
  bgGradient: 'linear(to-b, beta.400, beta.300)',
  color: 'blackAlpha.800',
  fontFamily: 'accent',
  zIndex: 'docked',
  h: 'full',
  '.chakra-checkbox__label': {
    color: 'blackAlpha.800',
  },
};
export const TextTitle: CSSObject = {
  color: 'alpha.400',
};
