import { CSSObject } from '@chakra-ui/react';

export const TextCheckbox: CSSObject = {
  color: 'white',
};
export const buttonCloseAccountProps: CSSObject = {
  pt: '1',
  variant: 'secondary',
  color: 'blackAlpha.800',
};
