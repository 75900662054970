import { CSSObject, DividerProps } from '@chakra-ui/react';

export const CardMainContent: CSSObject = {
  m: '6px',
  mb: 0,
  p: 0,
  pb: 2,
  borderBottom: '1px dashed',
  borderBottomColor: 'rgba(0, 0, 0, 0.3)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.02)',
};
export const CardContainer: CSSObject = {
  border: 'none',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
  borderRadius: 'md',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.06))',
  p: 1.5,
};
export const DividerCard: CSSObject = {
  border: 'none',
};
export const CardBottomContent: CSSObject = {
  borderTop: '1px dashed',
  borderColor: 'rgba(255, 255, 255, 0.08)',
  mx: '6px',
};
export const ExoticSelectionText: CSSObject = {
  color: 'white',
};
export const ContentRow: CSSObject = {
  display: 'flex',
  '&': {
    px: 0,
  },
  alignItems: 'start',
  '> svg': {
    color: 'beta.300',
    fill: 'beta.300',
  },
  '> div > svg[data-testid="iconLegProps"]': {
    boxShadow:
      '0px 1px 0px 0px #FFF inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
  },
  lineHeight: '18px',
  'p[data-cy="betOdds"]': {
    color: 'white',
  },
};
export const dividerExoticProps: DividerProps = {
  display: 'none',
};
