import { CSSObject } from '@chakra-ui/react';

export const GridRaceItems: CSSObject = {
  mt: '3',
  columnGap: '3',
};

export const TextRaceNumber: CSSObject = {
  flex: 0,
  fontWeight: 'bold',
  textAlign: 'center',
  minWidth: '6',
  display: 'inline-block',
  fontSize: '2xs',
  h: '4',
  bg: 'beta.300',
  borderRadius: '3px',
  color: 'blackAlpha.800',
  ml: '0.5',
  lineHeight: '17px',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
};

export const BoxColumn: CSSObject = {
  bg: 'transparent',
  mb: ['0.5', '5'],
};

export const LinkRaceContainer: CSSObject = {
  bg: 'alphaAlpha.800',
  color: 'white',
  borderRadius: 'md',
  border: '1px solid rgba(149, 239, 255, 0.2)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
  backdropFilter: 'blur(8px)',
  mb: '2.5',
  transition: 'all .2s ease-in-out',
  fontWeight: 'extrabold',
  fontSize: 'xs',
  svg: {
    color: 'beta.500',
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
  },
  _hover: {
    bg: 'alpha.400',
  },
  '.chakra-badge': {
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
  },
  '[class*="CountdownFlex"]': {
    bg: 'unset',
  },
};

export const TextRaceType: CSSObject = {
  color: 'alpha.100',
  fontSize: '13px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  mt: '1.5',
  fontFamily: 'accent',
};

export const TextRace: CSSObject = {
  color: 'white',
  fontWeight: 'extrabold',
  fontSize: 'xs',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'accent',
};

export const Icon: CSSObject = {
  boxSize: '5',
  color: 'alpha.100',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
