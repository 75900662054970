import { TPlayerLayoutStylesSchema } from './PlayerLayout.styles';

const styles: Partial<TPlayerLayoutStylesSchema> = {
  row: {
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(255, 255, 255, .15)',
    pb: '2',
    boxShadow: '1px -1.5px 0px -1px #292f36 inset',
  },
  btnGroupWrapper: {
    borderRadius: '5px',
    bgGradient: 'linear(180deg, #30BA00 0%, #00CAF6 0.01%, #006E86 100%)',
    boxShadow:
      '0px 0px 0px 2px #E57D24 inset, 0px 0px 0px 4px #003541 inset, 0px 0px 0px 5px #00B9E1 inset',
    minW: '90px',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
  },
  btnGroupText: {
    fontSize: 'sm',
    color: 'white',
    fontWeight: 'black',
    fontFamily: 'roboto',
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.60)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    h: '100%',
  },
  btnGroupIcon: {
    color: 'blackAlpha.700',
    sx: {
      filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.3))',
      h: '5',
      w: '7',
    },
  },
  name: {
    color: 'blackAlpha.700',
  },
};
export default styles;
