import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['3', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: '160px',
  bg: ['blackAlpha.400', null, null, 'alpha.400'],
  boxShadow: [
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    null,
    null,
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
  ],
  backdropFilter: ['unset', null, null, 'blur(8px)'],
  borderRadius: 'md',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: 'transparent',
  color: isActive ? 'beta.200' : 'white',
  textShadow: [
    '0px 3px 4px rgba(0, 0, 0, 0.25)',
    null,
    isActive
      ? '0px 0px 12px rgba(255, 110, 32, 1)'
      : '0px 3px 4px rgba(0, 0, 0, 0.25)',
  ],
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 'xs',
  lineHeight: 'normal',
  py: ['2', '2.5'],
  px: ['2', '4'],
  fontFamily: 'accent',
  borderBottom: ['2px solid', null, '1px solid'],
  borderBottomColor: [
    isActive ? 'beta.400' : 'transparent',
    null,
    'rgba(0, 0, 0, 0.3)',
  ],
  borderTop: [null, null, '1px solid'],
  borderTopColor: [null, null, 'rgba(255, 255, 255, 0.05)'],
  _first: {
    borderTop: 'none',
    borderTopRadius: ['none', null, 'md'],
  },
  _last: {
    borderBottomRadius: 'md',
    borderBottom: 'none',
  },
  '&:hover, &:focus': {
    bg: !isActive && 'blackAlpha.300',
  },
});

export const TopNavLabelWrapper: CSSObject = {
  mx: '2',
  color: 'white',
  fontFamily: 'body',
  bg: 'blackAlpha.400',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
  borderRadius: 'md',
  w: 'unset',
};

export const NavLinkLabel: CSSObject = {
  color: 'beta.400',
  textShadow: '0px 0px 9px rgba(243, 100, 23, 0.3)',
  border: 'none',
  borderBottom: '3px solid',
  borderBottomColor: 'beta.400',
  fontSize: 'xs',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'body',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '90px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: '36px',
  ml: ['2', '4'],
};
