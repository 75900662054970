import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'xl',
  fontWeight: 'bold',
  fontFamily: 'accent',
  letterSpacing: '1px',
  color: 'white',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  textTransform: 'uppercase',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
};
