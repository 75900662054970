import { CSSObject } from '@chakra-ui/react';

export const ModalHeader: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  pb: '2',
  px: '4',
  bg: 'alpha.300',
};
export const ModalBody: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  pb: '4',
  pt: '1',
  px: '4',
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
};
export const ButtonDeposit: CSSObject = {
  mt: '3',
};
