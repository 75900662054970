import { CSSObject } from '@chakra-ui/react';
import { IS_MOBILE_APP } from '../../../constants/isMobileApp';
import { AccountWrapperSchema } from './AccountWrapperTwo.styles';

export const accountWrapperStyles: AccountWrapperSchema = {
  boxWrapper: {
    color: 'gamma.200',
    pt: ['2', null, 'unset'],
    mb: 4,
    bg: 'alpha.400',
    borderRadius: 'md',
    pos: 'relative',
    border: '1px solid',
    borderColor: 'whiteAlpha.300',
  },
  boxHeading: {
    pt: '0',
    mb: 0,
    ...(!IS_MOBILE_APP && {
      pb: '9',
    }),
    color: 'white',
  },
  textTitle: {
    mt: '0',
    color: 'white',
    fontSize: 'xl',
    fontWeight: 'bold',
    fontFamily: 'accent',
    textTransform: 'uppercase',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  accountHeaderContainer: {
    sx: {
      '&&': {
        px: '0',
        borderTopRadius: 'md',
      },
    },
  },
  accountInfoContainer: {
    sx: {
      '&&': {
        borderBottomRadius: 'md',
        pb: '2.5',
        display: 'flex',
        gap: ['0', null, '4'],
        px: ['0', null, '3.5'],
      },
    },
  },
  titleContainer: {
    pr: ['2', '4'],
    pb: '2',
    ...(IS_MOBILE_APP && {
      p: '2',
      pb: '0',
      h: 'unset',
    }),
  },
  flexVerticalWrapper: {
    flexDir: 'column',
    w: ['unset', null, 'full'],
    borderRadius: 'md',
    px: '3',
    py: '3',
    bgGradient: 'linear(to-b, #FFFFFF1F, #FFFFFF0D)',
    mt: '2',
    mx: ['2', null, '0'],
    color: 'white',
  },
};

export const BoxHeading: CSSObject = {
  pt: '0',
  mb: 0,
  ...(!IS_MOBILE_APP && {
    pb: '9',
  }),
  color: 'white',
};
