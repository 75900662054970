import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  chakra,
  ChakraComponent,
  Flex,
  Skeleton,
  Switch,
} from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import thumb from '@/assets/hyperbet/images/toggle/thumb.png';

const FlexWrapper = chakra(Flex, {
  label: 'desktopToggle-FlexWrapper',
  baseStyle: () => ({
    borderRadius: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    p: '2',
    mx: ['1.5', null, 'unset'],
    mb: 4,
    gap: '2',
    boxShadow: [
      null,
      null,
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    ],
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    h: '16',
    pos: 'relative',
    zIndex: '0',

    _before: {
      bgGradient: 'linear(to-b, alpha.300, alpha.500)',
      boxShadow:
        '0px 0px 9px 0px rgba(173, 241, 6, 0.3), 0px 0px 0px 2px rgba(173, 241, 6, 1), 0px 0px 0px 1px rgba(36, 38, 40, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset',
      h: '44px',
      w: 'calc(100% - 20px)',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRadius: 'md',
      content: '""',
      pos: 'absolute',
      top: '10px',
      left: '10px',
      zIndex: '1',
    },
  }),
});

const TextToggle = chakra(Text, {
  label: 'desktopToggle-TextToggle',
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
  baseStyle: ({ isActive }: any) => ({
    fontFamily: 'accent',
    fontWeight: 'bold',
    fontSize: 'md',
    textTransform: 'uppercase',
    textShadow: isActive
      ? '0px 0px 10px rgba(173, 241, 6, 0.7)'
      : '0px 3px 4px rgba(0, 0, 0, 0.25)',
    color: isActive ? 'beta.300' : 'white',
    letterSpacing: 'widest',
    px: '3',
    pos: 'relative',
    zIndex: '5',
  }),
}) as ChakraComponent<'text', TextProps & { isActive: boolean }>;

const Switcher = chakra(Switch, {
  label: 'desktopToggle-Switcher',
  baseStyle: () => ({
    '--switch-track-diff':
      'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-3))',
    '--switch-track-width': '40px',
    bg: 'transparent',
    pos: 'relative',
    zIndex: '5',
    '.chakra-switch__thumb': {
      bg: 'transparent',
      pos: 'relative',
      transitionDuration: '0.7s',
      zIndex: 'dock',
      _before: {
        bgImg: `url(${thumb})`,
        boxSize: '35px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        content: '""',
        pos: 'absolute',
        left: '-12px',
        right: '4px',
        top: '-4px',
      },
    },
    '.chakra-switch__track': {
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset',
      bg: 'blackAlpha.600',
      borderRadius: 'base',

      _before: {
        bg: 'blackAlpha.500',
        h: '7px',
        w: '34px',
        bgPos: 'center',
        bgRepeat: 'no-repeat',
        bgSize: 'contain',
        borderRadius: '2em',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
        content: '""',
        pos: 'absolute',
        left: '2',
        top: '2.5',
      },
    },
  }),
});

export default function Toggle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { category } = {
    category: searchParams.get('category'),
  };
  const { data, isInitialLoading } = useQueryOfferings();
  const hasSports = FEATURE_FLAGS.HAS_SPORTS_VIEW;

  if (hasSports && isInitialLoading) {
    return <Skeleton sx={{ h: '44px', mb: '2.5' }} />;
  }

  if (hasSports && !data?.offered_sports?.length) {
    return <></>;
  }

  if (
    !FEATURE_FLAGS.HAS_RACE_VIEW ||
    !hasSports ||
    (hasSports && !data?.offered_sports?.length)
  )
    return <></>;

  return (
    <FlexWrapper>
      <TextToggle
        data-cy="racing-toggle-text"
        isActive={!category || category === 'racing'}
      >
        <FormattedMessage id="racing" defaultMessage="Racing" />
      </TextToggle>

      <Switcher
        onChange={() => {
          setSearchParams({
            category: !category || category === 'racing' ? 'sport' : 'racing',
          });
        }}
        isChecked={category && category !== 'racing'}
        colorScheme="blackAlpha"
        size="lg"
        data-cy="categorySwitcher"
      />
      <TextToggle data-cy="sports-toggle-text" isActive={category === 'sport'}>
        <FormattedMessage id="sport" defaultMessage="Sport" />
      </TextToggle>
    </FlexWrapper>
  );
}
