import { BannerSchema } from '@/components/Banner/style/Banner.styles';

export const bannerStyles: BannerSchema = {
  bannerBoxProps: {
    bg: 'alpha.800',
    alignItems: 'center',
    borderRadius: 'md',
    border: 'none',
    gap: '3',
    mb: '2.5',
  },
  depositIconProps: {
    color: 'alpha.100',
    boxSize: '8',
    my: 'auto',
  },
};
