import { CSSObject } from '@chakra-ui/react';
import { BannerSchema } from '@/views/account/Overview/components/Banner/styles/Banner.styles';

export const bannerStyles: BannerSchema = {
  buttonDeposit: {
    variant: 'solid',
    lineHeight: 'normal',
  },
};
export const FlexBannerContainer: CSSObject = {
  borderRadius: 'md',
  bg: 'alpha.700',
  color: 'white',
  gap: '0',
  p: ['2', null, '3'],
  minH: '107px',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
};
export const FlexAccountDetails: CSSObject = {
  alignItems: [null, null, 'center'],
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderBottom: '1px dashed',
  borderBottomColor: '#1C233F',
};
export const FlexAccountName: CSSObject = {
  fontSize: 'md',
  fontFamily: 'body',
  fontWeight: 'bold',
  color: 'beta.300',
  textTransform: 'capitalize',
  textShadow: '0px 0px 8px rgba(255, 110, 32, 0.5)',
};
export const FlexAccountBalances: CSSObject = {
  gap: '8',
  mt: [null, null, '0'],
  mr: [null, null, '-2'],
  px: [null, null, '4'],
  pb: ['3', null, '2'],
  pt: ['3', null, '0'],
};
export const FlexBannerBalanceInner: CSSObject = {
  flex: ['1', null, '0 1 auto'],
  alignItems: ['start', null, 'end'],
};
export const TextAccountBalanceLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontFamily: 'body',
  textAlign: ['start', null, 'end'],
};
export const FlexAccountBalanceWrapper: CSSObject = {
  fontSize: 'md',
  fontFamily: 'body',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  textShadow: '0px 0px 8px rgba(255, 110, 32, 0.5)',
};
export const TextAccountBalanceCurrencySymbol = (): CSSObject => ({
  display: 'inline',
  verticalAlign: 'super',
  color: 'beta.300',
});
export const TextAccountBalanceBaseUnit = (): CSSObject => ({
  color: 'beta.300',
});
export const TextAccountBalanceSubUnit = (): CSSObject => ({
  color: 'beta.300',
});
export const FlexButtonContainer: CSSObject = {
  flexDir: ['column', null, 'row'],
  gap: '1.5',
  borderTop: '1px dashed',
  borderTopColor: 'rgba(255, 255, 255, 0.1)',
  pt: '2',
};
export const TextButton: CSSObject = {
  h: '4',
  fontSize: 'xs',
  fontFamily: 'accent',
  textTransform: 'uppercase',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
};
export const FlexBalanceBetInfo: CSSObject = {
  bg: 'alpha.500',
  borderRadius: 'base',
  border: '2px solid',
  borderColor: 'alpha.100',
  color: 'white',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'accent',
  fontSize: 'xs',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  alignItems: 'center',
  h: '10',
  boxShadow: '0px 0px 10px 0px rgba(28, 213, 255, 0.5)',
  justifyContent: 'center',
  flex: ['1', null, '0'],
  px: '4',
  py: '3',
  whiteSpace: [null, null, 'nowrap'],
};
export const FlexLabel: CSSObject = {
  flexDirection: ['column', null, 'row'],
  gap: '1.5',
};
