import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    fontSize: 'xl',
    fontFamily: 'heading',
    fontWeight: 'normal',
    color: 'white',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    mx: ['2', null, '0'],
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: '0',
  },
  hStackWrapper: {
    justifyContent: 'flex-end',
  },
  sortByText: {
    fontSize: '2xs',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  scrollButtonGroup: {
    bg: 'none',
    boxShadow: 'none',
    variant: 'filter',
  },
  flexRenderSortingMobile: {
    justify: 'flex-end',
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
    px: ['2', null, '0'],
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'lg',
    gap: '1',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'heading',
    fontWeight: 'normal',
    color: 'white',
  },
  propositionButton: {
    variant: 'odds',
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  boxSportWrapper: {
    display: 'none',
  },
  buttonSport: {
    pr: '30px',
    sx: {
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        border: '1px',
        borderColor: 'white',
        boxSize: '2.5',
        top: '7px',
        right: '2.5',
        borderRadius: 'full',
        pr: '2',
      },
    },
  },
  buttonAllSports: {
    bg: 'transparent',
    color: 'white',
    fontWeight: 'extrabold',
    fontFamily: 'body',
    border: '2px solid',
    borderColor: 'white',
    sx: {
      '&:hover, &:active, &[data-active]': {
        bg: 'gamma.300',
        color: 'blackAlpha.700',
        border: '2px solid',
        borderColor: 'gamma.300',
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  segmentedButtonSortByTime: {
    color: 'white',
    _hover: {
      color: 'gamma.300',
    },
    _active: {
      bg: 'gamma.300',
      color: 'gamma.800',
    },
  },
  segmentedButtonSortBySport: {
    color: 'white',
    _hover: {
      color: 'gamma.300',
    },
    _active: {
      bg: 'gamma.300',
      color: 'gamma.800',
    },
  },
  headingIcon: {
    sx: {
      color: 'beta.300',
    },
  },
};
