import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const IncreaseAmountWrapper: CSSObject = {
  my: '1',
  gap: '1',
};

export const buttonIncreaseProps: CustomButtonProps = {
  variant: 'primary',
};
