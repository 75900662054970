import { CSSObject } from '@chakra-ui/react';
import { RaceTypeAvailableSchema } from './RaceTypeAvailable.styles';

export const raceTypeAvailableStyles: RaceTypeAvailableSchema = {
  raceTypeButton: {
    fontFamily: 'accent',
    fontSize: '2xs',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    sx: {
      '&[data-active="false"]': {
        h: '24px',
        border: '1px solid',
        borderColor: 'beta.400',
        p: '2.5',
        bg: 'transparent',
        boxShadow: '0px 0px 8px 0px rgba(37, 254, 214, 0.6)',
        color: 'white',
        borderRadius: 'base',
      },
      '&[data-active="true"]': {
        h: '24px',
        p: '2.5',
        bg: 'blackAlpha.500',
        color: 'whiteAlpha.700',
        boxShadow:
          '0px 2px 3px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
        borderRadius: 'base',
        border: 'none',
        textShadow: 'none',
      },
      '&:hover, &:focus': {
        bg: 'blackAlpha.500',
      },
    },
  },
};

export const TextRaceAvailability: CSSObject = {
  color: 'white',
};
