import React from 'react';
import { CSSObject, FlexProps, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    display: 'flex',
    variant: 'secondary',
    h: '26px',
    px: '1.5',
    transition: 'all .3s ease-in-out',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
    spanProps: {
      fontFamily: 'accent',
      fontWeight: 'bold',
      fontSize: '11px',
      pt: '0.5',
    },
    _disabled: {
      boxShadow: 'none',
      border: 'none',
      bg: 'blackAlpha.300',
      color: 'beta.50',
      opacity: '1',
      '&& > span': {
        _first: {
          color: 'beta.300',
          mr: '1',
        },
      },
    },
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset',
    border: 'none',
    sx: {
      '.chakra-button__icon': {
        display: 'none',
      },
      ':active, &[data-active="true"]': {
        bg: 'blackAlpha.700',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'gamma.300',
        textShadow: 'none',
      },
      '&&[data-isbonusbet="true"]': {
        textShadow: 'none',
        _disabled: {
          w: '100%',
          mt: '-3',
          bg: 'blackAlpha.600',
          borderRadius: 'none',
          borderBottomRadius: 'md',
          boxShadow: 'none',
          '.chakra-button__icon': {
            display: 'block',
            color: 'green.300',
            mr: '0.5',
          },
          span: {
            fontWeight: 'medium',
            fontSize: '2xs',
            fontFamily: 'body',
            color: 'white',
            pt: '0.5',
            textTransform: 'uppercase',
          },
        },
        bg: 'blackAlpha.700',
        boxShadow:
          '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
        color: 'gamma.300',
        textShadow: 'none',
        border: 'none',
        px: '1.5',
        h: '26px',
        spanProps: {
          fontFamily: 'accent',
          fontWeight: 'normal',
          fontSize: '11px',
          textShadow: 'none',
          pt: '0.5',
        },
        _hover: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
  flexWrapperMysteryBetFooterBetPlaced: {
    py: 2,
    alignItems: 'flex-start',
  },
  iconMysteryBetFooter: {
    alignSelf: 'unset',
    color: 'alpha.400',
  },
  flexBetInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'heading',
    fontSize: 'sm',
    lineHeight: '1.25',
  },
  spanRaceInfo: {
    color: 'white',
  },
  buttonBetPropositionClosedProps: {
    variant: 'unstyled',
    bg: 'orange.500',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
    borderRadius: 'base',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'xs',
    px: '3',
    py: '2',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  buttonStakeProps: {
    variant: 'quickAccess',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'bold',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
    h: '42px',
    maxW: '45px',
    borderRadius: 'md',
  },
  iconBetCardSvgMysteryBet: {
    sx: {
      '&&': {
        svg: {
          color: 'white',
        },
      },
    },
  },
  flexWrapperMysteryBetFooterBetReview: {
    bg: 'beta.800',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.400',
    rounded: 'base',
  },
  betCardSVG: {
    color: 'white',
    pb: '0.5',
  },
};
export const betCardErrorFlexTextProps: FlexProps = {
  color: 'red.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.35)',
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const betCardErrorFlexProps: FlexProps = {
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.20)',
  color: 'red.100',
  bg: 'red.700',
  borderRadius: 'md',
  w: '95%',
  mx: 'auto',
  mt: '1',
  justifyContent: 'center',
  minH: '8',
  alignItems: 'center',
  px: '2',
  py: '1',
};
export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'white',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'alpha.50',
  },
};
export const InfoBetOdds: CSSObject = {
  color: 'white',
  display: 'flex',
  fontWeight: 'semibold',
  fontSize: 'sm',
};
export const EventSubtitle: CSSObject = {
  textTransform: 'capitalize',
  fontSize: 'xs',
  fontWeight: 'medium',
  '&&': {
    color: 'white',
  },
};
export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  ml: '8',
};
export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};
export const FlexPlacedContainer: CSSObject = {
  p: '2',
};
export const BetPlaced: CSSObject = {
  bgGradient: 'linear(to-b, gray.200, gray.200)',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 1) inset',
  color: 'gray.600',
  borderRadius: 'base',
  flexDirection: 'column',
  px: '2',
  py: '1.5',
};
export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};
export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};
export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};
export const TextPlacedLabel: CSSObject = {
  flex: '1',
};
export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5' }} />
);
export const EventRule: CSSObject = {
  color: 'white',
};
export const BetPropositionClosedError: CSSObject = {
  bg: 'rgb(0,112,215,0.7)',
  borderRadius: 'md',
  position: 'absolute',
  top: '-1px',
  left: '-1px',
  w: 'calc(100% + 2px)',
  h: 'calc(100% + 2px)',
  pb: '0',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  zIndex: '3',
};
export const BetOdds: CSSObject = {
  mb: '2px',
};
export const DefaultStakeFlexbox: CSSObject = {
  gap: '1',
};
export const ExoticsFooterContainer: CSSObject = {
  pt: '0',
  pr: '2',
  pl: '10',
};
