/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { DownArrow, UpArrow } from 'styled-icons/boxicons-solid';
import { ErrorWarning } from '@styled-icons/remix-fill';
import { CloseOutline } from '@styled-icons/evaicons-outline';
import { Stop } from '@styled-icons/octicons/Stop';
import {
  Alert,
  AlertIcon,
  Box,
  chakra,
  Collapse,
  Flex,
  Icon,
  Show,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, {
  Children,
  cloneElement,
  isValidElement,
  memo,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { InfoCircle } from 'styled-icons/fa-solid';
import {
  EBetSlipViewMode,
  EBetSubmissionConfirmationStatus,
  EBetSubmissionRejectionReason,
  TBetSlipBet,
  TBetSlipBetExotics,
  TBetSlipBetMulti,
  TBetSlipBetMystery,
  TBetSlipBetSGMulti,
  TBetSlipBetSingle,
  TBetSlipBonusChanceType,
  TMultiLeg,
  TPromoToken,
} from '../../Services/Betslip.types';
import {
  BetCardContainer,
  betCardErrorFlexProps,
  betCardErrorFlexTextProps,
  BetCardSVG,
  BetCardVStack,
  BetCardWrapper,
  BetPlaced,
  BetPlacedRow,
  BetPropositionClosedError,
  betRecoveryAlertWrapperProps,
  DefaultStakeFlexbox,
  EventMarketName,
  EventRule,
  EventSubtitle,
  EventTitle,
  ExoticsFooterContainer,
  ExoticsNumberContainer,
  ExoticsPlaceContainer,
  FlexBonusBetsWrapper,
  FlexPlacedContainer,
  IconButtonThemed,
  IconPropositionClosed,
  InfoBetOdds,
  StakeCollapse,
  TextExoticSelections,
  TextExoticsInfo,
  TextExoticsLabel,
  TextPlacedLabel,
} from './styles/BetCard.styles';
import {
  betOdds,
  centsToDollarsNumber,
  formatStake,
  getIconBySportName,
  getPositionValue,
  getStrings,
} from '@/helpers/utils';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  removeBet,
  setBonusChanceModal,
  updateBet,
} from '@/redux/Betslip.slices';
import {
  BetSlipStoreActionSchema,
  BlendedSchema,
  getBetSlipStoreActions,
  SgmSchema,
  SrmSchema,
  useMoMBet,
} from '@/store/BetSlipStore';
import { StakeRounded } from '../StakeRoundedBanner/StakeRoundedBanner.styles';
import {
  CountdownTimerBox,
  flexEventOddsProps,
  flexEventProps,
  flexEventSubTitleProps,
  FlexIconPropName,
  FlexInnerHeaderContainer,
  HeaderContainer as HeaderContainerStyle,
  IconOddsChangeArrow,
  LinkMarket,
  SpanBarrierNumber,
  TextSubtitle,
  vsWrapper,
} from '../BetCard/components/Header/Header.styles';
import SgmLogo from '@/components/SgmLogo/SgmLogo';
import SrmLogo from '@/components/SrmLogo/SrmLogo';
import Countdown from '@/components/Countdown/Countdown';
import {
  checkOddsDecreased,
  checkOddsIncreased,
  findConfirmationStatus,
  getAmountOfPlaces,
  getBetHasBeenPlaced,
  getEventRuleText,
  getPlaceFromNumber,
  getPromoToken,
} from '../../Services/Betslip.utils';
import {
  useBetRequests,
  useBetSpecificViewMode,
  useBonusBets,
  useStake,
} from '../../Services/Betslip.hooks';
import { getEventHref } from '@/views/account/MyBets/Components/MyBetsCard/components/BottomRow/BottomRow.hooks';
import { InfoExoticsContainer as InfoExoticsStyles } from '../BetCard/components/Info/styles/Info.styles';
import { srmInfoStyles } from '../BetCard/components/Info/SRM/styles/SRMInfo.styles';
import { ApprovedBet } from '../BetCard/components/Footer/ApprovedBet';
import { RejectedBet } from '../BetCard/components/Footer/RejectedBet';
import { TPunterBetRequest } from '@/api/punter/punter.types';
import { PendingBet } from '../BetCard/components/Footer/PendingBet';
import { IconBetPlaced } from '../MultiCard/Components/Placed/styles/Placed.styles';
import {
  FormsHStack,
  ReviewContainer,
} from '../BetCard/components/Forms/Forms.styles';
import { betRecoveryAlertProps } from '../BetCard/components/BetRecoveryAlert/styles/BetRecoveryAlert.styles';
import isBetRecoveryEligible from '../BetCard/BetCard.utils';
import { useDefaultStake } from '../BetCard/BetCard.hooks';
import { Button } from '@/components/Button/Button';
import { betCardStyles } from '../BetCard/styles/BetCard.styles';
import FormsStake from '../BetCard/components/Forms/FormsStake';
import FormsExoticFlexi from '../BetCard/components/Forms/FormsExoticFlexi';
import { BetslipRefSchema } from '../Modal/ModalWeb';
import { TOptionalIcon, useBonusBetIcon } from '@/hooks/useIcon';
import {
  BetApprovalContainer as BetFooterContainerStyles,
  EstReturnsBlock,
  EstReturnsLabel,
  FooterContainer,
  FooterText,
} from '../BetCard/components/Footer/Footer.styles';
import {
  EventTitle as MultiEventTitle,
  flexEventContainerProps,
  FlexEventDetails,
  HeaderContainer as MultiHeaderWrapperStyles,
  MultiCardsContainer,
} from '../MultiCard/Components/MultiCard.styles';
import { calculateMultiOdds } from '../MultiCard/Services/MultiBet.utils';
import {
  MultiCardContainer,
  OddsMultiCard,
  OddsText,
} from '../MultiCard/Components/MultiCardInput.styles';
import { useFeatureFlag } from '@/store/FeatureFlagStore';
import { mysteryBetStyles } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import { OddsChangedBanner } from '../OddsChangedBanner/OddsChangedBanner';
import {
  calculateCombosForToteMulti,
  isToteMulti,
} from '@/views/races/bets/Exotics/services/Exotics.utils';
import { SUB_TABS_DISPLAY_NAME } from '@/components/TabBar/TabBar';

// Container ---
type CardContainerProps = Pick<CardProps, 'children' | 'data'>;

export function Card(props: CardContainerProps) {
  const { data } = props;
  const values = useCardView({ data });

  if (!data || !values.shouldRender) return null;
  return <CardView {...props} {...values} />;
}

// View ---

type DataSchema = {
  viewMode: EBetSlipViewMode;
  bet: TBetSlipBet;
  betslipRef: BetslipRefSchema;
  betRequest?: TPunterBetRequest;
};

type CardProps = {
  children: ReactNode;
  data: DataSchema;
};

function CardView({ children, data }: CardProps) {
  return (
    <BetCardWrapper
      as={motion.div}
      initial={{ opacity: 0, x: 350 }}
      animate={{ opacity: 1, x: 0 }}
    >
      <BetCardContainer
        viewMode={data?.viewMode ?? ''}
        data-cy={`betCardContainer-${data?.bet.event_title}`}
      >
        {Children.map(children, (c) =>
          isValidElement(c) ? cloneElement(c, { data }) : c
        )}
      </BetCardContainer>
    </BetCardWrapper>
  );
}

// --- Controller
type UseCardViewProps = Pick<CardProps, 'data'>;

const useCardView = ({ data }: UseCardViewProps) => {
  const { bet, viewMode } = data ?? {};
  const isReviewing = viewMode === EBetSlipViewMode.ReviewingBets;
  const shouldRender = (isReviewing && Number(bet?.stake) > 0) || !isReviewing;

  return { shouldRender };
};

// -----

// View ---
type ErrorProps = Pick<CardProps, 'data'>;

Card.Error = function ErrorContainer(props: Partial<ErrorProps>) {
  const { data } = props as unknown as ErrorProps;
  const { Rejected } = EBetSubmissionConfirmationStatus;
  const nonIssue = ['ManualRejection', 'OddsChange'].includes(
    data?.bet?.confirmation?.rejection_reason ?? ''
  );
  if (nonIssue) return null;

  if (data?.bet.confirmation?.status === Rejected) {
    return <ErrorView data={data} />;
  }
  return null;
};

function ErrorView({ data }: ErrorProps) {
  const { bet } = data ?? {};
  const { errors } = getStrings()[0].BetSlip.betSlipBetCard;
  const maxStakeLimit = bet?.confirmation?.max_stake_limit;
  const { UnknownError, PropositionClosed } = EBetSubmissionRejectionReason;
  const reason = bet?.confirmation?.rejection_reason ?? UnknownError;

  let msg = maxStakeLimit
    ? errors[reason].replace(
        /{max_stake_limit}/g,
        formatStake(centsToDollarsNumber(bet?.confirmation?.max_stake_limit))
      )
    : errors[reason];

  if (bet.confirmation?.rejection_reason === 'FundTransferFail') {
    msg = 'Something went wrong. Please try submitting your bet again.';
  }

  return (
    reason !== PropositionClosed && (
      <Flex
        px="3"
        py="2"
        w="full"
        backgroundColor="red.400"
        {...betCardErrorFlexProps}
      >
        <Icon w="4" h="4" mr="2" as={Stop} />

        <Text textColor="white" {...betCardErrorFlexTextProps}>
          {msg}
        </Text>
      </Flex>
    )
  );
}

// ---

function PropClosedContainerRedux(props: Partial<PropClosedProps>) {
  const { data } = props as unknown as PropClosedProps;
  const dispatch = useAppDispatch();
  const onClick = () => dispatch(removeBet(data?.bet?.request_id ?? ''));
  const { PropositionClosed } = EBetSubmissionRejectionReason;

  if (data?.bet.confirmation?.rejection_reason !== PropositionClosed) {
    return null;
  }
  return <PropClosedView data={data} onClick={onClick} />;
}

function PropClosedContainerZust(props: Partial<PropClosedProps>) {
  const { data } = props as unknown as PropClosedProps;
  const value = usePropClosedView({ data });
  const { PropositionClosed } = EBetSubmissionRejectionReason;

  if (data?.bet.confirmation?.rejection_reason !== PropositionClosed) {
    return null;
  }
  return <PropClosedView data={data} {...value} />;
}

Card.PropClosed = FEATURE_FLAGS.HAS_NEW_BS
  ? PropClosedContainerZust
  : PropClosedContainerRedux;

type PropClosedProps = {
  onClick: () => void;
} & Pick<CardProps, 'data'>;

function PropClosedView({ onClick }: PropClosedProps) {
  return (
    <BetPropositionClosedError>
      <Button
        onClick={onClick}
        {...betCardStyles.buttonBetPropositionClosedProps}
      >
        <IconPropositionClosed />
        <FormattedMessage id="betslip.betslipbetcard.errors.propositionclosed" />
      </Button>
    </BetPropositionClosedError>
  );
}

const usePropClosedView = ({ data }: Pick<PropClosedProps, 'data'>) => {
  const { removeBet: _removeBet } = getBetSlipStoreActions();
  const onClick = () => {
    _removeBet(data?.bet.id ?? '');
  };
  return { onClick };
};

// ---

/**
 * 🚨🚨🚨🚨🚨🚨
 * FIXME: This doesn't work - needs to be implemented back
 * hasBeenRounded <-- issue
 * 🚨🚨🚨🚨🚨🚨
 */
type RoundedBannerProps = Pick<CardProps, 'data'>;

Card.RoundedBanner = function RoundedBannerContainer(
  props: Partial<RoundedBannerProps>
) {
  const { data } = props as unknown as RoundedBannerProps;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore 'TODO:'
  if (data?.bet.type === 'Exotics' && data.bet.hasBeenRounded) {
    return <RoundedBanner data={data} />;
  }
  return null;
};

function RoundedBanner({ data }: RoundedBannerProps) {
  const { bet } = data ?? {};

  return (
    <StakeRounded>
      <Icon boxSize={4} mr={2} as={InfoCircle} />
      <FormattedMessage
        id="betslip.betslipmodal.stakeRoundedDown"
        values={{
          stake: bet?.stake,
        }}
      />
    </StakeRounded>
  );
}
/**
 * 🚨🚨🚨🚨🚨🚨
 * FIXME: This doesn't work - needs to be implemented back
 * hasBeenRounded <-- issue
 * 🚨🚨🚨🚨🚨🚨
 */

// ---

type ContentProps = { children: ReactNode } & Partial<Pick<CardProps, 'data'>>;

Card.Content = function Content({ children, data }: ContentProps) {
  return (
    <BetCardVStack spacing="2">
      {Children.map(children, (c) =>
        isValidElement(c) ? cloneElement(c, { data }) : c
      )}
    </BetCardVStack>
  );
};

// ---

// Container ---

type TEvemtTitleViewProps = {
  bet: TBetSlipBet;
  betRequest: TPunterBetRequest | undefined;
};
const EventTitleView = ({ bet, betRequest }: TEvemtTitleViewProps) => {
  const isMysteryBet = bet.price_type === 'mystery_bet';
  const isPlaced =
    bet.confirmation?.status === EBetSubmissionConfirmationStatus.Placed;

  const isSingleMysteryBet = bet.confirmation?.proposition_info?.some(
    (pr) => pr.leg_num === null
  );

  const pendingBet = betRequest?.bet_legs?.[0].event_data;

  const propInfo = bet.confirmation?.proposition_info?.find((prop) =>
    isSingleMysteryBet ? prop.leg_num === null : prop.leg_num === 1
  );

  const propToDisplay = pendingBet
    ? `${getPositionValue(pendingBet?.proposition_type)} - ${
        pendingBet?.runner_number
      }. ${pendingBet?.runner_name} ${
        pendingBet?.runner_barrier_number
          ? `(${pendingBet?.runner_barrier_number})`
          : ''
      }`
    : `${getPositionValue(propInfo?.proposition_type)} - ${
        propInfo?.runner_number
      }. ${propInfo?.proposition_name} ${
        propInfo?.barrier_number ? `(${propInfo?.barrier_number})` : ''
      }`;

  if (!isMysteryBet) {
    const title =
      bet?.event_title?.toLowerCase() === 'firstfour'
        ? 'First Four'
        : bet?.event_title;
    return SUB_TABS_DISPLAY_NAME[title] ?? title ?? null;
  }

  if (isMysteryBet && !isPlaced)
    return (
      <Flex gap="2">
        <BetCardSVG
          header
          boxSize="4"
          color="white"
          name={getIconBySportName('mystery-bet')}
          {...betCardStyles.iconBetCardSvgMysteryBet}
        />
        <FormattedMessage id="generic.mystery" />
        <BetCardSVG
          header
          boxSize="4"
          color="white"
          name={getIconBySportName('mystery-bet')}
          {...betCardStyles.iconBetCardSvgMysteryBet}
        />
      </Flex>
    );

  if (isPlaced && isMysteryBet) return propToDisplay;

  return null;
};

Card.Header = function HeaderContainer(props: Partial<HeaderProps>) {
  const { data } = props as unknown as Pick<CardProps, 'data'>;
  const value = useHeaderView({
    bet: data?.bet,
    betRequest: data?.betRequest,
  });
  return <HeaderView data={data} {...value} />;
};

// View ---

type HeaderProps = {
  displayOddsChangeBanner: boolean;
  isSGMorSRMorBlended: boolean;
  betIsInReview: boolean | undefined;
  oddsIncreased: boolean | undefined;
  oddsDecreased: boolean | undefined;
  boostedOddsPromoToken: TPromoToken | undefined;
  timeDifference: number;
  eventRule: string;
  eventHref: string;
  formattedOdds: string | number | null;
  removeBets: () => void;
  legCount: () => number;
  showOddsBoost: boolean;
} & Pick<CardProps, 'data'>;

function HeaderView({
  data,
  isSGMorSRMorBlended,
  betIsInReview,
  oddsIncreased,
  oddsDecreased,
  boostedOddsPromoToken,
  timeDifference,
  eventRule,
  eventHref,
  formattedOdds,
  removeBets,
  legCount,
  showOddsBoost,
}: HeaderProps) {
  const { bet } = data ?? {};
  const marketName = bet?.event_market_name?.replace(/_/g, ' ') ?? '';
  const matchNameSplit = bet?.event_subtitle?.split(/(vs)/i) ?? [];
  const hasOddsBoost = !!boostedOddsPromoToken && showOddsBoost;

  const isToteMultiBet = isToteMulti(bet.subType as string);

  return (
    <HeaderContainerStyle
      isClosed={
        bet?.confirmation?.rejection_reason ===
        EBetSubmissionRejectionReason.PropositionClosed
      }
    >
      {data?.bet?.showFlexiChangeBanner && (
        <StakeRounded>
          <Icon boxSize={4} mr={2} as={InfoCircle} />
          <FormattedMessage
            id="betslip.betslipmodal.stakeRoundedDown"
            values={{
              stake: formatStake(
                typeof bet?.stake === 'number'
                  ? bet?.stake.toFixed(2)
                  : Number(bet?.stake).toFixed(2)
              ),
            }}
          />
        </StakeRounded>
      )}
      <FlexInnerHeaderContainer>
        <FlexIconPropName w="100%">
          {bet?.event_icon && (
            <BetCardSVG header name={getIconBySportName(bet?.event_icon)} />
          )}
          <Flex flexDirection="column" {...flexEventProps}>
            <EventTitle data-cy="betSelection" w="100%">
              {isSGMorSRMorBlended ? (
                <Flex justifyContent="space-between" w="full">
                  <div>
                    <FormattedMessage
                      id={`betSlip.betSlipModal.${
                        bet?.type === 'Blended' ? 'blendedHeader' : 'sgmHeader'
                      }`}
                      values={{
                        legCount: legCount(),
                        span: (chunks) =>
                          bet?.type === 'Blended' ? (
                            <span>{chunks}</span>
                          ) : (
                            <VisuallyHidden>{chunks}</VisuallyHidden>
                          ),
                      }}
                    />{' '}
                    {bet?.type === 'SGMulti' && (
                      <SgmLogo {...betCardStyles.betCardSVG} />
                    )}
                    {bet?.type === 'SRMulti' && (
                      <SrmLogo {...betCardStyles.betCardSVG} />
                    )}
                  </div>
                </Flex>
              ) : (
                <EventTitleView bet={bet} betRequest={data?.betRequest} />
              )}
              {!!bet?.event_data?.barrier_number && (
                <SpanBarrierNumber as="span">
                  {bet?.race_type?.toLocaleLowerCase() !== 'harness racing' &&
                    `(${bet?.event_data?.barrier_number})`}
                </SpanBarrierNumber>
              )}
            </EventTitle>

            <EventMarketName isExtraInfo data-cy="EventMarketName">
              {(() => {
                // Check if the string is intl id or a regular string
                const regex = /^[^ .]+\.[^ .]+(\.[^ .]+)*$/;
                const isID = regex.test(marketName);

                if (isID) return <FormattedMessage id={marketName} />;
                return marketName;
              })()}
            </EventMarketName>
          </Flex>
        </FlexIconPropName>

        <Flex {...flexEventOddsProps}>
          <Flex dir="row" alignItems="center" w="100%" justifyContent="end">
            <>
              {oddsDecreased && (
                <IconOddsChangeArrow direction="decrease" as={DownArrow} />
              )}
              {oddsIncreased && (
                <IconOddsChangeArrow direction="increase" as={UpArrow} />
              )}
            </>

            <Flex flexDir="column">
              <InfoBetOdds>
                {!['Exotics', 'Blended', 'SRMulti'].includes(bet?.type ?? '') &&
                  getBetHasBeenPlaced(bet) &&
                  '@'}

                {typeof formattedOdds === 'string' ? (
                  <FormattedMessage id={formattedOdds} />
                ) : (
                  formattedOdds
                )}

                {(() => {
                  if (['SRMulti', 'Blended'].includes(bet?.type ?? '')) {
                    const _bet =
                      bet?.type === 'SGMulti'
                        ? (bet as unknown as SgmSchema)
                        : (bet as unknown as BlendedSchema);

                    return _bet.odds?.toFixed(2);
                  }
                })()}
              </InfoBetOdds>
              {hasOddsBoost && (
                <InfoBetOdds oddsHaveBeenBoosted={!!showOddsBoost}>
                  {!['Exotics', 'Blended', 'SRMulti'].includes(
                    bet?.type ?? ''
                  ) &&
                    getBetHasBeenPlaced(bet) &&
                    '@'}
                  {boostedOddsPromoToken.original_odds.toFixed(2)}
                </InfoBetOdds>
              )}
            </Flex>

            {!betIsInReview && (
              <IconButtonThemed
                onClick={removeBets}
                aria-label="Remove Bet"
                data-cy="betCardRemoveBet"
                variant="ghost"
                as={CloseOutline}
              />
            )}
          </Flex>
        </Flex>
      </FlexInnerHeaderContainer>

      <Flex w="full" {...flexEventSubTitleProps}>
        <EventSubtitle data-cy="eventName">
          {isToteMultiBet && bet?.raceNumbersForToteMulti && (
            <Flex {...vsWrapper}>
              {bet?.event_data?.venue_name?.toLowerCase()} Races{' '}
              <TextSubtitle>
                {bet?.raceNumbersForToteMulti
                  .map((r) => r.split('R')[1])
                  .join(', ')}
              </TextSubtitle>
            </Flex>
          )}
          {!isToteMultiBet && (
            <LinkMarket to={eventHref}>
              {bet?.event_type?.toLocaleLowerCase() !== 'match' ? (
                bet?.event_subtitle?.toLowerCase()
              ) : (
                <Flex {...vsWrapper}>
                  {matchNameSplit?.map((n, i) => {
                    if (n.toLocaleLowerCase() === 'vs') {
                      return (
                        <TextSubtitle textTransform="lowercase">
                          vs
                        </TextSubtitle>
                      );
                    }

                    return (
                      <TextSubtitle key={`event-name-${n}-${bet?.event_id}`}>
                        {i === 0 ? n : ` ${n}`}
                      </TextSubtitle>
                    );
                  })}
                </Flex>
              )}
            </LinkMarket>
          )}
        </EventSubtitle>

        {timeDifference <= 60 && (
          <CountdownTimerBox>
            <Countdown eventTime={bet?.event_start_time ?? ''} ml="2" />
          </CountdownTimerBox>
        )}
      </Flex>

      {eventRule !== '' &&
        !['tote_single_mid', 'tote_single_best'].includes(
          bet?.price_type ?? ''
        ) &&
        !isToteMultiBet && (
          <EventRule sx={{ '&&': { mt: '1' } }}>
            <Icon boxSize="3.5" as={ErrorWarning} />
            <Text as="span" fontWeight="normal">
              <FormattedMessage id={eventRule} />
            </Text>
          </EventRule>
        )}
      {/* Not all bet types have a subtitle eg multi */}
      {bet?.event_subtitle && (
        <EventMarketName data-cy="marketType">
          {bet?.event_market_name}
        </EventMarketName>
      )}
    </HeaderContainerStyle>
  );
}

// Controller ---

type UseHeaderViewProps = Partial<Pick<DataSchema, 'bet' | 'betRequest'>>;

const useHeaderView = ({ bet, betRequest }: UseHeaderViewProps) => {
  const { removeBet: _removeBet } = getBetSlipStoreActions();
  const oddsBoostRef = useRef<Partial<TBetSlipBonusChanceType>>();
  const moMBet = useMoMBet();

  // This will need to be refactored out also
  const dispatch = useAppDispatch();
  const { displayOddsChangeBanner, bonusChance } = useAppSelector(
    (state) => state.betSlip
  );
  const { betIsInReview } = useBetSpecificViewMode(bet);

  const isSGMorSRMorBlended = ['SGMulti', 'SRMulti', 'Blended'].includes(
    bet?.type ?? ''
  );
  const oddsIncreased = checkOddsIncreased(undefined, bet, moMBet ?? undefined);
  const oddsDecreased = checkOddsDecreased(undefined, bet, moMBet ?? undefined);

  const boostedOddsPromoToken = getPromoToken(
    betRequest?.promo_tokens ?? bet?.confirmation?.promo_tokens,
    'odds-boost'
  );
  const showOddsBoost =
    !bonusChance?.game && oddsBoostRef.current?.isVisible === true;
  const odds = betOdds(
    bet,
    bonusChance?.isVisible === true,
    oddsIncreased || oddsDecreased,
    showOddsBoost ? boostedOddsPromoToken : undefined
  );

  const oddsMap: Record<string, string | number> = {
    tote_single_mid: 'generic.toteAcronym',
    tote_single_best: 'generic.bestToteAcronym',
    starting: 'generic.startingPriceAcronym',
  };

  const currentTime = new Date();
  const givenTime = new Date(bet?.event_start_time ?? '');
  const timeDifference =
    (givenTime.getTime() - currentTime.getTime()) / 1000 / 60;

  const eventRule = getEventRuleText(bet?.eventRule);

  const removeBets = () => {
    const requestID = bet?.request_id ?? '';

    if (
      bet?.proposition_id !== undefined ||
      ['SRMulti', 'Blended', 'SGMulti'].includes(bet?.type ?? '') ||
      bet?.price_type === 'mystery_bet'
    ) {
      dispatch(removeBet(requestID));

      const betID = bet?.id ?? '';
      if (betID) _removeBet(betID);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const eventHref = getEventHref(bet as any) ?? '';

  const legCount = () => {
    if (!isSGMorSRMorBlended || !bet) return 0;
    if (['SGMulti', 'Blended'].includes(bet.type)) {
      const _bet =
        bet.type === 'SGMulti'
          ? (bet as unknown as SgmSchema)
          : (bet as unknown as BlendedSchema);
      return _bet.type === 'SGMulti'
        ? _bet?.selections?.length
        : _bet?.selection?.length;
    }
    const _bet = bet as unknown as SrmSchema;
    return _bet?.selection?.reduce((a, c) => a + c.length, 0);
  };

  /**
   * Monitors changes to the visibility of the odds boost feature.
   * Stores a reference upon visibility. This reference acts as a marker to
   * determine if the animation for the odds boost has been executed and
   * subsequently removed. In cases where the bonusChance data is missing but
   * the reference exists, it implies the animation was played. Boosted values
   * are displayed only after the completion of this animation.
   */
  useEffect(() => {
    if (
      bonusChance?.game === 'odds-boost' &&
      bonusChance?.isVisible &&
      !oddsBoostRef.current
    ) {
      oddsBoostRef.current = bonusChance;
    }
  }, [bonusChance, bonusChance?.isVisible]);

  return {
    displayOddsChangeBanner,
    betIsInReview,
    isSGMorSRMorBlended,
    oddsIncreased,
    oddsDecreased,
    boostedOddsPromoToken,
    timeDifference,
    eventRule,
    eventHref,
    formattedOdds: bet?.price_type ? oddsMap[bet.price_type] ?? odds : odds,
    removeBets,
    legCount,
    showOddsBoost,
  };
};

// ---

// Container ---

Card.InfoExotics = function InfoExoticsContainer(
  props: Partial<Pick<InfoExoticsProps, 'data'>>
) {
  const { data } = props as unknown as Pick<InfoExoticsProps, 'data'>;
  const value = useInfoExoticsView({ data });

  const isToteMultiBet = isToteMulti(data?.bet.subType as TBetSlipBetMulti);
  if (isToteMultiBet) {
    const { selection, raceNumbersForToteMulti } = data?.bet;

    return (
      <InfoExoticsStyles>
        <Flex {...srmInfoStyles.srmContainer}>
          {selection.map((sel, idx) => (
            <Flex key={idx} justifyContent="space-between" mb="2" pb="0">
              <Text {...srmInfoStyles.placeNumber}>
                {`${raceNumbersForToteMulti[idx]}: ${sel.join(', ')}`}
              </Text>
            </Flex>
          ))}
        </Flex>
      </InfoExoticsStyles>
    );
  }
  if (data?.bet?.type !== 'Exotics') return null;
  return <InfoExoticsView data={data} {...value} />;
};

// View ---

type InfoExoticsProps = { amountOfPlaces: number } & Pick<CardProps, 'data'>;

function InfoExoticsView({ data, amountOfPlaces }: InfoExoticsProps) {
  const { bet } = data ?? {};
  const _bet = bet as TBetSlipBetExotics;

  return (
    <InfoExoticsStyles>
      {_bet.selection.map((p, i) =>
        i < amountOfPlaces ? (
          <ExoticsPlaceContainer
            data-testid={`place-${i + 1}`}
            key={`combo_${i}`}
          >
            <ExoticsNumberContainer>
              {getPlaceFromNumber(i + 1)}:
            </ExoticsNumberContainer>

            <TextExoticSelections data-cy={`position${i + 1}`}>
              {p.join(',')}
            </TextExoticSelections>
          </ExoticsPlaceContainer>
        ) : null
      )}
    </InfoExoticsStyles>
  );
}

// Controller ---

const useInfoExoticsView = ({ data }: Partial<InfoExoticsProps>) => {
  const { bet } = data ?? {};
  const _bet = bet as TBetSlipBetExotics;
  const amountOfPlaces =
    bet?.type === 'Exotics' ? getAmountOfPlaces(_bet?.sub_type) : 0;

  return { amountOfPlaces };
};

// ---

type SRMSelectionsProps = Pick<CardProps, 'data'>;

/**
 * TODO: This needs a container
 */
Card.SRMSelections = function SRMSelections({
  data,
}: Partial<SRMSelectionsProps>) {
  const { bet } = data as Required<SRMSelectionsProps['data']>;
  if (bet?.type !== 'SRMulti') return null;
  const _bet = bet as unknown as SrmSchema;

  return (
    <InfoExoticsStyles>
      {_bet?.selection.map((itm, idx) => {
        if (!itm.length) return null;

        return (
          <Flex
            key={`srm-${idx}`}
            data-testid={`srm-place-${idx + 1}`}
            {...srmInfoStyles.srmContainer}
          >
            {itm.map((item) => (
              <>
                <Text key={item.runner_number} {...srmInfoStyles.placeNumber}>
                  {item.runner_number}. {item.runner_name}{' '}
                  {!!item.barrier_number && (
                    <span>({item.barrier_number})</span>
                  )}
                </Text>
                <Text {...srmInfoStyles.runnerDetails}>
                  <FormattedMessage
                    id={idx === 0 ? 'racing.srm.win' : 'racing.srm.top'}
                    values={{
                      number: idx + 1,
                    }}
                  />
                </Text>
              </>
            ))}
          </Flex>
        );
      })}
    </InfoExoticsStyles>
  );
};

// ---

type BlendedSelectionProps = Pick<CardProps, 'data'>;

/**
 * TODO: This needs a container
 */
Card.BlendedSelection = function BlendedSelection({
  data,
}: Partial<BlendedSelectionProps>) {
  const { bet } = data as unknown as BlendedSelectionProps['data'];
  if (bet?.type !== 'Blended') return null;
  const _bet = bet as unknown as BlendedSchema;

  return (
    <InfoExoticsStyles>
      <Flex {...srmInfoStyles.srmContainer}>
        {_bet.selection.map((selectionItem) => (
          <Flex
            key={selectionItem.runnerId}
            justifyContent="space-between"
            mb="2"
          >
            <Text {...srmInfoStyles.placeNumber}>
              {selectionItem.runner_number}. {selectionItem.runner_name}{' '}
              {selectionItem.barrier_number && (
                <span>({selectionItem.barrier_number})</span>
              )}
            </Text>
            <Text {...srmInfoStyles.placeNumber}>
              {selectionItem.odds?.toFixed(2)}
            </Text>
          </Flex>
        ))}
      </Flex>
    </InfoExoticsStyles>
  );
};

// ---

// ---

// Container ---

type BetApprovalContainerProps = Pick<BetApprovalProps, 'data'>;

Card.BetApproval = function BetApprovalContainer(
  props: Partial<BetApprovalContainerProps>
) {
  const { data } = props as Required<BetApprovalContainerProps>;
  const { shouldShow, ...values } = useBetApprovalView({ bet: data?.bet });
  if (!shouldShow) return null;
  return <BetApprovalView data={data} {...values} />;
};

Card.OddsIncreasedBanner = function OddsIncreasedBanner(
  props: Partial<BetApprovalContainerProps>
) {
  const { data } = props as Required<BetApprovalContainerProps>;
  const { bet } = data;

  if (!bet) return null;

  const oddsIncreased = checkOddsIncreased(undefined, bet);
  if (!oddsIncreased) return null;

  return (
    <Box
      sx={{
        padding: '4px 12px',
        '&>*': {
          width: '100%',
          ml: '0 !important',
        },
      }}
    >
      <OddsChangedBanner direction="increase" isInFooter={false} />
    </Box>
  );
};

// View ---

type BetApprovalProps = {
  betRequest: TPunterBetRequest | undefined;
  state: string | undefined;
} & Pick<CardProps, 'data'>;

function BetApprovalView({ data, betRequest, state }: BetApprovalProps) {
  const { bet } = data ?? {};

  const components: Record<string, JSX.Element> = {
    ReducedStake: (
      <>
        <ApprovedBet betRequest={betRequest} bet={bet} />
        <RejectedBet betRequest={betRequest} bet={bet} />
      </>
    ),
    Pending: <PendingBet />,
    Approved: <ApprovedBet betRequest={betRequest} bet={bet} />,
    Rejected: <RejectedBet betRequest={betRequest} bet={bet} />,
  };

  return state ? (
    <BetFooterContainerStyles>
      {components[state] ?? null}
    </BetFooterContainerStyles>
  ) : null;
}

type UseBetApprovalProps = {
  bet?: TBetSlipBet;
};

// Controller ---

const useBetApprovalView = ({ bet }: UseBetApprovalProps) => {
  const _bet = bet as unknown as TBetSlipBet;

  const [shouldShow, setShouldShow] = useState(false);
  const [approvedStakeHasBeenSet, setApprovedStakeHasBeenSet] = useState(false);
  const [boostedOddsPromoToken, setBoostedOddsPromoToken] = useState<
    TPromoToken | undefined
  >(undefined);
  const { data: betRequestData } = useBetRequests();
  const betRequest = betRequestData?.records?.find(
    (req) => req?.request_id === _bet.request_id
  );
  const hasLD = useFeatureFlag('LUCKY_DIP_ENABLED');

  const betConfirmation = _bet?.confirmation;

  // This will need to be refactored out
  const dispatch = useAppDispatch();
  const { updateBet: _updateBet } = getBetSlipStoreActions();

  useEffect(() => {
    if (betRequest?.promo_tokens?.length && !boostedOddsPromoToken) {
      const oddsBoost: TPromoToken | undefined = betRequest.promo_tokens.find(
        (token) => token?.token_type === 'odds-boost'
      );

      if (oddsBoost) {
        dispatch(
          setBonusChanceModal({
            game: 'odds-boost',
            variables: {
              boosted_odds: oddsBoost.boosted_odds,
              original_odds: oddsBoost.original_odds,
              new_payout:
                (betRequest.approved_stake ?? 1) *
                (oddsBoost.boosted_odds ?? 1),
              original_payout:
                (betRequest.approved_stake ?? 1) *
                (oddsBoost.original_odds ?? 1),
            },
            isVisible: !hasLD,
            isLDVisible: hasLD ?? false,
          })
        );
        setBoostedOddsPromoToken(oddsBoost);
      }
    }
  }, [betRequest, dispatch, bet, boostedOddsPromoToken, hasLD]);

  useEffect(() => {
    if (
      !approvedStakeHasBeenSet &&
      betRequest?.approved_stake &&
      betRequest?.status === 'ReducedStake'
    ) {
      const status = findConfirmationStatus(betRequest?.status);
      const updatedBet: TBetSlipBet = {
        ...(_bet ?? {}),
        stake: centsToDollarsNumber(betRequest.approved_stake),
        confirmation: { ..._bet.confirmation, status },
      };

      const newBetID = _bet?.id;

      if (newBetID) {
        _updateBet((state) => {
          const hasTreasure = betRequest?.promo_tokens?.some(
            (promo) => promo?.token_type === 'stake-match'
          );
          const _bets = { ...(state ?? {}) };
          _bets[newBetID].confirmation = {
            ..._bets[newBetID].confirmation,
            ...(hasTreasure ? { gameplay: { game: 'treasure-hunt' } } : {}),
            status,
          };
          _bets[newBetID].stake = (betRequest.approved_stake / 100).toString();
          return _bets;
        });
      }

      dispatch(updateBet(updatedBet));
      setApprovedStakeHasBeenSet(true);
    }
  }, [
    betConfirmation,
    betRequest,
    _bet,
    dispatch,
    approvedStakeHasBeenSet,
    _updateBet,
  ]);

  useEffect(() => {
    if (
      betRequest !== undefined ||
      ['Pending', 'ReducedStake'].includes(betConfirmation?.status ?? '')
    ) {
      setShouldShow(true);
    }
  }, [bet, betConfirmation, betRequest]);

  return {
    shouldShow,
    betRequest,
    state:
      betConfirmation?.status === 'ReducedStake'
        ? 'ReducedStake'
        : betRequest?.status,
  };
};

// ---

type FooterExoticProps = Pick<CardProps, 'data'>;

Card.FooterExotic = function FooterExoticContainer(
  props: Partial<FooterExoticProps>
) {
  const { data } = props as Required<FooterExoticProps>;
  if (data?.bet.type !== 'Exotics') return null;
  return <FooterExoticView data={data} />;
};

function FooterExoticView({ data }: FooterExoticProps) {
  const { bet } = data ?? {};
  const _bet = bet as unknown as TBetSlipBetExotics;

  const isbetAToteMulti = isToteMulti(_bet.sub_type as string);

  let combos = _bet?.validSelectionAmount;

  if (isbetAToteMulti) {
    combos = calculateCombosForToteMulti(_bet.selection);
  }

  return (
    <ExoticsFooterContainer>
      <TextExoticsLabel>
        <FormattedMessage id="racing.exotics.combos" />:{' '}
        <TextExoticsInfo as="span">{combos}</TextExoticsInfo>
      </TextExoticsLabel>
      <TextExoticsLabel fontSize="xs">
        <FormattedMessage id="betslip.betslipbetcard.stakepercombo" />:{' '}
        <TextExoticsInfo as="span">
          {formatStake(_bet.stake && combos ? Number(_bet?.stake) / combos : 0)}
        </TextExoticsInfo>
      </TextExoticsLabel>
    </ExoticsFooterContainer>
  );
}

// Mystery Bet

type FooterMysteryBet = Pick<CardProps, 'data'>;

Card.FooterMysteryBet = function FooteryMysteryBetContainer(
  props: Partial<FooterMysteryBet>
) {
  const { data } = props as Required<FooterMysteryBet>;
  if (data?.bet.price_type !== 'mystery_bet') return null;
  return <FooterMysteryBetView data={data} />;
};

function FooterMysteryBetView({ data }: FooterMysteryBet) {
  const { bet, viewMode } = data ?? {};
  const _bet = bet as unknown as TBetSlipBetMystery;

  const isPlaced =
    _bet.confirmation?.status === EBetSubmissionConfirmationStatus.Placed;

  const rolloverInfo = bet.confirmation?.proposition_info?.find(
    (prop) => prop.leg_num === 2
  );

  if (isPlaced && rolloverInfo)
    return (
      <Flex
        data-reviewing={viewMode === EBetSlipViewMode.ReviewingBets}
        {...betCardStyles.flexWrapperMysteryRollover}
      >
        <Flex
          {...betCardStyles.flexWrapperMysteryBetFooterBetReview}
          {...betCardStyles.flexWrapperMysteryBetFooterBetPlaced}
        >
          <BetCardSVG
            header
            name={getIconBySportName(rolloverInfo.race_type)}
            {...mysteryBetStyles.iconRace}
            {...betCardStyles.iconMysteryBetFooter}
          />
          <Flex {...betCardStyles.flexBetInfo}>
            <chakra.span {...betCardStyles.spanRaceInfo}>
              {rolloverInfo.runner_number}. {rolloverInfo.proposition_name}
              {!!rolloverInfo.barrier_number &&
                `(${rolloverInfo.barrier_number})`}
            </chakra.span>
            <Text {...mysteryBetStyles.textSlash}>/</Text>
            <Flex
              {...mysteryBetStyles.flexRaceInfo}
              {...betCardStyles.flexRaceInfoOverride}
            >{`${rolloverInfo.venue_name} R${rolloverInfo.race_number} `}</Flex>
            <Text {...mysteryBetStyles.textSlash}>/</Text>
            <chakra.span
              {...mysteryBetStyles.textPriceInfo}
              {...betCardStyles.spanBetOdds}
            >
              @{_bet.odds.toFixed(2)}
            </chakra.span>
            <Text {...mysteryBetStyles.textSlash}>/</Text>
            <chakra.span {...betCardStyles.spanPropositionType}>
              <FormattedMessage
                id={`racing.evenShot.${rolloverInfo.proposition_type.toLowerCase()}`}
              />
            </chakra.span>
          </Flex>
        </Flex>
      </Flex>
    );

  return (
    <Flex {...betCardStyles.flexWrapperMysteryRollover}>
      {_bet?.mystery_bet?.bets?.map((ss) =>
        ss.rollovers?.map((over) => (
          <>
            <Flex
              {...mysteryBetStyles.textRollover}
              {...betCardStyles.flexWrapperMysteryBetFooterBetReview}
            >
              <FormattedMessage id="mysteryBet.rolledOver" />
            </Flex>
            <Flex {...betCardStyles.flexWrapperMysteryBetFooterBetReview}>
              <Flex {...mysteryBetStyles.flexRaceInfo}>
                <BetCardSVG
                  header
                  name={getIconBySportName(over.race_type)}
                  {...mysteryBetStyles.iconRace}
                  {...betCardStyles.iconMysteryBetFooter}
                />
                <chakra.span {...betCardStyles.spanRaceInfo}>
                  {over.venue_name} R{over.race_number}
                </chakra.span>
              </Flex>
              <Text {...mysteryBetStyles.textSlash}>/</Text>
              <chakra.span
                {...mysteryBetStyles.textPriceInfo}
                {...betCardStyles.spanBetOdds}
              >
                @{_bet.odds.toFixed(2)}
              </chakra.span>
            </Flex>
          </>
        ))
      )}
    </Flex>
  );
}

// ---

type SMGInfoProps = Pick<CardProps, 'data'>;

Card.SMGInfo = function SMGInfoContainer(props: Partial<SMGInfoProps>) {
  const { data } = props as unknown as SMGInfoProps;
  if (data?.bet.type !== 'SGMulti') return null;
  return <SMGInfoView data={data} />;
};

function SMGInfoView({ data }: SMGInfoProps) {
  const { bet } = data ?? {};
  const _bet = bet as unknown as TBetSlipBetSGMulti;

  return (
    <Box w="full">
      {_bet.selections.map((selection) => {
        // TODO: Take a look at this - existing issue
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore this are all issues
        const { proposition_id, proposition_name, market_name } =
          selection ?? {};

        return (
          <chakra.dl
            key={proposition_id}
            borderColor="blackAlpha.300"
            borderTopStyle="dashed"
            borderTopWidth="1px"
            pl="10"
            pr="3"
            pt="2"
            pb="2"
          >
            <EventTitle ml="0">{proposition_name}</EventTitle>
            <EventSubtitle ml="0">{market_name}</EventSubtitle>
          </chakra.dl>
        );
      })}
    </Box>
  );
}

// ---

type PlacedProps = Pick<CardProps, 'data'>;

Card.Placed = function PlacedContainer(props: Partial<PlacedProps>) {
  const { data } = props as Required<PlacedProps>;
  if (
    !['Placed', 'ReducedStake'].includes(data?.bet?.confirmation?.status ?? '')
  ) {
    return null;
  }

  return <PlacedView data={data} />;
};

const PlacedView = memo(({ data }: PlacedProps) => {
  const { bet } = data ?? {};

  const date = useMemo(() => {
    const now = new Date();
    const options = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    } as const;
    return now.toLocaleString('en-US', options);
  }, []);

  return (
    <FlexPlacedContainer>
      <BetPlaced>
        <BetPlacedRow>
          <TextPlacedLabel>
            <FormattedMessage id="account.mybetscard.betplaced" />
          </TextPlacedLabel>
        </BetPlacedRow>

        <BetPlacedRow>
          <Text data-cy="betPlacedDateTime">{date}</Text>
        </BetPlacedRow>

        <BetPlacedRow>
          <TextPlacedLabel>
            <FormattedMessage id="account.mybetscard.betidtext" />
          </TextPlacedLabel>
        </BetPlacedRow>

        <BetPlacedRow>
          <Text data-cy="betPlacedBetId">{bet.request_id}</Text>
          <IconBetPlaced />
        </BetPlacedRow>
      </BetPlaced>
    </FlexPlacedContainer>
  );
});

// ---

type BetRecoveryProps = Pick<CardProps, 'data'>;

Card.BetRecovery = function BetRecoveryContainer(
  props: Partial<BetRecoveryProps>
) {
  const { data } = props as Required<BetRecoveryProps>;
  const { isEligible } = useBetRecoveryView({ bet: data?.bet });
  if (!isEligible) return null;
  return <BetRecoveryView />;
};

function BetRecoveryView() {
  return (
    <Box alignSelf="stretch" px="2" {...betRecoveryAlertWrapperProps}>
      <ReviewContainer p="0">
        <Alert
          bg="transparent"
          fontSize="sm"
          p="3"
          status="success"
          textAlign="start"
          textTransform="none"
          {...betRecoveryAlertProps}
        >
          <AlertIcon boxSize="4" color="inherit" me="2" />
          <span>
            <FormattedMessage
              id="betSlip.betSlipModal.betRecoveryAlert"
              values={{
                b: (chunks) => (
                  <chakra.b whiteSpace="nowrap">{chunks}</chakra.b>
                ),
              }}
            />
          </span>
        </Alert>
      </ReviewContainer>
    </Box>
  );
}

type UseBetRecoveryViewProps = {
  bet: TBetSlipBet | undefined;
};

const useBetRecoveryView = ({ bet }: UseBetRecoveryViewProps) => {
  const betIsBetRecoveryEligible = isBetRecoveryEligible(bet);
  return { isEligible: betIsBetRecoveryEligible };
};

// ---

type DefaultStakeContainerProps = Pick<DefaultStakeProps, 'data'>;

Card.DefaultStake = function DefaultStakeContainer(
  props: Partial<DefaultStakeContainerProps>
) {
  const { data } = props as Required<DefaultStakeContainerProps>;
  const { betIsInReview, ...values } = useDefaultStakeView({ bet: data?.bet });

  if (!betIsInReview) return <DefaultStakeView data={data} {...values} />;
  return null;
};

type DefaultStakeProps = {
  isOpen: boolean;
  defaultStake: string[];
  getUpdateValue: (value: string) => {
    stake: string;
    hasBeenRounded: boolean;
  };
  _updateBet: BetSlipStoreActionSchema['updateBet'];
  updateBetStake: (
    bet?: TBetSlipBet,
    value?: string,
    hasBeenRounded?: boolean | undefined
  ) => void;
} & Pick<CardProps, 'data'>;

function DefaultStakeView({
  data,
  isOpen,
  defaultStake,
  getUpdateValue,
  _updateBet,
  updateBetStake,
}: DefaultStakeProps) {
  const { bet } = data ?? {};

  return (
    <Show above="sm">
      <StakeCollapse as={Collapse} in={isOpen} isOpen={isOpen}>
        <DefaultStakeFlexbox>
          {defaultStake.map((value, idx) => (
            <Button
              key={`${value}-${idx}`}
              onClick={() => {
                const updateValue = getUpdateValue(value);

                const betID = bet?.id;
                if (betID) {
                  _updateBet({
                    id: betID,
                    values: { stake: updateValue.stake },
                  });
                }

                updateBetStake(
                  bet,
                  updateValue.stake,
                  updateValue.hasBeenRounded
                );
              }}
              {...betCardStyles.buttonStakeProps}
            >
              <FormattedMessage id="betslip.betslipbetcard.stakeprefix" />
              {value}
            </Button>
          ))}
        </DefaultStakeFlexbox>
      </StakeCollapse>
    </Show>
  );
}

// Controller ---

type UseDefaultStakeViewProps = {
  bet: TBetSlipBet | undefined;
};

const useDefaultStakeView = ({ bet }: UseDefaultStakeViewProps) => {
  const { isOpen, defaultStake, getUpdateValue } = useDefaultStake(bet);
  const { updateBet: _updateBet } = getBetSlipStoreActions();
  const { updateBetStake } = useStake();
  const { betIsInReview } = useBetSpecificViewMode(bet);

  return {
    isOpen,
    defaultStake,
    getUpdateValue,
    _updateBet,
    updateBetStake: updateBetStake as any, // legacy
    betIsInReview,
  };
};

// ---

type StakeProps = Pick<CardProps, 'data'>;

Card.Stake = function StakeContainer(props: Partial<StakeProps>) {
  const { data } = props as Required<StakeProps>;

  if (
    ![
      'Single',
      'EachWay',
      'SRMulti',
      'SGMulti',
      'Blended',
      'Exotics',
      'Multi',
    ].includes(data?.bet.type ?? '')
  ) {
    return null;
  }

  return <StakeView data={data} />;
};

function StakeView({ data }: Required<StakeProps>) {
  const { bet, betslipRef, betRequest } = data ?? {};

  return (
    <FormsHStack>
      {(() => {
        if (bet?.type === 'Exotics') {
          return (
            <>
              <FormsExoticFlexi bet={bet as TBetSlipBetExotics} />
              <FormsStake
                bet={bet}
                betslipRef={betslipRef}
                betRequest={betRequest}
              />
            </>
          );
        }

        return (
          <FormsStake
            bet={bet}
            betslipRef={betslipRef}
            betRequest={betRequest}
          />
        );
      })()}
    </FormsHStack>
  );
}

// ---

Card.EstWrapper = function EstWrapperContainer({
  children,
  ...props
}: Partial<EstWrapperProps>) {
  const { data } = props as Required<Pick<EstWrapperProps, 'data'>>;
  const value = useEstWrapperView({ bet: data.bet });

  if (!value.shouldDisplay) return null;

  return (
    <EstWrapperView data={data} {...value}>
      {children}
    </EstWrapperView>
  );
};

type EstWrapperProps = {
  shouldDisplayBtn: boolean;
  displayMysteryBet: boolean;
} & CardProps;

function EstWrapperView({
  children,
  data,
  shouldDisplayBtn,
  displayMysteryBet,
}: EstWrapperProps) {
  return (
    <FlexBonusBetsWrapper
      displayBonusBetsButton={shouldDisplayBtn}
      isMysteryBet={displayMysteryBet}
    >
      {Children.map(children, (c) =>
        isValidElement(c) ? cloneElement(c, { data }) : c
      )}
    </FlexBonusBetsWrapper>
  );
}

type UseEstWrapperViewProps = {
  bet: TBetSlipBet;
};

const useEstWrapperView = ({ bet }: UseEstWrapperViewProps) => {
  const { displayBonusBetEstReturnFlex } = useBetSpecificViewMode(bet);
  const { displayBonusBetsButton } = useBonusBets(bet);
  const { rollovers } = bet as TBetSlipBetSingle;
  const isMysteryBet = bet.price_type === 'mystery_bet' && !!rollovers?.length;

  return {
    shouldDisplay: bet.type === 'Multi' || displayBonusBetEstReturnFlex,
    shouldDisplayBtn: displayBonusBetsButton,
    displayMysteryBet: isMysteryBet,
  };
};

// ---

Card.BonusBet = function BonusBetContainer(
  props: Partial<Pick<BonusBetProps, 'data'>>
) {
  const { data } = props as Required<Pick<CardProps, 'data'>>;
  const value = useBonusBetView({ bet: data.bet });

  if (!value.shouldDisplayBtn) return null;
  return <BonusBetView data={data} {...value} />;
};

type BonusBetProps = {
  bonusBetIcon: TOptionalIcon;
  toggleUseBonusBets: () => void;
  displayMysteryBet: boolean;
} & Pick<CardProps, 'data'>;

function BonusBetView({
  data,
  bonusBetIcon,
  toggleUseBonusBets,
  displayMysteryBet,
}: Required<BonusBetProps>) {
  const { bet, viewMode } = data;

  // TODO: fix at some point
  if (bet.type === 'Exotics') return <div />;

  return (
    <Button
      width={
        displayMysteryBet || viewMode === EBetSlipViewMode.ReviewingBets
          ? '100%'
          : 'auto'
      }
      data-cy="useBonusBetsBtn"
      isBonusBet={bet.is_bonus_bet}
      data-isbonusbet={bet.is_bonus_bet}
      data-ismysterybet={displayMysteryBet}
      isDisabled={
        viewMode === EBetSlipViewMode.ReviewingBets ||
        bet.confirmation?.status === 'Pending'
      }
      isMysteryBet={displayMysteryBet}
      leftIcon={bonusBetIcon}
      onClick={() => toggleUseBonusBets()}
      {...betCardStyles.buttonBonusBetsProps}
    >
      <FormattedMessage
        id={
          bet.is_bonus_bet
            ? 'account.mybetscard.bonusapplied'
            : 'betslip.usebonusbet'
        }
      />
    </Button>
  );
}

type UseBonusBetViewProps = {
  bet: TBetSlipBet;
};

const useBonusBetView = ({ bet }: UseBonusBetViewProps) => {
  const { bonusBetIcon } = useBonusBetIcon(bet.is_bonus_bet);
  const { displayBonusBetsButton, toggleUseBonusBets } = useBonusBets(bet);
  const { rollovers } = bet as TBetSlipBetSingle;
  const isMysteryBet = bet.price_type === 'mystery_bet' && !!rollovers?.length;
  return {
    bonusBetIcon,
    shouldDisplayBtn: displayBonusBetsButton,
    toggleUseBonusBets,
    displayMysteryBet: isMysteryBet,
  };
};

// ---

type FooterProps = Pick<CardProps, 'data'>;

/**
 * TODO:
 * This needs a Container
 */
Card.Footer = function Footer({ data }: Partial<FooterProps>) {
  const { bet, viewMode } = data as Required<FooterProps>['data'];
  const shouldShowEst =
    ['Single', 'EachWay', 'SGMulti', 'SRMulti', 'Blended', 'Multi'].includes(
      bet.type ?? ''
    ) &&
    !['starting', 'tote_single_mid', 'tote_single_best'].includes(
      bet.price_type ?? ''
    );

  const hasRollover = !!bet?.rollovers?.length;

  if (viewMode === EBetSlipViewMode.EditingBets) {
    return (
      <FooterContainer>
        <Flex alignItems="center">
          <FooterText mx="1">
            <FormattedMessage
              id={
                hasRollover
                  ? 'betSlip.combinedEstPayout'
                  : 'betSlip.potentialReturns'
              }
            />
          </FooterText>
        </Flex>

        <EstReturnsLabel>
          <EstReturnsBlock as="span">
            {shouldShowEst ? (
              formatStake(bet.potential_returns ?? 0)
            ) : (
              <FormattedMessage id="generic.na" />
            )}
          </EstReturnsBlock>
        </EstReturnsLabel>
      </FooterContainer>
    );
  }
  return null;
};

// ---

type HeaderMultiProps = Pick<CardProps, 'data'>;

// Only pertains to dev.betcloud, not local (until MoM is enabled)
Card.HeaderMulti = function HeaderMultiContainer(
  props: Partial<HeaderMultiProps>
) {
  const { data } = props as Required<HeaderMultiProps>;
  if (data.bet.type !== 'Multi') return null;
  return <HeaderMultiView data={data} />;
};

function HeaderMultiView({ data }: Required<HeaderMultiProps>) {
  const { bet } = data;
  const _bet = bet as TBetSlipBetMulti;
  const multiOdds = calculateMultiOdds(_bet);
  const betLegCount = _bet.legs.filter((leg) => !leg.eventClosed).length;

  return (
    <MultiHeaderWrapperStyles>
      <BetCardSVG header name="multi_betslip" />
      <MultiEventTitle data-cy="multiBetNumberOfLegsSelected">
        {betLegCount} <FormattedMessage id="account.mybetscard.legmulti" />
      </MultiEventTitle>

      {multiOdds > 1 && (
        <OddsText data-cy="multiBetOddsOfLegsSelected">
          <Text as="span" fontWeight="normal" fontSize="xs">
            {getBetHasBeenPlaced(bet) && '@'}
          </Text>
          {multiOdds.toFixed(2)}
        </OddsText>
      )}
    </MultiHeaderWrapperStyles>
  );
}

// ---

Card.InfoMulti = function InfoMultiContainer(
  props: Partial<Pick<CardProps, 'data'>>
) {
  const { data } = props as Required<Pick<CardProps, 'data'>>;
  const isMulti = data.bet.type === 'Multi';

  if (!isMulti) return null;

  const _bet = data.bet as TBetSlipBetMulti;

  const legs = _bet.legs.filter(
    (leg) =>
      _bet.confirmation?.rejection_reason === 'PropositionClosed' ||
      !leg.eventClosed
  );

  return (
    <MultiCardsContainer>
      {legs.map((leg) => (
        <InfoMultiView key={`multi-${leg.event_id}`} data={data} leg={leg} />
      ))}
    </MultiCardsContainer>
  );
};

type InfoMultiProps = {
  leg: TMultiLeg;
} & Pick<CardProps, 'data'>;

// Only pertains to dev currently, not localhost (until MoM is enabled)

function InfoMultiView({ data, leg }: InfoMultiProps) {
  const { bet } = data;
  const _bet = bet as TBetSlipBetMulti;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const eventHref = getEventHref(_bet as unknown as any, leg);
  const propOddsChanged = bet.confirmation?.changed_odds?.find(
    (ch) => ch.proposition_id === leg.proposition_id
  );
  const eventRule = getEventRuleText(leg.eventRule);
  const oddsIncreased = leg.oddsChangeDirection === 'Increase';
  const matchNameSplit = leg?.event_subtitle?.split(/(vs)/i);

  return (
    <MultiCardContainer eventClosed={leg.eventClosed}>
      <Flex flexDir="column" gap="1" {...flexEventContainerProps}>
        <FlexEventDetails>
          {leg.event_icon && (
            <BetCardSVG header name={getIconBySportName(leg.event_icon)} />
          )}
          <Flex flexDirection="column" {...flexEventProps}>
            <EventTitle data-cy="betSelection">
              {leg.event_title.toLowerCase()}{' '}
              {leg.event_type?.toLocaleLowerCase() !== 'match' &&
                leg?.event_icon?.toLocaleLowerCase() !== 'harness racing' && (
                  <SpanBarrierNumber as="span">
                    ({leg.event_data?.barrier_number})
                  </SpanBarrierNumber>
                )}
            </EventTitle>
            <EventMarketName data-foo="bar" isExtraInfo>
              {(() => {
                // Check if the string is intl id or a regular string
                const regex = /^[^ .]+\.[^ .]+(\.[^ .]+)*$/;
                const isID = regex.test(leg.event_market_name);

                if (isID)
                  return <FormattedMessage id={leg.event_market_name} />;
                return leg.event_market_name.replace(/_/g, ' ').toLowerCase();
              })()}
            </EventMarketName>

            <EventSubtitle data-cy="eventName">
              <LinkMarket to={eventHref}>
                {matchNameSplit?.map((n, i) => {
                  if (n.toLocaleLowerCase() === 'vs') {
                    return (
                      <Text
                        textTransform="lowercase"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        vs
                      </Text>
                    );
                  }

                  return (
                    <Text
                      key={`event-name-${n}-${leg.event_id}`}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {i === 0 ? n : ` ${n}`}
                    </Text>
                  );
                })}
              </LinkMarket>
            </EventSubtitle>

            {leg.event_subtitle && (
              <EventMarketName data-cy="marketType">
                {leg.event_market_name}
              </EventMarketName>
            )}
          </Flex>
        </FlexEventDetails>

        {eventRule && (
          <EventRule ml="0.5">
            <Icon boxSize="3.5" as={ErrorWarning} />
            <Text as="span" fontWeight="normal">
              <FormattedMessage id={eventRule} />
            </Text>
          </EventRule>
        )}
      </Flex>

      <OddsMultiCard>
        {!!propOddsChanged && (
          <Box pr="1">
            <Icon
              h="2.5"
              w="5"
              color={oddsIncreased ? 'green.500' : 'red.500'}
              as={oddsIncreased ? UpArrow : DownArrow}
            />
          </Box>
        )}{' '}
        {leg.odds.toFixed(2)}
      </OddsMultiCard>
    </MultiCardContainer>
  );
}
