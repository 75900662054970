import { CSSObject } from '@chakra-ui/react';
import { RaceCardSchema } from './RaceCards.styles';

export const raceCardStyles: RaceCardSchema = {
  GridRaceCards: {
    my: '0',
    mb: '3',
    columnGap: '3.5',
  },
  FlexCard: {
    my: '1',
    border: '1px solid',
    borderColor: 'rgba(149, 239, 255, 0.2)',
    borderRadius: 'md',
    bg: 'alphaAlpha.800',
    boxShadow:
      '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset',
    backdropFilter: 'blur(8px)',
  },
  LinkCardHeader: {
    bg: 'transparent',
  },
  TextCardHeading: {
    color: 'white',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'extrabold',
    textTransform: 'uppercase',
    lineHeight: 'normal',
    textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    pt: '0.5',
    ml: '1',
  },
  TextRaceNumber: {
    bg: 'beta.300',
    borderRadius: '3px',
    color: 'blackAlpha.800',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.25) inset',
    lineHeight: '16px',
  },
  GridCardContent: {
    color: 'white',
    fontSize: 'sm',
    p: '0',
  },
  BoxGridHeaderItem: {
    bg: 'blackAlpha.400',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 'normal',
    fontFamily: 'body',
    px: '2',
    fontSize: '2xs',
  },
  FlexGridRunnerItem: {
    bg: 'transparent',
    borderBottom: '1px solid',
    borderBottomColor: 'rgba(0, 0, 0, 0.3)',
    boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.07)',
    px: '2.5',
    _last: {
      boxShadow: 'none',
      borderBottom: 'none',
    },
    sx: {
      ':nth-last-child(2)': {
        boxShadow: 'none',
        borderBottom: 'none',
      },
    },
  },
  TextRunner: {
    fontSize: 'xs',
    color: 'white',
    fontWeight: 'semibold',
  },
  TextRunnerNumber: {
    sx: {
      _last: {
        color: 'white',
        fontSize: '2xs',
        fontWeight: 'normal',
        pos: 'relative',
        top: '-2px',
      },
    },
  },
  TextScratched: {
    color: 'white',
  },
  LinkRaceDetails: {
    lineHeight: '27px',
    h: '42px',
    bgGradient: 'linear(to-b, beta.200, beta.400)',
    borderRadius: 'base',
    color: 'blackAlpha.800',
    fontFamily: 'accent',
    fontSize: 'xs',
    fontWeight: 'black',
    textTransform: 'uppercase',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
    _hover: {
      bgGradient: 'linear(to-b, beta.200, beta.200)',
      boxShadow:
        '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.5) inset',
    },
    m: 2.5,
  },
  buttonRaceCardOddsProps: {
    variant: 'odds',
    fontFamily: 'body',
    fontSize: 'xs',
    fontWeight: 'black',
    textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.35)',
    borderRadius: 'base',
    h: '8',
    w: '42px',
  },
};
export const TextRunner: CSSObject = {
  color: 'white',
};
export const IconCardHeader: CSSObject = {
  color: 'beta.500',
  filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25))',
};
