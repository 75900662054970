import { CSSObject } from '@chakra-ui/react';

export const TopNavContainer: CSSObject = {
  mt: '0',
  px: '0',
  pt: ['0.5', '1.5', '0'],
  pb: '0',
  overflowX: ['scroll', null, 'auto'],
  '::-webkit-scrollbar': {
    width: '0px',
    bg: 'transparent',
    height: '0',
  },
};

export const TopNavWrapper: CSSObject = {
  fontFamily: 'accent',
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  minW: [null, null, '160px'],
  borderRadius: [null, null, 'md'],
  '&::-webkit-scrollbar': { display: 'none' },
  background: 'blackAlpha.300',
  backgroundPosition: [null, null, '-370px 0px'],
  bgSize: [null, null, '364px'],
  bgBlendMode: [null, null, 'overlay'],
  mt: '2',
  mx: '0',
  px: ['2', null, '0'],
  boxShadow: '0px 1px 0px 0px #FFFFFF1A, 0px 3px 5px 0px #00000033 inset',
};

export const NavLink = (isActive: boolean): CSSObject => ({
  bg: ['transparent', null, isActive ? 'alpha.600' : 'transparent'],
  color: isActive ? 'beta.300' : 'white',
  textShadow: [
    'none',
    null,
    isActive ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.25)',
  ],
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: ['sm', null, 'base'],
  lineHeight: 'normal',
  py: ['2', '3'],
  pl: isActive ? ['2', '3'] : ['2', '4'],
  pr: ['2', '4'],
  borderBottom: ['2px solid', null, isActive ? 'none' : '1px solid'],
  borderBottomColor: [
    isActive ? 'beta.300' : 'transparent',
    null,
    isActive ? 'unset' : 'blackAlpha.400',
  ],
  borderTop: ['none', null, isActive ? 'none' : '1px solid'],
  borderTopColor: ['none', null, isActive ? 'unset' : 'whiteAlpha.200'],
  '&:hover, &:focus': {
    bg: !isActive && 'blackAlpha.300',
  },
});

export const NavLinkLabel: CSSObject = {
  color: 'gamma.200',
  textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  border: 'none',
  borderBottom: '3px solid',
  borderBottomColor: 'gamma.200',
  fontSize: 'sm',
  textAlign: 'center',
  fontFamily: 'Staatliches',
  textTransform: 'uppercase',
  minHeight: '7',
  w: '110px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: '10',
  ml: ['2', '5'],
};

export const TopNavLabelWrapper: CSSObject = {
  overflowX: ['scroll', null, 'visible'],
  flexDir: ['row', null, 'column'],
  w: ['95%', '98%'],
  bg: 'blackAlpha.400',
  mx: '2',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10),0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset',
  borderRadius: 'lg',
  mb: '2',
  '&::-webkit-scrollbar': { display: 'none' },
};
