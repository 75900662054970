import * as betprofessor from './NavIcon.styles.betprofessor';
import * as betroyale from './NavIcon.styles.betroyale';
import * as fiestabet from './NavIcon.styles.fiestabet';
import * as puntcity from './NavIcon.styles.puntcity';
import * as puntgenie from './NavIcon.styles.puntgenie';
import * as questbet from './NavIcon.styles.questbet';
import * as sterlingparker from './NavIcon.styles.sterlingparker';
import * as vikingbet from './NavIcon.styles.vikingbet';
import * as volcanobet from './NavIcon.styles.volcanobet';
import * as forsport from './NavIcon.styles.forsport';
import * as juicybet from './NavIcon.styles.juicybet';
import * as sugarcastle from './NavIcon.styles.sugarcastle';
import * as goldenbet888 from './NavIcon.styles.goldenbet888';
import * as gigabet from './NavIcon.styles.gigabet';
import * as slambet from './NavIcon.styles.slambet';
import * as templebet from './NavIcon.styles.templebet';
import * as hyperbet from './NavIcon.styles.hyperbet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  forsport,
  juicybet,
  sugarcastle,
  goldenbet888,
  gigabet,
  slambet,
  templebet,
  hyperbet,
};
