import { Image } from '@chakra-ui/react';
import React from 'react';
import footerLogo from '@/assets/hyperbet/images/logoHyper.png';

export default function index() {
  return (
    <Image
      src={footerLogo}
      maxW={['268px', null, '232px']}
      maxH={['34px', null, '30px']}
    />
  );
}
