import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const FlexFilterWrapper: CSSObject = {
  px: '0',
  py: '0',
  my: '3',
  h: '9',
  position: 'inherit',
};

export const ButtonFilter = () => ({
  color: 'alpha.300',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: selected ? 'transparent' : 'blackAlpha.300',
  color: selected ? 'beta.300' : 'transparent',
  boxShadow: selected ? '0px 0px 5px 0px rgba(255, 183, 29, 1)' : 'none',
});

export const IconRace = ({ selected }: TSelected) => ({
  color: selected ? 'beta.300' : 'whiteAlpha.700',
});

export const Divider: CSSObject = {
  bg: 'alpha.800',
  display: 'block',
  h: '7',
  my: 'auto',
  mx: '4',
};

export const TextFilterLabel: CSSObject = {
  color: 'whiteAlpha.700',
};

export const FlexRaceTypeWrapper: CSSObject = {
  columnGap: '2',
  mr: '0',
};
