import { CSSObject } from '@chakra-ui/react';

export const DrawerHeaderContainer: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 'popover',
  alignItems: 'center',
  borderBottom: 'none',
  h: '16',
  bg: 'alpha.600',
};
export const IconCircleOutline: CSSObject = {
  boxSize: '8',
  color: 'gamma.300',
};
