import { CSSObject } from '@chakra-ui/react';

export const TextCheckbox: CSSObject = {
  color: 'white',
};
export const buttonCloseAccountProps: CSSObject = {
  variant: 'solid',
  textTransform: 'uppercase',
  fontSize: 'xs',
  lineHeight: 'normal',
};
