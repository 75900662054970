import { CSSObject } from '@chakra-ui/react';
import thumb from '@/assets/hyperbet/images/toggle/thumb.png';

export const FlexWrapper = {
  borderRadius: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  p: '2',
  mt: '2',
  mx: ['3', null, 'unset'],
  mb: '2',
  gap: '2',
  boxShadow: [
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    null,
    null,
  ],
  bgGradient: 'linear(to-b, alpha.300, alpha.500)',
  h: '16',
  pos: 'relative',
  zIndex: '0',

  _before: {
    bgGradient: 'linear(to-b, alpha.300, alpha.500)',
    boxShadow:
      '0px 0px 9px 0px rgba(173, 241, 6, 0.3), 0px 0px 0px 2px rgba(173, 241, 6, 1), 0px 0px 0px 1px rgba(36, 38, 40, 1) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.15) inset',
    h: '44px',
    w: 'calc(100% - 20px)',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'contain',
    borderRadius: 'md',
    content: '""',
    pos: 'absolute',
    top: '10px',
    left: '10px',
    zIndex: '1',
  },
};

export const TextToggle = (isActive: boolean) => ({
  fontFamily: 'accent',
  fontWeight: 'bold',
  fontSize: 'md',
  textTransform: 'uppercase',
  textShadow: isActive
    ? '0px 0px 10px rgba(173, 241, 6, 0.7)'
    : '0px 3px 4px rgba(0, 0, 0, 0.25)',
  color: isActive ? 'beta.300' : 'white',
  letterSpacing: 'widest',
  px: '3',
  pos: 'relative',
  zIndex: '5',
});

export const Switcher = (): CSSObject => ({
  '--switch-track-diff':
    'calc(var(--switch-track-width) - var(--switch-track-height) + var(--bc-sizes-3))',
  '--switch-track-width': '40px',
  bg: 'transparent',
  pos: 'relative',
  zIndex: '5',
  '.chakra-switch__thumb': {
    bg: 'transparent',
    pos: 'relative',
    transitionDuration: '0.7s',
    zIndex: 'dock',
    _before: {
      bgImg: `url(${thumb})`,
      boxSize: '35px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      content: '""',
      pos: 'absolute',
      left: '-12px',
      right: '4px',
      top: '-4px',
    },
  },
  '.chakra-switch__track': {
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 6px 0px rgba(0, 0, 0, 0.5) inset',
    bg: 'blackAlpha.600',
    borderRadius: 'base',

    _before: {
      bg: 'blackAlpha.500',
      h: '7px',
      w: '34px',
      bgPos: 'center',
      bgRepeat: 'no-repeat',
      bgSize: 'contain',
      borderRadius: '2em',
      boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.09)',
      content: '""',
      pos: 'absolute',
      left: '2',
      top: '2.5',
    },
  },
});
