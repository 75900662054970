import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import { KeypadSchema } from '@/components/Betslip/components/Keyboard/styles/Keypad.styles';

export const keypadStyles: KeypadSchema = {
  buttonActionProps: {
    variant: 'outline',
    fontFamily: 'body',
    borderColor: 'white',
    border: '2px solid',
    color: 'white',
    height: '5',
    borderRadius: '3px',
    bg: 'transparent',
    boxShadow: 'none',
  },
};
export const FlexKeypadWrapper: CSSObject = {
  p: '0.5',
  w: ['full', null, '102%', 'full'],
  left: [null, null, '0'],
  bg: 'alpha.500',
  borderTop: '2px solid',
  borderTopColor: 'beta.300',
};
export const buttonKeysProps: CustomButtonProps = {
  fontWeight: 'bold',
  pt: '0.5',
  fontSize: 'lg',
  variant: 'secondary',
  height: 'full',
  sx: {
    svg: {
      pos: 'relative',
      bottom: '2px',
    },
  },
};
export const buttonDoneKeyProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'bold',
  pt: '0.5',
  fontSize: 'lg',
  height: 'full',
};
export const buttonBatchKeysProps: CustomButtonProps = {
  variant: 'solid',
  fontWeight: 'bold',
  pt: '0.5',
  fontSize: 'lg',
  height: 'full',
};
