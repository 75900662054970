import { rgba } from 'polished';
import {
  sideNavBaseStyle,
  TSideNavTheme,
} from '../../base/customComponents/sideNav';
import { TStyleConfig } from '../../base/types';

export const SideNav: TStyleConfig<TSideNavTheme> = {
  baseStyle: (props) => {
    const base = sideNavBaseStyle(props);

    return {
      ...base,
      readableColor: 'beta.500',
      background: {
        bg: ['alpha.500', null, null, null],
        bgGradient: [null, null, null, 'linear(to-b, #FFFFFF0D, #FFFFFF0D)'],
        boxShadow: [
          '0px 4px 20px 0px #00000066',
          null,
          null,
          '0px 0px 0px 1px rgba(255, 255, 255, 0.16) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
        ],

        bgRepeat: ['no-repeat', null, null, 'unset'],
        bgSize: ['cover', null, null, 'unset'],
        borderRadius: ['0px', null, null, 'lg'],
        p: ['0', null, null, '0.5'],
        mb: ['0', null, null, '4'],
      },
      drawer: {
        ...base.drawer,
        label: 'drawer',
        gap: '2',
        maxW: ['100%', '370px', null, null],
      },
      drawerBody: {
        label: 'drawerBody',
        '> div, a': {
          ml: '0',
          '> .dropDownInner, a[class*="dropDownInner"]': {
            _first: {
              cursor: 'initial',
            },
            '&:hover, &:active': {
              bg: rgba(`${props.theme.colors.beta[500]}`, 0.8),
            },
          },
        },
        '&::-webkit-scrollbar': { display: 'none' },
      },
      icon: {
        ...base.icon,
        boxSize: '28px',
        mr: '2',
        borderRadius: 'md',
        boxShadow: ['none', null, null, '0px 2px 5px 0px #00000040'],
      },
      headerSX: {
        cursor: 'default',
        fontSize: 'xs',
        fontWeight: 'bold',
        borderBottom: '1px dashed rgba(0, 0, 0, 0.1)',
        lineHeight: 'normal',
        '&&': {
          color: ['alpha.100', null, null, 'white'],
          py: '0',
          h: '35px',
        },
        '&:hover': {
          bg: 'transparent',
        },
      },
      dropDownInner: {
        ...base.dropDownInner,
        color: 'white',
      },
      linkSX: {
        ...base.linkSX,
        borderRadius: 'none',
        mb: '0',
      },
      link: {
        ...base.link,
        fontWeight: 'semibold',
        height: '45px',
        color: 'white',
        transition: 'all, 0.2s linear',
        borderBottom: '1px solid',
        borderBottomColor: '#00000030',
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.05)',
        ':first-child': {
          borderTop: 'none',
        },
        ':last-child': {
          borderBottom: 'none',
        },
        ':hover': {
          color: 'alpha.100',
          textShadow: 'none',
          bg: 'alpha.600',
          '.icon, .svgIcon': {
            bgGradient: 'linear(to-b, alpha.100, alpha.300)',
            color: 'blackAlpha.600',
            filter: 'drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.35))',
          },
        },
        py: '3',
        px: ['3', null, null, '2'],
        ml: '0',
        '~ .nested': {
          pl: '0',
        },
        svg: {
          color: ['beta.400', null, null, 'blackAlpha.700'],
          bgGradient: ['none', null, null, 'linear(to-b, beta.300, beta.500)'],
          p: '6px',
        },
        _active: {
          bg: 'rgba(0, 0, 0, 0.24)',
          opacity: '1',
        },
        '> .icon, .svgIcon, .dropDownIcon': {
          transition: 'all, 0.2s linear',
        },
        '.dropDownIcon': {
          fill: 'none',
        },
      },
      dropDownContainerClosed: {
        '.dropDownInner': {
          pl: ['3', null, null, '2'],
          borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
        },
        '&:hover, &:active': {
          '.dropDownInner': {
            label: 'dropDownInner',
            ...base.dropDownInner,
            h: '45px',
            px: ['3', null, null, '2'],
            borderBottom: ['none', null, null, '1px dashed rgba(0, 0, 0, 0.2)'],
          },
        },
      },
      dropDownContainerOpen: {
        ...base.dropDownContainerOpen,
        p: '0',
        '.dropDownInner': {
          px: '2.5',
          color: 'beta.300',
          bg: 'rgba(0, 0, 0, 0.24)',
        },
        '> .icon, .svgIcon, .dropDownIcon': {
          color: 'beta.300',
          filter: 'drop-shadow(0px 0px 8px rgba(251, 201, 21, 0.50))',
        },
      },
    };
  },
  sizes: {},
  variants: {},
};
