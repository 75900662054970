import { PageButtonSchema } from '@/views/sports/components/PageButton/styles/PageButton.styles';

export const pageButtonStyles: PageButtonSchema = {
  button: {
    variant: 'unstyled',
    fontSize: '11px',
    color: 'white',
    h: '20px',
    w: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'normal',
    bgGradient: 'transparent',
    textTransform: 'uppercase',
    fontFamily: 'body',
    sx: {
      '&&': {
        fontWeight: 'bold',
      },
      svg: {
        bg: 'white',
        p: '0.5',
        color: 'alpha.400',
        borderRadius: 'sm',
      },
    },
  },
};
