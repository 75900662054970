import { CSSObject } from '@chakra-ui/react';

export const ExoticResultedWrapper: CSSObject = {
  px: '2',
  bg: 'rgba(34, 92, 161, 0.5)',
  boxShadow:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 0px 20px 0px rgba(0, 216, 255, 0.15) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset',
  border: 'none',
  borderRadius: 'md',
  flex: '1',
};
export const TypeItem: CSSObject = {
  color: 'beta.400',
  fontWeight: 'bold',
  fontSize: 'xs',
};
export const ResultRowWrapper: CSSObject = {
  border: 'none',
  borderRadius: 'base',
  mb: '1',
  p: '1.5',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  bg: 'blackAlpha.500',
  '&:last-child': {
    mb: '2',
  },
};
export const ResultRowExoticWrapper: CSSObject = {
  fontSize: 'sm',
};
export const ResultRowExoticRow: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'medium',
  _last: {
    fontWeight: 'black',
  },
};
