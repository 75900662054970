import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/hyperbet/images/racing/mysteryBetLogo.png';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 1,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: {
    variant: 'odds',
    h: '34px',
    sx: {
      span: {
        color: 'alpha.800',
        textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.6)',
      },
    },
  },
  imageLogo: {
    pt: '1',
    w: '180px',
    src: mysteryLogo,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  iconMystery: {},
  flexWrapperMysteryLabelView: {
    color: 'white',
    fontFamily: 'heading',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 'normal',
    gap: 1,
    lineHeight: 'normal',
    alignItems: 'center',
  },
  buttonAddToBetslip: {
    variant: 'quickAccess',
    h: '34px',
    sx: {
      '&&': {
        borderLeftRadius: 'none',
        ms: '0',
      },
    },
  },
  buttonMysteryLabel: {
    variant: 'unstyled',
    color: 'white',
    h: '34px',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    borderRightRadius: 'none',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 3px 5px 0px rgba(0, 0, 0, 0.2) inset',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontFamily: 'heading',
    fontWeight: 'normal',
    sx: {
      '&&': {
        px: [2, 3],
      },
    },
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.400',
    borderRadius: 'base',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    px: '3',
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
  },
  flexWrapperMysteryPlacedBetView: {
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear-gradient(21.15deg, #39393D 13.95%, #4F4F51 74.59%)',
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset',
    borderRadius: 'base',
    boxSize: '10',
    p: '2',
    color: 'beta.300',
    textShadow: 'none',
    cursor: 'pointer',
    h: '34px',
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  imageMysteryBet: {
    w: '5',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    fontSize: 'sm',
    color: 'white',
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  textSlash: {
    color: 'whiteAlpha.400',
    fontWeight: 'bold',
  },
  textPriceInfo: {
    fontSize: 'sm',
    color: 'gamma.300',
    fontFamily: 'heading',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  textRollover: {
    fontSize: 'sm',
    color: 'gamma.300',
    fontFamily: 'heading',
    fontWeight: 'normal',
    textShadow: 'none',
    fontStyle: 'normal',
  },
  textError: {
    color: 'white',
    fontFamily: 'accent',
    fontWeight: 'normal',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    fontStyle: 'normal',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: '10',
    color: 'white',
    height: '34px',
  },
  iconRace: { color: 'beta.300', mb: '1.5' },
  textSpanWhite: {
    color: 'white',
  },
  buttonRollOver: {
    variant: 'secondary',
    h: '24px',
    _disabled: {
      bg: 'blackAlpha.700',
      boxShadow:
        '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
      color: 'gamma.300',
      textShadow: 'none',
    },
  },
  spanSelectionText: {
    color: 'alpha.400',
  },
  spanPositionType: {
    color: 'alpha.400',
  },
  tooltipIcon: {
    color: 'beta.300',
    fill: 'beta.300',
    boxShadow: 'none',
  },
};
