import * as betgalaxy from './OnboardingLayout.styles.betgalaxy';
import * as betprofessor from './OnboardingLayout.styles.betprofessor';
import * as betroyale from './OnboardingLayout.styles.betroyale';
import * as fiestabet from './OnboardingLayout.styles.fiestabet';
import * as goldenrush from './OnboardingLayout.styles.goldenrush';
import * as juicybet from './OnboardingLayout.styles.juicybet';
import * as puntcity from './OnboardingLayout.styles.puntcity';
import * as puntgenie from './OnboardingLayout.styles.puntgenie';
import * as questbet from './OnboardingLayout.styles.questbet';
import * as vikingbet from './OnboardingLayout.styles.vikingbet';
import * as volcanobet from './OnboardingLayout.styles.volcanobet';
import * as wellbet from './OnboardingLayout.styles.wellbet';
import * as oldgill from './OnboardingLayout.styles.oldgill';
import * as sugarcastle from './OnboardingLayout.styles.sugarcastle';
import * as buffalobet from './OnboardingLayout.styles.buffalobet';
import * as bet777 from './OnboardingLayout.styles.bet777';
import * as luckystride from './OnboardingLayout.styles.luckystride';
import * as titanbet from './OnboardingLayout.styles.titanbet';
import * as templebet from './OnboardingLayout.styles.templebet';
import * as goldenbet888 from './OnboardingLayout.styles.goldenbet888';
import * as hyperbet from './OnboardingLayout.styles.hyperbet';
import * as chromabet from './OnboardingLayout.styles.chromabet';
import * as bet575 from './OnboardingLayout.styles.bet575';
import * as betblast from './OnboardingLayout.styles.betblast';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  vikingbet,
  volcanobet,
  wellbet,
  oldgill,
  sugarcastle,
  buffalobet,
  bet777,
  luckystride,
  titanbet,
  templebet,
  goldenbet888,
  hyperbet,
  chromabet,
  bet575,
  betblast,
};
