import { CSSObject } from '@chakra-ui/react';

export const BoxResultedWrapper: CSSObject = {
  mb: '0',
  px: '2',
  pb: '1',
  borderRadius: 'md',
  flex: '1',
  border: '1px solid rgba(255, 255, 255, 0.16)',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.05))',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
};
export const RaceResultsHeader: CSSObject = {
  color: 'white',
  fontSize: 'sm',
  fontFamily: 'body',
  textTransform: 'uppercase',
  fontWeight: 'bold',
};
export const RunnerResultRow: CSSObject = {
  borderTop: '1px dashed rgba(255, 255, 255, 0.15)',
  borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',
  py: '1.5',
  h: '12',
  _last: {
    borderBottom: 'none',
  },
};
export const RunnerDetails: CSSObject = {
  gap: '2',
};
export const RunnerPlace: CSSObject = {
  borderRadius: 'base',
  bgGradient: 'linear(to-b, beta.300, beta.500)',
  color: 'alpha.700',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.5)',
  textTransform: 'uppercase',
  padding: '2',
  fontSize: '2xs',
  '&&': {
    h: '7',
    w: '9',
  },
  border: 'none',
};
export const RunnerNumber: CSSObject = {
  fontWeight: 'normal',
  fontSize: '2xs',
  color: 'white',
  pos: 'relative',
  bottom: '2px',
};
export const RunnerOddsContainer: CSSObject = {
  gap: '1',
};
export const RunnerOdds: CSSObject = {
  w: '12',
  h: '9',
  bg: 'blackAlpha.300',
  border: 'none',
  borderRadius: 'md',
  color: 'whiteAlpha.300',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
  fontWeight: 'normal',
  fontFamily: 'accent',
  fontSize: 'sm',
};
export const Heading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
  pt: '2',
  pb: '1.5',
  alignItems: 'center',
  borderBottom: '1px dashed rgba(0, 0, 0, 0.25)',
};
export const WinPlaceHeader: CSSObject = {
  py: '0',
  w: '12',
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'medium',
};
export const FlexEventRule: CSSObject = {
  color: 'white',
};
